import React from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';




const PoliticaDePrivacidade: React.FC = () => {



  return (
    <>
      <ContainerWhite id="cancelamento">
        <div className="p50 p600 ">
          <h2>AVISO QUANTO AO USO DE IMAGEM E POLÍTICA DE PRIVACIDADE</h2>
          <div className="separator" />
          <p>

            <strong>Identificação</strong>
            <br />
            A <strong>FUNDAÇÃO MÉDICA DO RIO GRANDE DO SUL</strong> inscrita no CNPJ sob no
            94.391.901/0001-03, estabelecida na Rua Ramiro Barcelos, 2350 sala 177, Bairro Bom Fim,
            Porto Alegre, Estado do RS, CEP 90035-003, atua como controlador dos seus dados
            pessoais para as atividades descritas neste aviso.
            <br /><br />
            Ao aceitar este termo, o usuário declara estar ciente de que durante o evento, seja ele
            realizado de forma presencial ou on-line, eventualmente, poderá ocorrer a captação de sua
            imagem, por meio de filmagens ou fotografias realizadas pela equipe organizadora. O
            usuário, desde já, autoriza de forma irrevogável e irretratável a utilização de sua imagem
            para todas as finalidades relacionadas ao evento, nos termos do direito de imagem.
            Em atendimento à Lei Geral de Proteção de Dados Pessoais (LGPD), nós fornecemos a
            você neste documento, as informações necessárias sobre os seus direitos e nossas
            obrigações. Ainda, explicamos como, para quê e quando seus dados pessoais são tratados.
            <br /><br />
            Dados pessoais utilizados e para quais finalidades<br />
            Trataremos seus dados pessoais conforme especificado no quadro abaixo:

            <table className='tableRef'>
              <tbody>
                <tr><th>Campo</th><th>Gerenciamento</th></tr>
                <tr><td>País</td><td>Armazenado enquanto o usuário se mantiver cadastrado na plataforma</td></tr>
                <tr><td>Estado</td><td>Armazenado enquanto o usuário se mantiver cadastrado na plataforma</td></tr>
                <tr><td>Município</td><td>Armazenado enquanto o usuário se mantiver cadastrado na plataforma</td></tr>
                <tr><td>Telefone / Whatsapp</td><td>Armazenado enquanto o usuário se mantiver cadastrado na plataforma</td></tr>
                <tr><td>Data de Nascimento</td><td>Armazenado enquanto o usuário se mantiver cadastrado na plataforma</td></tr>
                <tr><td>Senha</td><td>Armazenada criptografada enquanto o usuário se mantiver cadastrado na plataforma</td></tr>
                <tr><td>Dados de cartão de crédito</td><td>Não são armazenados</td></tr>
                <tr><td>CNPJ</td><td>Armazenado enquanto o usuário se mantiver cadastrado na plataforma</td></tr>
                <tr><td>Razão social</td><td>Armazenado enquanto o usuário se mantiver cadastrado na plataforma</td></tr>
              </tbody>
            </table>


            <br />

          </p>
        </div>
      </ContainerWhite>
    </>
  );
};
export default PoliticaDePrivacidade;
