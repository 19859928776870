import React, { useEffect, useState } from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle } from '../../../../../../config';
import { FaExchangeAlt, FaMoneyBill } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';
import { Form } from '@unform/web';
import SelectApi from '../../../../core/components/Forms/SelectApi';
import { useLoading } from '../../../../../../hooks/LoadingHook';
import { useSearch } from '../../../../../../hooks/Search';
import { string } from 'yup';
import SelectSimple from '../../../../core/components/Forms/SelectSimple';
import { useModal } from '../../../../../../hooks/Modal';
import SelectApiDirect from '../../../../core/components/Forms/SelectApiDirect';

interface ICreateModule {
  lineData: Record<string, any>;
}

interface IOptions {
  label: string;
  value: string;
}

const FormComponent = ({ changeProject }) => {

  const [products, setProducts] = useState<Record<string, any>[]>([]);
  const [project, setProject] = useState('');

  const productsList = products?.reduce((prev: IOptions[], item) => {

    prev.push({ label: item?.title, value: item?._id?.toString() })

    return prev;
  }, [] as IOptions[])


  const projectSelect = async (project_id) => {
    const projectItem = await api.get(`/projects/${project_id}`);
    if (projectItem) {
      setProject(projectItem?.data?.url);
    }
  }


  const loadProducts = async (projectValue) => {

    const productsProjectList = await api.get('/products', { params: { where: { project_id: projectValue } } })

    setProducts(productsProjectList?.data?.rows);

  }

  useEffect(() => {

    if (!project) {
      setProducts([]);
    }
    else {
      loadProducts(project)


    }

  }, [project])

  return <Form onSubmit={changeProject}>
    <SelectApiDirect setCallback={(value) => { projectSelect(value) }} alias='/projects' label='Projeto' endpoint='/projects' name='project' />
    <SelectSimple name='product' label='Produto' options={productsList} />
    <button className='defaultButtonReverse'>Alterar</button>
  </Form>

}

export const ChangeProductAndProject: React.FC<ICreateModule> = ({ lineData }) => {

  const { projectHash } = useParams<Record<string, any>>();
  const { addToast } = useToast();
  const { addLoading, removeLoading } = useLoading();
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const changeProject = async (data) => {
    addLoading({ title: 'Carregando', key: 'loading' });

    if (!data?.project) {
      removeLoading('loading');
      addToast({ title: 'Selecione um projeto', type: 'error' });
    }

    if (!data?.product) {
      removeLoading('loading');
      addToast({ title: 'Selecione um produto', type: 'error' });
    }

    try {
      await api.post('/sales-change-project-and-product', { project: data?.project, product: data?.product, sale_hash: lineData?.sale_hash })
      removeLoading('loading');
      reloadSearchAll();
      addToast({ title: 'Alteração realizada oom sucesso', type: 'success' });
      removeModal('modal')
    }
    catch (err) {
      removeLoading('loading');
      addToast({ title: 'Erro ao alterar venda', type: 'error' });
    }


  }

  const gerarRelatorioFinanceiro = async () => {





    addModal({ title: '', key: 'modal', content: < FormComponent changeProject={changeProject} />, theme: 'whiteModal' })


  }

  const moduleUpdate = (): JSX.Element => (
    <button className="lineIcon" type="button" onClick={gerarRelatorioFinanceiro}>
      <FaExchangeAlt />
    </button>
  );

  return moduleUpdate();
}
