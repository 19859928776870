import { useLanguage } from "../../../../../../hooks/Language";
import { useToast } from "../../../../../../hooks/Toast";
import * as Yup from 'yup';

export default function useFieldsList() {

    const { translate } = useLanguage();
    const { addToast } = useToast();

    const checkFieldListValidation = async ({ product, data }) => {

        if (product.fields && product.fields.length > 0) {

            const shape = {};

            product.fields.map((f: Record<string, any>) => {
                if (f.mandatory === 'yes') {
                    shape[f.field] = Yup.string().required(`${translate(f.field)} ${translate(f.label)}`);
                }

            })

            if (Object.keys(shape).length < 1) {
                return;
            }

            const schema = Yup.object().shape(shape)

            try {

                await schema.validate(data, { abortEarly: false });
                return;

            }
            catch (err) {

                const errors = err?.errors && err?.errors?.length > 0 ? err.errors.join(',') : translate('Preencha todos os dados Obrigatórios');

                addToast({ type: 'error', title: errors });
                return 'error';
            }

        }

    }

    const fieldsListGetData = ({ product, data }) => {

        const fieldsValues: Record<string, any> = {};
        let fieldsText = '';

        if (!product.fields || product?.fields?.length < 1) {
            return { fields: { ...fieldsValues }, fields_text: fieldsText }
        }

        const fieldsKeys: Array<string> = [];

        let x = 1;
        product.fields.map((f: Record<string, any>) => {

            fieldsKeys.push(f.field);
            fieldsValues[f.field] = data?.[f.field];

            fieldsText = `${fieldsText}${data?.[f.field]}`;
            if (x >= 1 && x < product?.fields?.length) {
                fieldsText = `${fieldsText} | `;
            }
            x++;

        })


        return { fields: { ...fieldsValues }, fields_text: fieldsText }


    }


    return { fieldsListGetData, checkFieldListValidation }



}


