/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import { urlWebsite } from '../../config';
import { Container, AnimatedDiv, Content, ActiveButtonItem } from './styles';
import ProductsContainer from './modules/Products';
import CoursesContainer from './modules/Courses';
import LoginContainer from './modules/Login';
import AddressContainer from './modules/Address';
import PaymentMethodContainer from './modules/PaymentMethod';
import { ResponseMessage } from '../../components/ResponseMessage';
import Button from '../../components/Button';
import { FaAddressCard, FaCheck, FaCreditCard, FaKey, FaTicketAlt, FaUser } from 'react-icons/fa';
import { useLanguage } from '../../hooks/Language';
import { useConfiguration } from '../../hooks/Configuration';

import { checkIsAfterDate } from '../../utils/date';

interface ProductProps {
  _id: string;
  title: string;
  price: number;
  formattedPrice: string;
  max_selectable_courses: number;
  benefits: string[];
  quantity: number;
  hash_link: string;
}

interface AddressProps {
  zipcode: string;
  address: string;
  address_number?: string;
  neighborhood: string;
  city: string;
  state: string;
}

interface LoginProps {
  name: string;
  email: string;
  password: string;
}

interface UserProps {
  document_type: string;
  document_number: string;
  birth_date: string;
}

interface BillingProps {
  paymentMethod: 'boleto' | 'credit_card';
  credit_card_hash?: string;
  installments?: number;
}

export interface BuyContext {
  amount: number;
  maxCourses: number;
  productType: 'free' | 'paid';
  isLogged?: boolean;
  products?: ProductProps[];
  product: ProductProps;
  courses?: string[];
  login: LoginProps;
  user?: UserProps;
  address?: AddressProps;
  billing?: BillingProps;
  parner?: Record<string, any>;
  fields?: Record<string, any>;
  fields_text?: string;
}

interface IProduct {
  productId: string;
}

interface ISteps {
  step: number;
}

export const ActiveButton: React.FC<ISteps> = ({ step }) => {
  const { translate } = useLanguage();
  return <div style={{ display: 'flex', border: '2px solid #fff', marginBottom: '10px' }}>
    <ActiveButtonItem active={step === 1} title={translate('Ingressos')}><FaTicketAlt /> {step === 1 && <aside>{translate('Inscrição')}</aside>}</ActiveButtonItem>
    <ActiveButtonItem active={step === 11} title={translate('Login / Cadastrar')}><FaKey /> {step === 11 && <aside>{translate('Login / Cadastrar')}</aside>}</ActiveButtonItem>
    <ActiveButtonItem active={step === 2} title={translate('Dados Gerais')}><FaUser /> {step === 2 && <aside>{translate('Dados Gerais')}</aside>}</ActiveButtonItem>
    <ActiveButtonItem active={step === 22} title={translate('Endereço e Privacidade')}><FaAddressCard /> {step === 22 && <aside>{translate('Endereço e Privacidade')}</aside>} </ActiveButtonItem>
    <ActiveButtonItem active={step === 3} title={translate('Pagamento')}><FaCreditCard /> {step === 3 && <aside>{translate('Pagamento')}</aside>}</ActiveButtonItem>
    <ActiveButtonItem active={step === 4} title={translate('Pagamento')}><FaCheck /> {step === 4 && <aside>{translate('Confirmação')}</aside>}</ActiveButtonItem>
  </div>
}

const ProductsWithTicket: React.FC = () => {
  const [step, setStep] = useState<number[]>([1, 1]);
  const { configuration } = useConfiguration();
  const [buyContext, setBuyContext] = useState<BuyContext>({} as BuyContext);
  const { productId } = useParams<IProduct>();




  const saleEnd = configuration?.current_event_id_response?.sale_end && !checkIsAfterDate(configuration?.current_event_id_response?.sale_end) ? true : false;


  if (configuration?.current_event_id_response?.open_status === 'closed' || saleEnd) {
    return <Container className='marginTop40' id="inscricao" style={{ minHeight: '80vh', width: '100%' }}>

      <h2 style={{ fontSize: '24px', color: '#fff', marginBottom: '15px' }}>Inscrições encerradas</h2>
      <Link to="/"><button className='defaultButton'> Voltar</button></Link>

    </Container>
  }

  if (configuration?.current_event_id_response?.open_status === 'waiting') {
    return <Container className='marginTop40' id="inscricao" style={{ minHeight: '80vh', width: '100%' }}>
      <h2 style={{ fontSize: '24px', color: '#fff', marginBottom: '15px' }} >Aguardando inscrições</h2>
      <Link to="/"><button className='defaultButton'> Voltar</button></Link>

    </Container>
  }


  return (
    <Container className='marginTop40' id="inscricao" style={{ minHeight: 'auto', width: '100%' }}>
      {/* configuration?.current_event_id_response?.title && <h2 style={{ padding: '20px' }}>{configuration?.current_event_id_response?.title}</h2> */}


      <PaymentMethodContainer />


    </Container>
  );
};
export default ProductsWithTicket;
