import usePaymentRequest from './paymentRequest';

export default function usePaymentWithBoleto() {

  const { paymentRequest } = usePaymentRequest()

  const payWithBoleto = async ({ paymentContext }) => {

    await paymentRequest({ paymentContext });

    return false;

  }

  return { payWithBoleto };

}