import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiPhone } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaAddressCard,
  FaArrowRight,
} from 'react-icons/fa';

import { MdError, MdDateRange } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey } from '../../../config';
import getCep from '../../../services/cep';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import { useLanguage } from '../../../hooks/Language';
import { countries } from '../../../pages/Products/modules/Login/countries';
import { useToast } from '../../../hooks/Toast';
import { AnimatedDiv } from '../../DashboardPages/core/components/Profile/styles';
import Axios from 'axios';
import { useAuth } from '../../../hooks/Auth';
import states from './states';
import { useConfiguration } from '../../../hooks/Configuration';

interface IRecoverFormData {
  name: string;
  email: string;
  repeat_email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
 country: string;
 extra_2 : string;
 extra_3 : string;
 hash : string;
 instituicao ?: any;
 document_number ?: string;
}

interface IOption{
  value: string;
  label: string;
}

interface ICaptcha {
  getValue(): string;
}

interface IParam {
  code: string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const {addToast} = useToast();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const [countryISO, setCountryISO] = useState('BR');
  const { translate} = useLanguage();
  const [cities,setCities] = useState<Array<IOption>>([]);
  const [stateValue,setStateValue] = useState('');

  const {code} = useParams<IParam>();
  const {projectHash} = useConfiguration();
const {signIn} = useAuth();
  const callCityApi = async ( value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if(response){

      const values : Array<IOption> = [];

      response.data.map( city => {
        values.push( { value: city.nome, label : city.nome});
      })

      setCities(values);
    }


  }
  

  useEffect(() => {
    callCityApi(stateValue);
  },[stateValue])

  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    data.birth_date = data.birth_date
      ? data.birth_date.toString().replace(/[^0-9/]/g, '')
      : '';

    

    try {
      formRef.current?.setErrors({});

     
        const schema =   countryISO === 'BR' ? Yup.object().shape( {
          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),

   
         phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),
          password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),

          document_number: Yup.string()
  
            .required(`${translate('CPF')} ${translate('obrigatório')}`),
 
     
         
            
          birth_date: Yup.string()
            .min(10, translate('min 10 Preecha a Data de Nascimento'))
            .max(10, translate('max 10 Preecha a Data de Nascimento'))
            .required(translate(`${translate('Data de Nascimento')} ${translate('obrigatória')}`)),
     
            zipcode: Yup.string().required(`${translate('CEP')} ${translate('obrigatório')}`),

            
            address: Yup.string().required(`${translate('Endereço')} ${translate('obrigatório')}`),
            address_number: Yup.string().required(`${translate('Complemento')} ${translate('obrigatório')}`),
            neighborhood: Yup.string().required(`${translate('Bairro')} ${translate('obrigatório')}`),
            city: Yup.string().required(`${translate('Cidade')} ${translate('obrigatório')}`),
            state: Yup.string().required(`${translate('Estado')} ${translate('obrigatório')}`),
           

           

        } ) : Yup.object().shape( {
  
          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),

   
         phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),
          password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),

          document_number: Yup.string()
  
            .required(`${translate('CPF')} ${translate('obrigatório')}`),
 
     
         
            
          birth_date: Yup.string()
            .min(10, translate('min 10 Preecha a Data de Nascimento'))
            .max(10, translate('max 10 Preecha a Data de Nascimento'))
            .required(translate(`${translate('Data de Nascimento')} ${translate('obrigatória')}`)),
     
            zipcode: Yup.string().required(`${translate('CEP')} ${translate('obrigatório')}`),

            
            address: Yup.string().required(`${translate('Endereço')} ${translate('obrigatório')}`),
            address_number: Yup.string().required(`${translate('Complemento')} ${translate('obrigatório')}`),
            neighborhood: Yup.string().required(`${translate('Bairro')} ${translate('obrigatório')}`),
            city: Yup.string().required(`${translate('Cidade')} ${translate('obrigatório')}`),
            state: Yup.string().required(`${translate('Estado')} ${translate('obrigatório')}`),
        });
        data.phone = data.phone.toString().replace(/[^0-9]/g, '');
        data.birth_date.toString().replace(/[^0-9/]/g, '');
        data?.document_number?.toString().replace(/[^0-9]/g, ''); 

      let {email, password, hash, country, extra_2, extra_3} = data;


      const pais = countries.findIndex(c => c.value === country);
      if(pais){
        extra_3 = countries[pais].dialCode;
        extra_2 = countries[pais].label;
      }

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const response = await api.post(`/signup-cortesy`, {
        ...data,
        hash,
        captcha: captchaValue,
        extra_2,
        extra_3
      });


     
      await signIn({ email,password });
    
        
      window.scrollTo(0,0);
      if(response?.data?.confirmationCode){

        return window.location.href = `/app/${projectHash}/payment/success/${response?.data?.confirmationCode}`;

      }

      setSuccess(true);
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        console.log(err);
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
    
        err?.response?.data?.error || err?.response?.data?.message || 'Erro ao gerar cadastro',
    ]);
    
    }
    setLoading(false);
  };

  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    const state = cepResponse.uf
      ? states.find(st => {
          return st.uf === cepResponse.uf;
        })
      : false;

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
    
        formRef?.current?.setFieldValue('state', cepResponse.uf);
      
    }
  };
  const [company, setCompany] = useState('');



  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

 
<aside style={{background:'#fff', display:'flex', padding:'20px', flexDirection:'column', alignItems: 'center', justifyContent:'center', margin: '30px', borderRadius: '20px'}}>
   


<aside style={{background:'#fff', display:'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center', margin: '30px', borderRadius: '20px'}}>
                      <Link style={{ display:'flex', flexDirection:'row', alignItems: 'center', color: '#333', justifyContent:'center' }} to={`/app/${projectHash}/aplicar-codigo/${code ? code : ''}`}><strong>JÁ TENHO CADASTRO </strong><FaArrowRight color="#333" size={20}/></Link>
                      </aside>
                  <Form className="form" ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR', hash: code}}>
                    
                    <h1 className="defaultMarked" style={{color: '#333',}} ><div/>{translate('Novo cadastro')}</h1>
                    <Input name="hash" placeholder={translate('Código do ingresso')} />
                     
                    <SelectSimple setCallback={(value) => setCountryISO(value) } options={countries} name="country" label={translate('Pais')}/>
                    
                    <Input name="name" placeholder={translate('Nome completo')} /> 
      
            
       

                 

                    { countryISO === 'BR' ? <MaskedInput
                      mask="(99) 99999.9999"
                      name="phone"
                      placeholder={translate('Telefone / Whatsapp')}
                    /> : <Input name="phone"
                    placeholder={translate('Telefone')}/>}

 
 
 

{ countryISO === 'BR' ? <Input style={{display:'none'}} name="document_type" value="cpf"
                placeholder={translate('Tipo de documento')}/> :  <SelectSimple name="document_type"
                label={translate('Tipo de Documento (Passaporte / Outro)')} options={[{label: translate('Passaporte'), value: 'passport'}, {label: translate('Outro'), value: 'other'}]} />  }


{ countryISO === 'BR' ? <Input style={{display:'none'}} name="document_type" value="cpf"
                placeholder={translate('Tipo de documento')}/> :  <SelectSimple name="document_type"
                label={translate('Tipo de Documento (Passaporte / Outro)')} options={[{label: translate('Passaporte'), value: 'passport'}, {label: translate('Outro'), value: 'other'}]} />  }



{ countryISO === 'BR' ? <MaskedInput
                      mask="999.999.999-99"
                      name="document_number"
                      placeholder={translate('CPF')}
                /> :  <Input name="document_number"
                placeholder={translate('Nº do documento')}/> }

                

                    <MaskedInput
                      mask="99/99/9999"
                      name="birth_date"
                      placeholder={translate('Data de Nascimento (DD/MM/AAAA)')}
                    />

                    

{ countryISO === 'BR' ?
<MaskedInput

              setCallback={getCepFromApi}
              mask="99999999"
              name="zipcode"
              placeholder={translate('CEP')}
            /> : <Input name="zipcode" placeholder={translate('ZIP Code')} /> }
            <Input name="address" placeholder={translate('Endereço')} />
            <Input name="address_number" placeholder={translate('Número / Apartamento')} />
            <Input name="neighborhood" placeholder={translate('Bairro')} />
            <Input name="city" placeholder={translate('Cidade')} />
            { countryISO === 'BR' ?     <MaskedInput mask="aa" name="state" placeholder={translate('Estado')} /> : 

<Input name="state" placeholder={translate('Província / Região')} />} 

 

<p style={{fontSize:'14px', color: '#333', textAlign:'center', fontWeight: 'bold', marginTop:'30px', marginBottom:'15px'}}>Você gostaria de informar alguma necessidade especial?</p>
<Input name="disability_type" placeholder={translate('Se sim, qual?')} />

<p style={{fontSize:'14px',color: '#333', textAlign:'center', fontWeight: 'bold',marginTop:'30px', marginBottom:'15px'}}> Crie seu acesso</p>

<Input name="email" placeholder={translate('E-mail')} />
 
 
 <Input
   icon={FiLock}
   name="password"
   type="password"
   placeholder={translate('Cadastre sua senha de acesso')}
 />





<h2 style={{width: '100%', fontSize: '18px', color: '#333', textAlign: 'center', margin:'0px auto', marginTop: '40px'}}>Dados da Entidade</h2>
<p style={{width: '100%', fontSize: '12px', color: '#333', textAlign: 'center', marginBottom: '20px'}} >Não obrigatório</p>

<Input name="company" 
                placeholder={`${translate('Empresa') }`}/>

{ countryISO === 'BR' ? <MaskedInput

                      mask="99.999.999/9999-99"
                      name="company_document_number"
                      placeholder={`${translate('CNPJ da Empresa / Entidade')}`}
                    /> : 
                    <Input name="company_document_number" 
                placeholder={`${translate('Nº de identificação da Empresa / Entidade') }`}/>}


<aside style={{display:'flex', justifyContent:'flex-start', width: '100%'}}>


<CheckboxInput
              style={{ display: 'flex'  }}
              name="term"
              unique={true}
              preText={(
                <>
                  {' '}
                  Estou ciente de que estou fornecendo ao {authTitle} e seus parceiros meus contatos gerais, os quais são dados pessoais protegidos pela Lei Geral de Proteção de Dados Brasileira, para gestão de relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.

                 
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Sim, autorizo a liberação dos meus dados para uso da ${authTitle} e seus parceiros`,
                  label: translate(`Sim, autorizo a liberação dos meus dados para uso da ${authTitle} e seus parceiros`),
                  value: `Sim, autorizo a liberação dos meus dados para uso da ${authTitle} e seus parceiros`,
                },
                {
                  id: `Não autorizo a liberação dos meus dados para uso da ${authTitle} e seus parceiros`,
                  label: translate(`Não autorizo a liberação dos meus dados para uso da ${authTitle} e seus parceiros`),
                  value: `Não autorizo a liberação dos meus dados para uso da ${authTitle} e seus parceiros`,
                },
              ]}
            />
</aside>
<p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {translate('Ao me cadastrar, afirmo que li e aceito a')}
{' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {translate('POLÍTICA DE PRIVACIDADE')}
{' '}
                  </Link>
{' '}
                </strong>
                {translate('do evento.')}
              </p>
              <aside style={{display:'flex', justifyContent:'center', width: '100%'}}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />
</aside>

<aside style={{display:'flex', justifyContent:'center', width: '100%'}}>
  {errorMessage && <Error>{errorMessage.map( text => <div>{text}</div> )}</Error>}
</aside>
                    <Button type="submit">
                      {translate('Finalizar inscrição')}

                    </Button>

             
         
                  </Form>
           
          </aside>
    </>
  );
};

export default FormContainer;
