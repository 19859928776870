import React from 'react';


import {

  Capa,



} from './styles';

import ProjectList from '../ProjectList';
import EventList from '../DashboardPages/features/EventsList';



const CursosDoAHA: React.FC = () => {


  return (
    <>


      <ProjectList />




    </>
  );
};
export default CursosDoAHA;
