import usePaymentRequest from './paymentRequest';

export default function usePaymentWithCourtesy() {

  const { paymentRequest } = usePaymentRequest()

  const payWithCourtesy = async ({ paymentContext }) => {

    await paymentRequest({ paymentContext });

    return false;

  }

  return { payWithCourtesy };

}