import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaCheckCircle, FaExclamationCircle, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';



import { Container, ContainerWhite } from './styles';
import { useParams } from 'react-router-dom';
import { useModal } from '../../../../hooks/Modal';

import { useLoading } from '../../../../hooks/LoadingHook';
import Chart from '../../../../components/ChartModule';





const SaleList: React.FC<Record<string, any>> = ({ project }) => {
  const { user } = useAuth();
  const { addLoading, removeLoading } = useLoading();
  const { addModal } = useModal();
  const [products, setProducts] = useState<Record<string, any>[]>([]);
  const [sales, setSales] = useState<Record<string, any>[]>([]);
  const [contador, setContador] = useState(5)

  const [filter, setFilter] = useState('');
  const [group, setGroup] = useState('payment_status_id_title');

  const salesFiltered = filter ? sales?.filter(sale => sale?.payment_status_id === filter) : sales;

  const load = async () => {
    addLoading({ title: 'Carregando', key: 'loading' });



    const productsList = await api.get('/list-products-by-project', {
      params: {
        limitItems: 10000,
        where: { project_id: project?.url },
        export_data: {
          _id: { ref: '_id', label: 'ID', search: true },
          title: { ref: 'title', label: 'Título', search: true },
        }

      }
    });

    const salesList = await api.get('/list-sale-products-by-project', {
      params: {
        limitItems: 10000,
        where: { project_id: project?.url, payment_status_id: { "$ne": "cancelled" } },
        export_data: {
          _id: { ref: '_id', label: 'ID', search: true },
          client_id: { ref: 'client_id', label: 'Titular', search: true },
          payer_id: { ref: 'payer_id', label: 'Comprador', search: true },
          product_id: { ref: 'product_id', label: 'producte', search: true },
          payment_status_id: { ref: 'payment_status_id', label: 'Status de pagamento', search: true },
        }

      }
    });

    const getPaymentStatus = {
      waiting: 'Aguardando pagamento',
      paid: 'Pago',
      cancelled: 'Cancelado',
      expired: 'Expirado',
    };

    const getPaymentMethod = {
      boleto: 'Boleto',
      cortesy: 'Cortesia',
      courtesy: 'Cortesia',
      credit_card: 'Cartão de crédito',
      pix: 'PIX'
    }

    const saleContent: Array<Record<string, any>> = [];
    salesList.data.rows.map(item => {

      item.payment_method_id_title = getPaymentMethod?.[item?.payment_method_id];
      item.payment_status_id_title = getPaymentStatus?.[item?.payment_status_id];
      item.product_title = item?.product_id_response?.title;

      saleContent.push(item);

    })

    setSales(saleContent)
    setProducts(productsList?.data?.rows)
    removeLoading('loading')
  }

  useEffect(() => {
    if (project?.url) {
      load();
    }

  }, [project])



  return <Container>



    <h2>Relatório de inscrições</h2>
    <div className='selectorDiv'>
      <button className={filter === '' ? 'button buttonColored' : 'button'} onClick={() => setFilter('')}>Todos</button>
      <button className={filter === 'paid' ? 'button buttonColored' : 'button'} onClick={() => setFilter('paid')}>Pagos</button>
      <button className={filter === 'waiting' ? 'button buttonColored' : 'button'} onClick={() => setFilter('waiting')}>Aguardando</button>
    </div>

    <table className='tableRef'>
      <tbody>
        <tr>

          <td><strong>Produto</strong></td>
          <td><strong>Vagas</strong></td>
          <td><strong>Vendas</strong></td>
          {filter !== 'waiting' ? <td><strong>Pagas</strong></td> : <></>}
          {filter !== 'paid' ? <td><strong>Aguardando</strong></td> : <></>}
          <td><strong>% Vendidos</strong></td>
        </tr>
        {products?.map(product => {
          const salesItems = salesFiltered?.filter(i => i.product_id === product?._id?.toString() && (i?.payment_status_id === 'paid' || i?.payment_status_id === 'waiting'));
          const salesPaid = salesFiltered?.filter(i => i.product_id === product?._id?.toString() && i?.payment_status_id === 'paid')?.length;
          const salesWaiting = salesFiltered?.filter(i => i.product_id === product?._id?.toString() && i?.payment_status_id === 'waiting')?.length;
          const percent = product?.total_quantities ? `${((salesItems?.length / parseInt(product?.total_quantities, 10)) * 100)?.toFixed(2)}%` : '-';

          return <tr key={product?._id}><td>{product.title}</td> <td>{product.total_quantities || '-'}</td> <td>{salesItems?.length}</td>
            {filter !== 'waiting' ? <td>{salesPaid}</td> : <></>}
            {filter !== 'paid' ? <td>{salesWaiting}</td> : <></>}
            <td>{percent}</td></tr>



        })}
        <tr><td><strong>Total</strong></td><td><strong>{salesFiltered?.length}</strong></td>
          {filter !== 'waiting' ? <td><strong>{salesFiltered?.filter(i => i?.payment_status_id === 'paid')?.length}</strong></td> : <></>}
          {filter !== 'paid' ? <td><strong>{salesFiltered?.filter(i => i?.payment_status_id === 'waiting')?.length}</strong></td> : <></>}
        </tr>
      </tbody>
    </table>



  </Container>






}
export default SaleList;
