import { Form } from '@unform/web';
import React, { useState } from 'react';
import { useLanguage } from '../../../../../../hooks/Language';
import CreditCardForm from './modules/CreditCard';
import GeneralForm from './modules/GeneralForm';
import CurrencyInput from '../../../../../../components/Forms/MaskedCurrencyInput';
import CompleteSaleFields from '../../../CompleteSaleFields';
import { FiArrowRight } from 'react-icons/fi';
import calculateDiscount from '../../Functions/calculateDiscount';
interface IProps {
    paymentMethod: 'credit_card' | 'boleto' | 'pix' | 'cortesy';

    cart: Array<Record<string, any>>;
    couponInfo?: Record<string, any>;
    changeInstallment: Function;
    installmentValue: number;
    quantity: number;
    sendPaymentRequest: Function;
    applyCoupon: Function;
    courtesyCode: string;
}

const PaymentMethodForm: React.FC<IProps> = ({ courtesyCode, paymentMethod, couponInfo, cart, changeInstallment, installmentValue, quantity, sendPaymentRequest, applyCoupon }) => {

    const { translate } = useLanguage();
    const product = cart?.[0] || {};


    const paymentMethodElements = {
        credit_card: () => <CreditCardForm cart={cart} couponInfo={couponInfo} changeInstallment={changeInstallment} />,
        boleto: () => <GeneralForm />,
        pix: () => <GeneralForm />,
        cortesy: () => <></>,
        courtesy: () => <></>,
    }

    return <>

        <Form className="form"

            initialData={{ installments: installmentValue, quantity: quantity, document_type: 'cpf' }}
            onSubmit={(data) => sendPaymentRequest({ data, paymentMethod: calculateDiscount(couponInfo, cart).discountedValue > 0 ? paymentMethod : 'boleto', cart, coupon: couponInfo, courtesy: courtesyCode })}
            onKeyUp={event => { const key = event.key || event.keyCode; if (key === 13) { event.stopPropagation(); } }}
        >


            {product.fields && product.fields.length > 0 ? <>  <CompleteSaleFields applyCoupon={applyCoupon} fields={product.fields} />
                <br />
            </> : <></>}

            <div style={{ marginTop: '10px' }} />


            {calculateDiscount(couponInfo, cart).discountedValue > 0 ? paymentMethodElements?.[paymentMethod]() : paymentMethodElements?.['boleto']()}

            <button className='defaultButton' type="submit">
                {translate('Finalizar Compra')}
                <FiArrowRight />
            </button>

        </Form>
    </>

};
export default PaymentMethodForm;
