import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { uuid } from 'uuidv4';
import QRCode from 'qrcode.react';
import { FaQrcode } from 'react-icons/fa';
import { AxiosInstance } from 'axios';
import { useModal } from '../../../../../../hooks/Modal';

import { useToast } from '../../../../../../hooks/Toast';

 

import { usePrinter } from '../../../../../../hooks/PrinterHook';


interface ICreateModule {
  lineData: Record<string, any>;
  title?: string;
}

export default function QRCodeModule({
 
  lineData,
  title = '',

}: ICreateModule): JSX.Element {
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { addPrint, printIt } = usePrinter();
  const key = uuid();

  const print = line => {
    printIt();
  };

  const handleQR = async (line): Promise<void> => {
  /*  addPrint([
    <div className="qrHolder"
    style={{
      padding: '20px 5px',
      background: '#fff',
      width: '100%',
      textAlign: 'center',

    }}
  > <div style={{      display: 'flex',
  alignItems:'center',
  justifyContent:'center', width: '100%'}}> <img src="/apoio/logo.png" style={{width: '250px'}}/>   <QRCode style={{ marginTop: '20px'}}

  size={100} 
          includeMargin={true}
          imageSettings={{src:"", width: 100, height: 100, excavate : false}}
  renderAs="svg"
  value={`https://dasaeducaeventos.com.br/app/${line.project_id}/event/${line.event_id}/survey/${line._id}`}
/></div>
 


    
  </div>]
    ); */
    const content =  <><h2>QRCode</h2><div className="qrHolder" style={{padding: '20px', background: '#fff'}}><QRCode
    renderAs="svg"
  
    size={300} 
    includeMargin={true}
    value={
      `https://dasaeducaeventos.com.br/app/${line.project_id}/event/${line.event_id}/survey/${line._id}`
    }
  /></div>  </>;

    if (content) {
      const keyModal = addModal({ title, content, key });
    }
  };

  const moduleQRCode = (line): JSX.Element => (


<button title="Imprimir QRCode" className="lineIcon" type="button" onClick={() => handleQR(line)}>
<FaQrcode />
</button>
  );

  return moduleQRCode(lineData);
}
