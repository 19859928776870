import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Whatsapp from '../../../../../components/Whatsapp';
import { logo, authTitle, urlWebsite } from '../../../../../config';
import { useConfiguration } from '../../../../../hooks/Configuration';
import {

  Capa,

} from './styles';
 
const SidebarTheme: React.FC = () => {
  const {configuration,projectHash} = useConfiguration();
 

 
  return (
    <>
      <Capa>
        <div style={{padding: '20px'}} >

        {configuration.header_image && <div>
          <img style={{width: '100%', opacity: 1, maxWidth: '600px', margin: '0px auto', marginTop: '150px'}} 
          src={`${urlWebsite}/${configuration.header_image}`} />
     
        </div>}
        </div>
        <div className="p51 backgroundWhiteGradient"  style={{ display: 'flex', maxWidth: '400px', alignItems: 'center', flexDirection: 'column'}}>
        <div className="titleCapa">
         {configuration.data_image ? <img src={`${urlWebsite}/${configuration.data_image}`} style={{width: '100%', opacity: 1, maxWidth: '500px', margin: '0px auto'}}/> : <></>}
          <p style={{color: '#000e40', fontSize: '14px', margin: '10px auto'}}>100% online e gratuito</p>
          {configuration.certificates && configuration.certificates === 'yes' ? <Link to="/certificados" style={{margin: '10px'}}><button className="defaultButton">Certificados</button></Link> : <></>}
          <Link to={`/app/${projectHash}/inscricao`} style={{margin: '5px 10px'}}><button className="defaultButtonReverse">Inscreva-se</button></Link>
         {configuration?.active_pages?.map( button => {
          return button.button === 'yes' ? <Link to={`/app/${projectHash}/${button.url}`} style={{margin: '5px 10px'}}><button className="defaultButton">{button.title}</button></Link> : <></>
         })}
          
           <Link to={`/app/${projectHash}/login`} style={{margin: '5px 10px'}}><button className="defaultButton">Entrar</button></Link>
         <Whatsapp number="555180191425" message="Suporte técnico" text={`Olá, estou no ${authTitle}`}/> 

          </div>
        </div>
      </Capa>
   
    </>
  );
};
export default SidebarTheme;
