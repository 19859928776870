import { useState } from "react";

export default function useCart() {

    /* this function manage the cart */

    const [cart, setCart] = useState<Array<Record<string, any>>>([]);

    const changeCart = (product, option) => {

        setCart((currentValue) => {

            const cartItems = [...currentValue];

            const index = cartItems?.findIndex(item => item?._id?.toString() === product?._id?.toString());

            if (option === 'add') {

                if (index >= 0) {
                    cartItems[index].quantity++;
                }
                else {
                    cartItems.push({ ...product, quantity: 1 });
                }


            }
            else if (option === 'remove') {

                if (index >= 0) {
                    if (cartItems[index].quantity === 1) {
                        cartItems.splice(index, 1);
                    }
                    else {
                        cartItems[index].quantity--;
                    }

                }


            }

            return cartItems;

        })


    }

    return { cart, changeCart }

}