import React, { useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { Menu } from '../../../components/Menu';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
import { useAuth } from '../../../hooks/Auth';
import PopupComponent from '../../../components/PopupContainer';

import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../hooks/Configuration';
import api from '../../../services/api';
import Loader from '../../DashboardPages/core/components/Loader';
import { urlWebsite } from '../../../config';
import MainLayout from '../header';

interface IParams {
  projectHash: string;
  eventHash: string;
}


const DefaultLayout: React.FC = ({ children }) => {
  const page = window.location.pathname !== '/';
  const { menu, setMenu, prepareMenu } = usePages();

  const { token, user, handleApiErrors } = useAuth();
  const { configuration, setConfiguration, setProjectHash, setEventHash } = useConfiguration();

  const [loading, setLoading] = useState(false);



  return (
    <MainLayout>
      {loading && <Loader message='Carregando' />}
      <Menu menuList={menu} fixedMenu={false} />
      <Wrapper background_color={configuration.background_color} background={`${urlWebsite}/${configuration.background}`}>



        {children}

        <PopupComponent />


        <FooterLayout />
      </Wrapper>
    </MainLayout>
  );
};

export default DefaultLayout;
