/* eslint-disable @typescript-eslint/camelcase */
import React, { useCallback, Dispatch, useRef, useMemo, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FiArrowRight, FiArrowLeft, FiMail } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Link, useParams } from 'react-router-dom';
import api from '../../../../services/api';

import Input from '../../../../components/Forms/Input';
import Button from '../../../../components/Button';
import SelectSimple from '../../../../components/Forms/SelectSimple';

import { authTitle, eventName, gateKey } from '../../../../config';

import CurrencyInput from '../../../../components/Forms/MaskedCurrencyInput';
import { AnimatedDiv } from '../../styles';
import { Content } from './styles';
import { useModal } from '../../../../hooks/Modal';
import { useToast } from '../../../../hooks/Toast';

import { ActiveButton } from '../../index';
import Loader from '../../../../components/Loader';
import format from '../../../../utils/format';
import {
  ResponseMessage,
  IResponseMessage,
} from '../../../../components/ResponseMessage';

import UpdateUserContainer from '../../../../components/UserUpdateComponent';
import { useAuth } from '../../../../hooks/Auth';
import { useLanguage } from '../../../../hooks/Language';
import { FaBarcode, FaCreditCard, FaInfoCircle, FaQrcode, FaReceipt } from 'react-icons/fa';
import AtualizarCadastroContainer from '../AtualizarCadastro';

import { useConfiguration } from '../../../../hooks/Configuration';

import CompleteSaleFields from '../CompleteSaleFields';
import WhatsappFixed from '../../../../components/WhatsappFloat';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import DiscountCoupon, { useCheckCuponCode } from './modules/DiscountCoupon';
import AddProductsComponent from './modules/AddProductsToCart';
import PaymentMethodForm from './modules/PaymentForm';
import ProductNotFound from './modules/ProductNotFound';
import UserNotSigned from './modules/UserNotSigned';
import ProductNotAvailable from './modules/ProductNotAvailable';

import useCart from './Functions/useCart';
import usePaymentContext from './Functions/usePaymentContext';
import DisplayCartPrice from './modules/DisplayCartPrice';
import calculateDiscount from './Functions/calculateDiscount';

interface IOptions {
  text?: string;
  label?: string;
  value: string | number;
}

interface IParams {
  product_hash: string;
}

interface ICoupon {
  label: string;
  value: string;
  price: number;
}

const PaymentMethod: React.FC = () => {
  const { configuration } = useConfiguration();
  const { eventHash, projectHash = configuration?.url } = useParams<Record<string, any>>();
  const { cart, changeCart } = useCart();
  const { installmentValue, changeInstallment, paymentMethod, changePaymentMethod, sendPaymentRequest } = usePaymentContext();

  const product = cart?.[0] || {};

  const [buyContext, setBuyContext] = useState<Record<string, any>>({});
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { product_hash } = useParams<IParams>();
  const [defaultValue, setDefaultValue] = useState(0);
  const [amount, setAmount] = useState(0.00);

  const [quantity, setQuantity] = useState((product_hash === 'apoio' || product_hash === 'apoio-pos') ? 5 : 1);

  const [closed, setClosed] = useState(false);
  const [productNotFound, setProductNotFound] = useState(false);
  const [paymentType, setPaymentType] = useState('default');

  const { language } = useLanguage();

  const { addToast } = useToast();
  const { addModal, removeModal } = useModal();
  const [keepOneTicket, setKeepOneTicket] = useState('no');
  const [couponInfo, setCouponInfo] = useState<Record<string, any>>();
  const [courtesyCode, setCourtesyCode] = useState('');
  const { checkCuponCode } = useCheckCuponCode();

  const applyCoupon = async (hash) => {

    if (hash === 'remove') {
      setCouponInfo({});
    }

    if (!hash) {
      return;
    }

    try {
      const couponValue = await checkCuponCode(hash, projectHash);

      setCouponInfo(couponValue);

    }
    catch (err) {
      return;
    }



  }


  const [responseMessage, setResponseMessage] = useState(
    {} as IResponseMessage,
  );

  const { translate } = useLanguage();

  const { user } = useAuth();



  const handleApplyCourtesy = async (data) => {

    if (!data.courtesy_hash) {
      addToast({ title: 'Adicione um código de cortesia', type: 'success' });
      return;
    }
    setLoading(true);
    try {

      const courtesyItem = await api.get(`/check-courtesy/${data.courtesy_hash}/${projectHash}`);


      const newProduct = courtesyItem.data.product;

      changeCart(newProduct, 'add');
      setCourtesyCode(data.courtesy_hash);
      changePaymentMethod('cortesy');

      setLoading(false);
      removeModal('courtesy')
      return;

    }
    catch (err) {

      addToast({ title: err?.response?.data?.message || err?.response?.data?.error || 'Erro ao verificar código', type: 'error' });
      setLoading(false);
      return;

    }

  }


  const load = useCallback(async (hash) => {

    if (user) {

      try {
        const userInfo = await api.get(`/verify-user`);

        if (!userInfo) {
          setUpdate(true);
          setLoading(false);
          return;

        }

        setReady(true);

      } catch (err) {
        setUpdate(true);
        setLoading(false);

        addToast({ type: "error", title: translate("Seu cadastro precisa ser atualizado.") });
        return;
      }

      if (product_hash === 'courtesy') {
        setLoading(false);
        return;
      }

      try {

        const response = await api.get(`/product/${product_hash}/${projectHash}`);

        if (!response) {
          setProductNotFound(true);
          return;
        }

        const product = response?.data;

        if (product?.valid_id === 'Não') {
          setClosed(true);
        }

        let newCoupon: Array<ICoupon> = [];



        if (!product) {
          setProductNotFound(true);
          setLoading(false);

          addToast({ type: "error", title: translate("Produto não identificado"), description: translate('Este produto parece não estar mais ativo') });
          return;
        }

        if (product?.any_price === 'yes') {
          removeModal('modalSubscriptionType');
        }

        if (product?.allow_group_sale === 'no') {
          removeModal('modalSubscriptionType');
        }


        setLoading(false);
        setDefaultValue(product.price);
        setAmount(product.price * quantity);
        changeCart(product, 'add');

        const nextContext: Record<string, any> = { ...buyContext, products: [product], amount: product.price };

        if (user && user.country && user.country !== 'BR') {
          nextContext.billing = {};
          nextContext.billing.paymentMethod = 'credit_card';
        }

        if (!nextContext?.billing?.paymentMethod) {
          nextContext.billing = {};
          nextContext.billing.paymentMethod = 'credit_card';
        }


        if (product_hash === 'courtesy') {
          nextContext.billing.paymentMethod = 'courtesy';
        }
        setBuyContext(nextContext);



      }
      catch (err) {

        setProductNotFound(true);
        setLoading(false);

        addToast({ type: "error", title: translate("Produto não identificado"), description: translate('Este produto parece não estar mais ativo') });
        return;
      }

    }
  }, [user, buyContext, product_hash]);

  useEffect(() => {



    load(product_hash);
    setLoading(false);

  }, [product_hash])


  const handleChangeTypeSubscription = (type) => {
    setPaymentType(type);
    removeModal('modalSubscriptionType');
  }


  useEffect(() => {
    if (quantity && defaultValue) {
      setAmount(quantity * defaultValue);
    }
  }, [quantity, product, defaultValue, installmentValue])

  useEffect(() => {

    if (product_hash === 'courtesy') {

      addModal({
        theme: 'whiteModalMedium',
        key: "courtesy",
        closeButton: false,
        title: '', content: <aside style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start', gap: '15px', padding: '30px 0px' }}>
          <Form className="form"
            ref={formRef}
            onSubmit={handleApplyCourtesy}
            onKeyDown={event => { const key = event.key || event.keyCode; if (key === 13 || key === 'Enter') { event.preventDefault(); } }}
            onKeyPress={event => { const key = event.key || event.keyCode; if (key === 13 || key === 'Enter') { event.preventDefault(); } }}

          >

            <h2 style={{ color: '#333', width: '100%', margin: '10px 0px', textAlign: 'center', fontSize: '14px', lineHeight: '18px' }}>
              {translate('Insira seu código da sua cortesia')}
            </h2>

            <Input
              name="courtesy_hash"
              placeholder={translate('Código do cupom')}
            />

            <aside style={{ margin: '10px auto', display: 'flex', justifyContent: 'center', width: '100%' }}>
              <Button type="submit">
                {translate('Enviar')}
                <FiArrowRight />
              </Button>
            </aside>

          </Form>
        </aside>

      })

    }
    else if (configuration && user && product?._id && product?.any_price !== 'yes') {
      addModal({
        theme: 'whiteModalMedium',
        key: "modalSubscriptionType",
        closeButton: false,
        title: '', content: <aside style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start', gap: '15px', padding: '30px 0px' }}><h2 style={{ color: '#333' }}>{translate('Escolha o tipo de inscrição')}</h2>
          <button
            className={


              'shadowButtonSelect'
            }
            type="button"
            onClick={() => handleChangeTypeSubscription('default')}
          >

            <p style={{ fontSize: '14px', color: '#333' }}>{translate('Para eu utilizar')}</p>

          </button>

          <button
            className={


              'shadowButtonSelect'
            }
            type="button"
            onClick={() => handleChangeTypeSubscription('gift')}
          >

            <p style={{ fontSize: '14px', color: '#333' }}>{translate('Para outra pessoa')}</p>

          </button>

          <button
            className={


              'shadowButtonSelect'
            }
            type="button"
            onClick={() => handleChangeTypeSubscription('group')}
          >

            <p style={{ fontSize: '14px', color: '#333' }}>{translate('Para 2 ou mais pessoas')}</p>

          </button>
        </aside>

      })
    }

  }, [])


  useEffect(() => {

    if (configuration && user) {

      if (product_hash === 'courtesy') {
        changePaymentMethod('courtesy');
      }
      else if (configuration.allow_credit_card !== 'no') {
        changePaymentMethod('credit_card');
      }
      else if (user.country === 'BR') {
        changePaymentMethod('boleto');
      }

    }

  }, [product, configuration, user])









  if (closed) {
    return <ProductNotAvailable />
  }


  if (update) {
    return <AnimatedDiv style={{ flexDirection: 'column', alignItems: 'center' }} visible={true}>
      <AtualizarCadastroContainer />
    </AnimatedDiv>
  }

  if (!user) {
    return <UserNotSigned product_hash={product_hash} />
  }

  if (productNotFound) {
    return <ProductNotFound />
  }

  return (
    <>
      {loading && <Loader message={`${translate('Carregando')}...`} />}





      {ready && <AnimatedDiv visible={true} style={{ flexDirection: 'column', alignItems: 'center' }}>
        <WhatsappFixed
          message={translate('Suporte Técnico')}
          text={`${translate('Estou finalizando minha inscrição no')} ${authTitle} - ${configuration?.current_event_id_response?.title} - ${product?.title}`}
        />
        {responseMessage.active === true && (
          <ResponseMessage
            active
            type={responseMessage.type}
            title={responseMessage.title}
            description={responseMessage.description}
          />
        )}
        {responseMessage.active !== true && (
          <>
            <Content style={{ width: '100%', maxWidth: '100%', minHeight: '80vh' }}>

              <ActiveButton step={3} />

              <h2 style={{ color: '#333', margin: '10px 0px' }}>{translate('Falta pouco! Escolha aqui a forma de pagamento')}</h2>

              {product?.orientation ? <aside style={{ margin: '10px', fontSize: '16px', color: '#333', alignItems: 'center', justifyContent: 'center', width: '100%', display: 'flex' }}>{translate(product?.orientation)}</aside> : <></>}

              <aside className='PaymentMethodContainer'>
                <aside style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: ' flex-start' }}>
                  {calculateDiscount(couponInfo, cart).discountedValue > 0 ? <>
                    <aside className='PaymentMethodSelector' >


                      {configuration?.current_event_id_response?.allow_credit_card !== 'no' && product_hash !== 'courtesy' && product?.allow_credit_card !== 'no' && configuration?.allow_credit_card !== 'no' && <button
                        style={{ display: 'flex', alignItems: 'center', padding: '15px', flexDirection: 'row', margin: '10px', width: '250px', maxWidth: '250px', justifyContent: 'flex-start' }}
                        className={
                          paymentMethod === 'credit_card'
                            ? 'shadowButton active'
                            : 'shadowButton'
                        }
                        type="button"
                        onClick={() => changePaymentMethod('credit_card')}
                      >
                        <input
                          checked={
                            paymentMethod === 'credit_card'
                          }
                          name="payment_method"
                          value="credit_card"
                          type="checkbox"
                        />
                        <FaCreditCard size={25} style={{ marginRight: '10px', fontSize: '14px' }} /><p style={{ fontSize: '14px', color: '#333' }}>{translate('Cartão de Crédito')}</p>

                      </button>}
                      {user && user.country === 'BR' && product_hash !== 'courtesy' && configuration?.current_event_id_response?.allow_boleto !== 'no' && configuration?.allow_boleto !== 'no' && product?.allow_boleto !== 'no' && <button style={{ display: 'flex', padding: '10px', alignItems: 'center', width: '250px', maxWidth: '250px', flexDirection: 'row', margin: '10px', justifyContent: 'flex-start' }}
                        className={
                          paymentMethod === 'boleto'
                            ? 'shadowButton active'
                            : 'shadowButton'
                        }
                        type="button"
                        onClick={() => changePaymentMethod('boleto')}
                      >
                        <input
                          checked={paymentMethod === 'boleto'}
                          name="payment_method"
                          value="boleto"
                          type="checkbox"
                        />
                        <FaReceipt size={25} style={{ marginRight: '10px', fontSize: '14px' }} /><p style={{ fontSize: '14px', color: '#333' }}>{translate('Boleto')}</p>

                      </button>}

                      {user && user.country === 'BR' && product_hash !== 'courtesy' && configuration?.current_event_id_response?.allow_pix !== 'no' && configuration?.allow_pix !== 'no' && product?.allow_pix !== 'no' && <button style={{ display: 'flex', padding: '10px', alignItems: 'center', width: '250px', maxWidth: '250px', flexDirection: 'row', margin: '10px', justifyContent: 'flex-start' }}
                        className={
                          paymentMethod === 'pix'
                            ? 'shadowButton active'
                            : 'shadowButton'
                        }
                        type="button"
                        onClick={() => changePaymentMethod('pix')}
                      >
                        <input
                          checked={paymentMethod === 'pix'}
                          name="payment_method"
                          value="pix"
                          type="checkbox"
                        />
                        <FaQrcode size={25} style={{ marginRight: '10px', fontSize: '14px' }} /><p style={{ fontSize: '14px', color: '#333' }}>{translate('PIX')}</p>

                      </button>}


                      {configuration?.current_event_id_response?.allow_cortesy !== 'no' && product?.allow_cortesy !== 'no' && product_hash === 'courtesy' && paymentType !== 'group' && paymentType !== 'gift' &&
                        <button style={{ display: 'flex', padding: '10px', alignItems: 'center', width: '250px', maxWidth: '250px', flexDirection: 'row', margin: '10px', justifyContent: 'flex-start' }}
                          className={
                            paymentMethod === 'cortesy'
                              ? 'shadowButton active'
                              : 'shadowButton'
                          }
                          type="button"
                          onClick={() => changePaymentMethod('cortesy')}
                        >
                          <input
                            checked={paymentMethod === 'cortesy'}
                            name="payment_method"
                            value="cortesy"
                            type="checkbox"
                          />
                          <FaBarcode size={25} style={{ marginRight: '10px', fontSize: '14px' }} /><p style={{ fontSize: '14px', color: '#333' }}>{translate('Tenho uma cortesia')}</p>

                        </button>}


                    </aside> </> : <>


                  </>}
                  {product_hash !== 'courtesy' && product?.any_price !== 'yes' && configuration?.allow_discount !== "no" ? <DiscountCoupon setCoupon={setCouponInfo} coupon={couponInfo} cart={cart} /> : <></>}
                  <p style={{ color: '#fff', marginTop: '30px', background: '#3f507e', borderRadius: '15px', padding: '15px', cursor: 'pointer' }}

                    onClick={() =>
                      addModal({
                        title: '',
                        content: <UpdateUserContainer />,
                        key: 'userUpdateForm',
                        theme: 'whiteModal'
                      })}
                  >
                    {translate('Atualizar cadastro')}
                  </p>

                  <AddProductsComponent unique={true} showAdditional={product_hash && product_hash !== 'courtesy' ? true : false} cart={cart} setCallback={(product, option) => { changeCart(product, option) }} />

                </aside>

                <Content id='pagamento' style={{ display: paymentMethod ? 'flex' : 'none', paddingTop: '10px' }}>
                  <h2 style={{ fontSize: '14px', padding: '10px', color: '#333' }}>{translate('Preencha os campos abaixo para finalizar')}</h2>
                  <aside style={{ display: 'flex', flexDirection: 'column' }}>

                    <PaymentMethodForm key={'paymentForm'} courtesyCode={courtesyCode} applyCoupon={applyCoupon} installmentValue={installmentValue} cart={cart} changeInstallment={changeInstallment} paymentMethod={paymentMethod} quantity={quantity} sendPaymentRequest={(context) => { sendPaymentRequest(context) }} couponInfo={couponInfo} />

                    <DisplayCartPrice cart={cart} coupon={couponInfo} installmentValue={installmentValue} paymentMethod={paymentMethod} />

                  </aside>

                </Content>
              </aside>
            </Content>

          </>
        )}

      </AnimatedDiv>}
    </>
  );
};

export default PaymentMethod;
