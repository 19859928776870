import styled from 'styled-components';
interface IBackground {
    background ?: string;
}

export const Capa = styled.div<IBackground>`
  min-height: auto;

display: block;
background: none;
color: #fff;
padding: 10px;
width: 100%;
max-width: 1000px;

// border: 2px solid rgb(0,15,64);
margin: 40px auto;
background: rgba(255,255,255,1);
padding: 30px 20px;

.downloadItem{

  >a{
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 750px) {
    display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .eventTime{
    display: none ;
  }

}

img{
  width: 100%;
  max-width: 200px;

  @media (max-width: 750px) {
    max-width: 100%;
  }
}
  }
}

@media (max-width: 750px) {
  padding: 30px 0px;
}

.eventTime{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: none;
  padding: 20px;
  border-radius: 0px;
  
  margin: 15px;
  max-width: 70px;
  h2{
    font-size: 22px;
    color: #333;

  }
  h3{
    font-size: 18px;
    color: #333;
  }
}

>div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin: 0 10px;
  @media (max-width: 1200px) {
      width: 100%;
      margin: 0px;
    }

}

@media (max-width: 1200px) {
  min-height: 50vh;
  flex-direction: column;
}

@media (max-width: 850px) {
  padding: 50px 20px;

}

> .p50 {
    z-index: 1;
    width: 50%;
    min-width: 50%;
    margin: 10px 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    @media (max-width: 1200px) {
      width: 100%;
    }

    > h2 {
      font-size: 18px;

      @media (max-width: 1200px) {
        text-align: center;
      }
    }

    > p {
      text-align: justify;
    }
  }

  .event-list-container {
    display:flex; 
    flex-direction:column; 
    width: 100%; 
    max-width: 1200px;
    margin: 10px auto;
    justify-content: center; 
    align-items: center; 
    padding: 0px 20px;
    
    @media (max-width: 850px) {
  padding: 0px;

}


  }

.slide-enter{
  margin: 0px 10px;
}

  .event-content{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      padding: 20px 10px;
    
      width: 100%;
 
      margin: 0px auto;


      >img {
        width: 100%;
      }

    }


  .event-container {
    background: #f4f4f4;
   
    width: 100%;
    max-width: 550px;
    min-width: 300px;
     box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.5s all;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 850px) {
      min-width: auto;
}

    &:hover{
      transform: translateY(-10px);
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }

    p{
      color: #45474d;
    }


    .event-date {
      color: #45474d;
      font-size: 12px;
      font-weight: bold;
      width: 100%;
      text-align: center;
      

    }

    .event-project {
      color: #555;
      font-size: 12px;
      font-weight: regular;
      width: 100%;
      text-align: left;
      font-weight: bold;

      
 

    }
    .event-title{
      color: #333;
      font-size: 14px;
      font-weight: regular;
      width: 100%;
      text-align: left;
      font-weight: bold;
 
      min-height: 40px;
      

    }
    .event-button-div{
      display: flex;
      align-items: center;
      justify-content: center;

    >a { 
      margin: 10px;
      max-width: 150px;

  
     
    
  }
    }


    >img{ 
      width: 100%;
    }
  }

.slick-arrow{
  bottom: -30px;
  top: auto;

  
    &:before{
      color: #333;
    }
  
}
.slick-next {
  right: 48%;
  @media (max-width: 850px) {
    right: 40%;

  }
}
.slick-prev{
  left: 48%;
  @media (max-width: 850px) {
    left: 40%;

  }
}
`;


export const Photo = styled.div<IBackground>`
  width: 200px;
  margin: 10px;

  height: 200px;
  background: url(${props => props.background}) no-repeat;
  background-size: cover;
  background-position: center;
transition: 0.2s all;
box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
border: 0px;
  &:hover{
    transform: scale(1.1);
    box-shadow: 0px 3px 15px rgba(0,0,0,0.3);
    border: 3px solid #fff;
  }


`;
