import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';
import report from './modules/Report';
interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

const ExpositorsList: React.FC = () => {
  const endpoint = '/expositorlist';
  const title = 'Cortesias';

  const { projectHash, eventHash } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    hash: { ref: 'hash', column: 'hash', label: 'Hash' },
    category: { ref: 'category', column: 'category', label: 'Categoria' },
    title: { ref: 'title', column: 'title', label: 'Expositor' },
    client_id: {
      ref: 'client_id',
      column: 'client_id_response',
      includeColumn: 'name',
      label: 'Comprador',
    },
    quantity: { ref: 'quantity', column: 'quantity', label: 'Quantidade de ingressos' },
    owner: { ref: 'owner', column: 'owner', label: 'Responsável' },
    phone: { ref: 'phone', column: 'phone', label: 'Telefone' },
    product_id: {
      ref: 'product_id',
      column: 'product_id_response',
      includeColumn: 'title',
      label: 'Produto / Ingresso',
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    hash: { model: 'input', type: 'text', name: 'hash', label: 'Hash (Código Promocional)' },
    category: {
      model: 'selectSimple',
      category: 'text',
      name: 'category',
      label: 'Categoria',

      options: [
        { id: 'Patrocinador', value: 'Patrocinador', label: 'Patrocinador' },
        { id: 'Expositor', value: 'Expositor', label: 'Expositor' },
        { id: 'Imprensa', value: 'Imprensa', label: 'Imprensa' },
        { id: 'Organização', value: 'Organização', label: 'Organização' },
        { id: 'Staff', value: 'Staff', label: 'Staff' },
        { id: 'Fornecedores', value: 'Fornecedores', label: 'Fornecedores' },
        { id: 'Participantes', value: 'Participantes', label: 'Participantes' },
        { id: 'Caravana', value: 'Caravana', label: 'Caravana' },
        { id: 'Palestrantes', value: 'Palestrantes', label: 'Palestrantes' },
      ],
    },
    title: { model: 'input', type: 'text', name: 'title', label: 'Nome do Patrocinador' },
    quantity: { model: 'input', type: 'text', name: 'quantity', label: 'Quantidade de ingressos' },
    owner: { model: 'input', type: 'text', name: 'owner', label: 'Responsável' },
    phone: { model: 'input', type: 'text', name: 'phone', label: 'Telefone' },

    product_id: {
      alias: 'product_id',
      model: 'selectApi',
      name: 'product_id',
      label: 'Produto / Ingresso',

      endpoint,
    },
    client_id: {
      alias: 'client_id',
      model: 'selectApi',
      name: 'client_id',
      label: 'Comprador',

      endpoint,
    },
  };

  const formSchemaUpdate = formSchema;

  const formValidation: Record<string, ValidationSchema> = {

  };

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [

      () =>
        report({ projectHash })
    ],
    bodyModules: [

    ],
  };
  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default ExpositorsList;
