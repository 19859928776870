import React, { useState } from 'react';
import { useLanguage } from '../../../../../../../hooks/Language';
import Input from '../../../../../../DashboardPages/core/components/Forms/Input';
import MaskedInput from '../../../../../../DashboardPages/core/components/Forms/MaskedInput';
import SelectSimple from '../../../../../../../components/Forms/SelectSimple';
import calculateFinalPrice from '../../../Functions/calculateFinalPrice';
import generateInstallments from '../../../Functions/generateInstallments';

interface IProps {

    cart: Array<Record<string, any>>;
    couponInfo?: Record<string, any>;
    changeInstallment: Function;
}

const CreditCardForm: React.FC<IProps> = ({ cart = [], couponInfo = {}, changeInstallment }) => {

    const { translate } = useLanguage();

    return <>
        <Input name="card_number" placeholder={translate('Número do Cartão')} />
        <Input
            name="card_holder_name"
            placeholder={translate('Nome no Cartão')}
        />
        <MaskedInput
            mask="99/99"
            name="card_expiration_date"
            placeholder={translate('Validade')}
        />
        <Input name="card_cvv" placeholder="CVV" />


        <SelectSimple index={5}
            setCallback={(value) => { changeInstallment(value) }}
            name="installments"
            label={translate('Parcelas')}
            options={generateInstallments(calculateFinalPrice({ cart, coupon: couponInfo, paymentMethod: 'credit_card', installments: 1 }))}
        />

        <SelectSimple index={10} style={{ zIndex: 100000 }}
            options={[
                { value: 'cpf', label: translate('Pessoa Física') },
                {
                    value: 'cnpj',
                    label: translate('Entidade / Pessoa Jurídica'),
                },
            ]}
            name="document_type"

            label={translate('Emitir como')}
        />

    </>;
};
export default CreditCardForm;
