import React, { Dispatch, useRef, useCallback, useState, useEffect } from 'react';

import { Form } from '@unform/web';
import {
  FiMail,
  FiLock,
  FiUser,
  FiArrowRight,
  FiArrowLeft,
  FiKey,
} from 'react-icons/fi';
import { Link, useLocation, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FaArrowLeft } from 'react-icons/fa';
import { ISigla, readSigla } from '../../siglas';
import { useAuth } from '../../../../hooks/Auth';
import Input from '../../../../components/Forms/Input';
import MaskedInput from '../../../../components/Forms/MaskedInput';
import { useToast } from '../../../../hooks/Toast';
import { AnimatedDiv } from '../../styles';
import { Error, LoginContent } from './styles';
import getValidationErrors from '../../../../utils/getValidationErrors';
import SelectSimple from '../../../../components/Forms/SelectSimple';

import Button from '../../../../components/Button';
import { ActiveButton, BuyContext } from '../../index';
import api from '../../../../services/api';
import TextareaInput from '../../../../components/Forms/TextareaInput';
import Loader from '../../../../components/Loader';
import { countries } from './countries';
import getCep from '../../../../services/cep';
import { useLanguage } from '../../../../hooks/Language';
import CheckboxInput from '../../../../components/Forms/CheckboxInput';
import { authTitle, defaultProjectHash, recaptchaKey } from '../../../../config';
import ReCAPTCHA from 'react-google-recaptcha';
import { MdError } from 'react-icons/md';
import { LanguageIcons } from '../../../../components/Menu';
import states from './states';
import { useConfiguration } from '../../../../hooks/Configuration';
interface IRead {
  [key: string]: any;
}

interface ComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
  productId: string;
}

interface SignInData {
  email: string;
  password: string;
}

interface ICaptcha {
  getValue(): string;
}

interface LoginProps {
  [key: string]: string;
}

const LoginContainer: React.FC = ({

}) => {

  const query = new URLSearchParams(useLocation().search);


  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [buyType, setBuyType] = useState('');
  const [countryISO, setCountryISO] = useState('BR');

  const [step, setStep] = useState();



  const [company, setCompany] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const { translate, language } = useLanguage();
  const [captKey, setCaptKey] = useState(1);
  const siglas = readSigla();
  const [stepPosition, setStepPosition] = useState(2);
  const recaptchaRef = useRef(null);
  const [stateValue, setStateValue] = useState('');


  const [product, setProduct] = useState<Record<string, any>>({});
  const { eventHash, configuration } = useConfiguration();
  const { projectHash = defaultProjectHash, product_hash } = useParams<Record<string, any>>();

  const [companyArea, setCompanyArea] = useState('');
  const [foodIssue, setFoodIssue] = useState('');
  const [knowAbout, setKnowAbout] = useState('');


  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const handleLogin = useCallback(
    async (data: SignInData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),
          password: Yup.string()
            .required(`${translate('Senha')} ${translate('obrigatório')}`)


        });

        await schema.validate(data, { abortEarly: false });

        const { email, password } = data;

        await signIn({ email, password, project_id: projectHash, event_id: eventHash });

        setLoading(false);
        window.location.href = eventHash ? `/app/${projectHash}/event/${eventHash}/checkout/${product?.hash_link}` : `/app/${projectHash}/checkout/${product?.hash_link}`;
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          setLoading(false);
        }
        setLoading(false);
        addToast({
          type: 'error',
          title: translate('Erro na autenticação'),
          description: translate('Email ou senha inválidos'),
        });
      }
    },
    [signIn, addToast, setStep, product],
  );



  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLogin = useCallback(
    async (data: LoginProps) => {
      setLoading(true);



      if (knowAbout !== 'Outra') {
        data.know_about = knowAbout;
      }

      if (data?.zipcode) {
        data.zipcode = data.zipcode.toString().replace(/[^0-9]/g, '');
      }



      if (stepPosition === 2) {


        try {

          formRef.current?.setErrors({});


          const schema = countryISO === 'BR' ? Yup.object().shape({

            name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
            email: Yup.string()
              .required(`${translate('Email')} ${translate('obrigatório')}`)
              .email(`${translate('Email')} ${translate('obrigatório')}`),



            phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),

            password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),

            document_number: Yup.string()
              .min(11, `${translate('CPF')} ${translate('obrigatório')}`)
              .required(`${translate('CPF')} ${translate('obrigatório')}`),

            birth_date: Yup.string()
              .min(10, translate('min 10 Preecha a Data de Nascimento'))
              .max(10, translate('max 10 Preecha a Data de Nascimento'))
              .required(`${translate('Data de Nascimento')} ${translate('obrigatório')}`),




          }) : Yup.object().shape({

            name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
            email: Yup.string()
              .required(`${translate('Email')} ${translate('obrigatório')}`)
              .email(`${translate('Email')} ${translate('obrigatório')}`),



            phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),

            password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),

            document_number: Yup.string().required('Informe o número do seu documento'),


            birth_date: Yup.string()
              .min(10, translate('min 10 Preecha a Data de Nascimento'))
              .max(10, translate('max 10 Preecha a Data de Nascimento'))
              .required(`${translate('Data de Nascimento')} ${translate('obrigatório')}`),

          });
          data.phone = data.phone.toString().replace(/[^0-9]/g, '');
          data.birth_date.toString().replace(/[^0-9/]/g, '');
          data.document_number.toString().replace(/[^0-9]/g, '');



          await schema.validate(data, { abortEarly: false });
          setLoading(false);
          setStepPosition(22);
          setErrorMessage([]);
          return;
        }
        catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);
            if (formRef && formRef.current && formRef.current.setErrors) {
              formRef.current.setErrors(errors);
            }
            setLoading(false);

            const errorsString = Object.keys(errors).reduce(
              (prev: Array<string>, key: string): Array<string> => {
                prev = [...prev, errors[key]];
                return prev;
              },
              [],
            );

            setErrorMessage(errorsString);
            setCaptKey(state => state + 1);
            return;
          }

          setLoading(false);
          return;
        }

      }




      try {
        formRef.current?.setErrors({});


        const schema = countryISO === 'BR' ? Yup.object().shape({

          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),



          phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),

          password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),



          document_number: Yup.string()
            .min(11, `${translate('CPF')} ${translate('obrigatório')}`)
            .required(`${translate('CPF')} ${translate('obrigatório')}`),

          birth_date: Yup.string()
            .min(10, translate('min 10 Preecha a Data de Nascimento'))
            .max(10, translate('max 10 Preecha a Data de Nascimento'))
            .required(`${translate('Data de Nascimento')} ${translate('obrigatório')}`),

          zipcode: Yup.string()
            .min(8, `${translate('CEP')} ${translate('obrigatório')}`)
            .max(8, `${translate('CEP')} ${translate('obrigatório')}`)
            .required(`${translate('CEP')} ${translate('obrigatório')}`),
          address: Yup.string().required(`${translate('Endereço')} ${translate('obrigatório')}`),
          address_number: Yup.string().required(`${translate('Endereço')} ${translate('obrigatório')}`),
          neighborhood: Yup.string().required(`${translate('Bairro')} ${translate('obrigatório')}`),
          city: Yup.string().required(`${translate('Cidade')} ${translate('obrigatório')}`),
          state: Yup.string()
            .max(2, translate('No máximo 2 caracteres'))
            .required(translate(`${translate('Estado')} ${translate('obrigatório')}`)),


          know_about: Yup.string().required(`${translate('Campo Como soube do evento')} ${translate('obrigatório')}`),

        }) : Yup.object().shape({

          name: Yup.string().required(`${translate('Nome')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),



          phone: Yup.string().required(`${translate('Telefone')} ${translate('obrigatório')}`),

          password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),

          document_number: Yup.string().required('Informe o número do seu documento'),


          birth_date: Yup.string()
            .min(10, translate('min 10 Preecha a Data de Nascimento'))
            .max(10, translate('max 10 Preecha a Data de Nascimento'))
            .required(`${translate('Data de Nascimento')} ${translate('obrigatório')}`),


          address: Yup.string().required(`${translate('Endereço')} ${translate('obrigatório')}`),
          address_number: Yup.string().required(`${translate('Número do Endereço')} ${translate('obrigatório')}`),
          neighborhood: Yup.string().required(`${translate('Bairro')} ${translate('obrigatório')}`),
          city: Yup.string().required(`${translate('Cidade')} ${translate('obrigatório')}`),
          state: Yup.string().required(`${translate('Estado')} ${translate('obrigatório')}`),

          know_about: Yup.string().required(`${translate('Campo Como soube do evento')} ${translate('obrigatório')}`),
        });
        data.phone = data?.phone ? data?.phone?.toString().replace(/[^0-9]/g, '') : '';
        data.birth_date = data?.birth_date ? data?.birth_date?.toString().replace(/[^0-9/]/g, '') : '';
        data.document_number = data?.document_number ? data?.document_number?.toString().replace(/[^0-9]/g, '') : '';

        if (data?.company_document_number) {
          data.company_document_number = data?.company_document_number ? data?.company_document_number.toString().replace(/[^0-9]/g, '') : '';
        }
        if (countryISO === 'BR') {
          data.zipcode = data?.zipcode ? data?.zipcode.toString().replace(/[^0-9]/g, '') : '';
        }


        let {
          instituicao,
          category_id,
          crm,
          cpf_familiar,
          name,
          repeat_email,
          email,
          password,
          company,
          phone,
          position,
          document_type,
          document_number,

          birth_date,
          company_document_number,
          extra_1,
          extra_2,
          extra_3,
          extra_4,
          extra_5,
          education_id,
          especialidade,
          country,
          address,
          address_number,
          neighborhood,
          city,
          state,
          zipcode,
          gender,
          facebook,
          term
        } = data;


        await schema.validate(data, { abortEarly: false });





        const pais = countries.findIndex(c => c.value === country);
        if (pais) {
          extra_3 = countries[pais].dialCode;
          extra_2 = countries[pais].label;
        }

        const captchaValue =
          getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
          false;

        const newUser = await api.post('/signup', {

          ...data,
          project_id: projectHash,
          event_id: eventHash,

          document_number: document_number.toString().replace(/[^0-9]/g, ''),
          term,
          captcha: captchaValue,
        });

        if (!newUser) {
          setLoading(false);
          return false;
        }
        await signIn({ email, password });

        if (newUser?.data?.confirmationCode) {
          window.location.href = eventHash ? `/app/${projectHash}/event/${eventHash}/payment/success/${newUser?.data?.confirmationCode}` : `/app/${projectHash}/payment/success/${newUser?.data?.confirmationCode}`;

        }
        else {
          window.location.href = eventHash ? `/app/${projectHash}/event/${eventHash}/checkout/${product?.hash_link}` : `/app/${projectHash}/checkout/${product?.hash_link}`;

        }

        setLoading(false);

        window.scrollTo(0, 0);

        return false;
      }
      catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          if (formRef && formRef.current && formRef.current.setErrors) {
            formRef.current.setErrors(errors);
          }
          setLoading(false);

          const errorsString = Object.keys(errors).reduce(
            (prev: Array<string>, key: string): Array<string> => {
              prev = [...prev, errors[key]];
              return prev;
            },
            [],
          );

          setErrorMessage(errorsString);
          setCaptKey(state => state + 1);
          return;
        }

        setErrorMessage([
          err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao gerar seu cadastro',
        ]);
      }
      setCaptKey(state => state + 1);
      setLoading(false);

    },
    [addToast, product, setStep, signIn, countryISO, stepPosition, knowAbout],
  );


  const handleProduct = useCallback(
    productInfo => {
      productInfo.quantity = 1;

      setProduct(productInfo);

    },
    [setStep],
  );

  const loadProduct = async () => {

    if (product_hash === 'courtesy') {
      handleProduct({ title: 'Cortesia', hash_link: 'courtesy' });
      return;
    }

    try {
      const response = await api.get(`/product/${product_hash}/${projectHash}`);
      handleProduct(response.data);
      return;
    } catch (err) {

      addToast({ title: translate('Erro ao identificar produto'), type: 'error' });
      return;
    }


  }

  useEffect(() => {


    if (configuration && product_hash) {
      loadProduct()
    }



  }, [configuration, product_hash])


  if (configuration?.current_event_id_response?.status && configuration?.current_event_id_response?.status !== 'Sim') {
    return <AnimatedDiv visible style={{ minHeight: '80vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >

      <div style={{ display: 'flex', flexDirection: 'column', background: '#fff', borderRadius: '5px', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
        <h2 style={{ background: '#fff', padding: '15px', borderRadius: '5px' }}>Inscrições encerradas!</h2>
      </div>
    </AnimatedDiv>
  }


  return (
    <AnimatedDiv visible={true}>
      <LoginContent>
        {loading && <Loader message="Carregando" />}
        <div style={{ width: '100%', justifyContent: 'center' }}>
          {' '}
          <aside style={{ width: '100%', maxWidth: '100%' }}>
            {buyType === '' && (
              <AnimatedDiv visible style={{ paddingTop: '40px' }}>

                <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                  <p
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      fontSize: '12px',
                      color: '#333',
                      marginBottom: '20px'
                    }}
                    onClick={() => { window.location.href = `/app/${projectHash}/inscricao`; }}


                  >
                    <FaArrowLeft color="#333" />
                    {' '}
                    {translate('Voltar')}
                  </p>

                  <ActiveButton step={11} />

                  {product.title ? <aside style={{ fontSize: '14px', lineHeight: '18px', maxWidth: '350px', margin: '15px auto', color: '#333', width: '100%', textAlign: 'center' }}><strong>{product?.[`title_${language}`] ? product?.[`title_${language}`] : product.title}</strong></aside> : <></>}
                  <button className='defaultButton' style={{ margin: '15px', width: '100%', maxWidth: '340px', minHeight: '50px', padding: '10px' }}

                    onClick={() => setBuyType('new')}
                  >
                    {translate('Novo Cadastro')}
                  </button>

                  <p style={{ width: '100%', background: '#333', height: '2px', position: 'relative', margin: '20px 0px' }}>
                    <p style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%) translateY(-20px)', fontSize: '12px', background: '#333', color: '#fff', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {translate('OU')}
                    </p>
                  </p>

                  <Form className="form" style={{ margin: '15px' }} ref={formRef} onSubmit={handleLogin}>
                    <h2 style={{ marginBottom: '20px' }}>{translate('Faça seu login')}</h2>

                    <Input icon={FiMail} name="email" placeholder={translate('E-mail')} />
                    <Input icon={FiKey} type='password' name="password" placeholder={translate('Senha')} />
                    <aside className="form2" style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'stretch', marginTop: '20px', padding: '0px' }}>
                      {/*  <Input
                 icon={FiLock}
                 name="password"
                 type="password"
                 placeholder={translate('Senha')}
             /> */}
                      <button className="defaultButton" style={{ padding: '10px', margin: '10px auto', width: '200px' }} type="submit">{translate('LOGIN')}</button></aside>
                    <aside style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                      <Link className="underline" style={{ marginTop: '10px' }} to="/recuperar_senha">
                        {translate('Esqueceu a senha? Clique aqui!')}
                      </Link>
                    </aside>

                  </Form>


                </div>

              </AnimatedDiv>
            )}
            {buyType !== '' && (
              <AnimatedDiv visible>


                {buyType === 'login' && (
                  <Form className="form" ref={formRef} onSubmit={handleLogin}>

                    <h1 className="defaultMarked"><div />{translate('LOGIN')}</h1>
                    <Input icon={FiMail} name="email" placeholder={translate('E-mail')} />

                    <Input
                      icon={FiLock}
                      name="password"
                      type="password"
                      placeholder={translate('Senha')}
                    />
                    <Button type="submit">{translate('Entrar')}</Button>
                    <Link style={{ color: '#333' }} to="/recuperar_senha">
                      {translate('Esqueci minha senha')}
                    </Link>
                    <p
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        fontSize: '12px',
                        color: '#333',
                        marginTop: '20px'
                      }}
                      onClick={() => setBuyType('')}


                    >
                      <FaArrowLeft color="#333" />
                      {' '}
                      {translate('Voltar')}
                    </p>
                  </Form>

                )}

                {buyType === 'new' && (
                  <Form className="form" ref={formRef} onSubmit={handleNewLogin} initialData={{ country: 'BR' }}>
                    <ActiveButton step={stepPosition} />
                    {/*  <p style={{width: '100%', padding: '10px', margin: '20px 0px', color: '#333', display:'flex', justifyContent:'center', alignItems: 'center', background:'#fff'}}><span style={{marginRight: '5px'}}>{translate('Selecionar idioma')}: </span> <LanguageIcons/></p>
                  */}




                    <div style={{ width: '100%', background: 'none', flexDirection: 'column', display: stepPosition === 2 ? 'flex' : 'none' }}>
                      <h2 style={{ width: '100%', fontSize: '18px', color: '#333', textAlign: 'center', margin: '0px auto', marginTop: '40px' }} ><div />{translate('Novo cadastro')}</h2>


                      <SelectSimple setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={translate('Pais')} />

                      <Input name="name" placeholder={translate('Nome completo')} />

                      {countryISO === 'BR' ? <MaskedInput
                        mask="(99) 99999.9999"
                        name="phone"
                        placeholder={translate('Telefone / Whatsapp')}
                      /> : <Input name="phone"
                        placeholder={translate('Telefone')} />}

                      <MaskedInput
                        mask="99/99/9999"
                        name="birth_date"
                        placeholder={translate('Data de Nascimento (DD/MM/AAAA)')}
                      />

                      {countryISO === 'BR' ? <Input style={{ display: 'none' }} name="document_type" value="cpf"
                        placeholder={translate('Tipo de documento')} /> : <SelectSimple name="document_type"
                          label={translate('Tipo de Documento (Passaporte / Outro)')} options={[{ label: translate('Passaporte'), value: 'passport' }, { label: translate('Outro'), value: 'other' }]} />}



                      {countryISO === 'BR' ? <MaskedInput
                        mask="999.999.999-99"
                        name="document_number"
                        placeholder={translate('CPF')}
                      /> : <Input name="document_number"
                        placeholder={translate('Nº do documento')} />}






                      <p style={{ fontSize: '14px', textAlign: 'center', fontWeight: 'bold', marginTop: '30px', marginBottom: '15px' }}> {translate('Crie seu acesso')}</p>

                      <Input name="email" placeholder={translate('E-mail')} />


                      <Input
                        icon={FiLock}
                        name="password"
                        type="password"
                        placeholder={translate('Cadastre sua senha de acesso')}
                      />













                      {errorMessage && (
                        <Error>
                          {errorMessage.map((item, index) => (
                            <div key={`error${index}`}>
                              <MdError />
                              {item}
                            </div>
                          ))}
                        </Error>
                      )}
                      <aside style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button>{translate('Continuar')}</Button></aside>



                    </div>
                    <div style={{ width: '100%', background: 'none', display: stepPosition === 22 ? 'flex' : 'none', flexDirection: 'column' }}>

                      <button onClick={() => setStepPosition(2)} type="button" className='defaultButtonMini'><FaArrowLeft size={20} style={{ marginRight: '10px' }} />Voltar</button>

                      <h2 style={{ width: '100%', fontSize: '18px', color: '#333', textAlign: 'center', margin: '0px auto', marginTop: '40px' }} ><div />{translate('Meu endereço')}</h2>

                      {countryISO === 'BR' ?
                        <MaskedInput

                          setCallback={getCepFromApi}
                          mask="99999999"
                          name="zipcode"
                          placeholder={translate('CEP')}
                        /> : <Input name="zipcode" placeholder={translate('ZIP Code')} />}
                      <Input name="address" placeholder={translate('Endereço')} />
                      <Input name="address_number" placeholder={translate('Número / Apartamento')} />
                      <Input name="neighborhood" placeholder={translate('Bairro')} />
                      <Input name="city" placeholder={translate('Cidade')} />
                      {countryISO === 'BR' ? <MaskedInput mask="aa" name="state" placeholder={translate('Estado')} /> :

                        <Input name="state" placeholder={translate('Província / Região')} />}

                      <Input name="company"
                        placeholder={`${translate('Qual o nome da empresa em que você trabalha')}`} />




                      <Input name="position"
                        placeholder={`${translate('Cargo')}`} />

                      <SelectSimple index={2}
                        setCallback={(value) => { setKnowAbout(value) }}
                        options={[
                          { label: 'WhatsApp', value: 'WhatsApp' },
                          { label: translate('Indicação'), value: 'Indicação' },
                          { label: 'Instagram', value: 'Instagram' },
                          { label: 'Facebook', value: 'Facebook' },
                          { label: 'Linkedin', value: 'Linkedin' },
                          { label: translate('E-mail marketing'), value: 'E-mail marketing' },
                          { label: 'SMS', value: 'SMS' },
                          { label: 'Workplace', value: 'Workplace' },
                          { label: 'Google', value: 'Google' },
                          { label: translate('Outra'), value: 'Outra' },
                        ]}
                        name="know_about"
                        formRef={formRef}
                        label={translate("Como soube do evento?")}
                      />

                      {knowAbout === 'Outra' ? <Input key={knowAbout} name="know_about"
                        placeholder={`${translate('Qual?')}`} /> : <></>}


                      <h2 style={{ width: '100%', fontSize: '18px', color: '#333', textAlign: 'center', margin: '0px auto', marginTop: '40px' }}>{translate('Dados da empresa')}</h2>
                      <p style={{ width: '100%', fontSize: '12px', color: '#333', textAlign: 'center', marginBottom: '20px' }} >{translate('Em caso de inscrições custeadas por alguma empresa/entidade, preencha o cnpj')}:</p>



                      {countryISO === 'BR' ? <MaskedInput

                        mask="99.999.999/9999-99"
                        name="company_document_number"
                        placeholder={`${translate('CNPJ da Empresa / Entidade')}`}
                      /> :
                        <Input name="company_document_number"
                          placeholder={`${translate('Nº de identificação da Empresa / Entidade')}`} />}


                      <CheckboxInput
                        style={{ display: 'flex' }}
                        name="term"
                        unique={true}
                        preText={(
                          <>
                            {' '}
                            Estou ciente de que estou fornecendo ao {authTitle} e seus parceiros meus contatos gerais, os quais são dados pessoais protegidos pela Lei Geral de Proteção de Dados Brasileira, para gestão de relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.
                            {' '}

                          </>
                        )}
                        options={[
                          {
                            id: `Sim`,
                            label: translate('Sim'),
                            value: `Sim`,
                          },
                          {
                            id: `Não`,
                            label: translate('Não'),
                            value: `Não`,
                          },
                        ]}
                      />





                      <p
                        style={{
                          padding: '10px',
                          background: 'none',
                          margin: '5px auto',
                          color: '#333',
                          fontSize: '12px',
                          textAlign: 'left',
                        }}
                      >

                        {' '}
                        <strong>
                          <Link to="/termos"
                          >
                            {translate('Ao me cadastrar, afirmo que li e aceito a ')}  {translate('POLÍTICA DE PRIVACIDADE')} {translate(' desta plataforma')}
                            {' '}
                          </Link>
                          {' '}
                        </strong>

                      </p>

                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <ReCAPTCHA key={`recapt-${captKey}`} ref={recaptchaRef} sitekey={recaptchaKey} />
                      </div>
                      {errorMessage && (
                        <Error>
                          {errorMessage.map((item, index) => (
                            <div key={`error${index}`}>
                              <MdError />
                              {item}
                            </div>
                          ))}
                        </Error>
                      )}
                      <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <Button type="submit">
                          {translate('Continuar')}

                          <FiArrowRight />
                        </Button>
                      </aside>

                    </div>
                  </Form>
                )}
              </AnimatedDiv>
            )}
          </aside>
        </div>
      </LoginContent>
    </AnimatedDiv>
  );

};

export default LoginContainer;
