import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";

import { FaFileExcel, FaFilter } from "react-icons/fa";

import { convertToXLSX, downloaditPost } from "../../utils/download";
import { apiUrl } from "../../config";
import { formatReal } from "../../utils/format";

interface IData {
    value: number;
    name: string;
    total: number;
    simpleName?: string;
    percentage?: string;
}

interface IChart {
    title?: string;
    subtitle?: string;
    data: Array<Record<string, any>>;
    category?: string;
    group?: string;
    chartType?: string;
    sortBy?: string;
    showTotal?: boolean;
}

interface IConverter {
    data: IData[];
    type: string;
    title: string;
}

const Chart: React.FC<IChart> = ({
    showTotal = false,
    category = '',
    group = '',
    sortBy = "value",
    data = [],
    title = '',
    subtitle = '',
    chartType = "pie"
}) => {

    const [renderData, setRenderData] = useState<IData[]>([]);
    const [groupColumn, setGroupColumn] = useState(group);
    const [type, setType] = useState(chartType);

    const convertToArrayAndExport = ({ data, type = 'xlsx', title }: IConverter) => {
        const content = [[title, 'Quantidade', 'Porcentagem']];

        data.forEach(item => {
            content.push([item.simpleName || '', item.value.toString() || '0', item.percentage?.toString()?.replace('.', ',') || '0']);
        });

        return downloaditPost({ url: `${apiUrl}/converter/${type}`, data: content, type });
    }

    const changeGroup = (value: string) => {
        setGroupColumn(value);
    }
    const renderDataInfo = async (groupInfo: string, dataContent: Array<Record<string, any>>) => {
        if (!dataContent || dataContent.length === 0) return;

        const dataItems: IData[] = dataContent.reduce((prev: IData[], item: Record<string, any>) => {
            const index = prev.findIndex(i => i.simpleName === item[groupInfo]);

            if (index >= 0) {
                prev[index].value += 1;
                prev[index].total += item.total;
            } else {
                prev.push({ simpleName: item[groupInfo], name: item[groupInfo], value: 1, total: item.total });
            }
            return prev;
        }, []);

        if (dataItems.length > 0) {
            dataItems.sort((a, b) => a[sortBy] < b[sortBy] ? 1 : -1);
        }

        const total = dataItems.reduce((prev, item) => prev + item.value, 0);

        dataItems.forEach(d => {
            d.percentage = ((d.value / total) * 100).toFixed(2);
            d.name = `${d.name} - ${d.value} - ${d.percentage}%`;
        });

        setRenderData(dataItems);
    };

    useEffect(() => {
        console.log('started');
        const fetchData = async () => {
            await renderDataInfo(group, data);
        };
        fetchData();
    }, [data, group]);

    const getOption = () => {


        switch (chartType) {
            case 'bar':
                return {
                    title: {
                        text: title,
                        subtext: subtitle,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: { type: 'shadow' }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: renderData.map(item => item.simpleName || item.name)
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        name: category,
                        type: 'bar',
                        data: renderData?.reduce((prev, item) => {
                            prev.push(item?.value)
                            return prev;
                        }, [] as Number[]),
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                };
            case 'pie':
            default:
                return {
                    title: {
                        text: title,
                        subtext: subtitle,
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: '{a} <br/>{b} : {c} ({d}%)'
                    },
                    xAxis: {
                        type: 'category',
                        data: []
                    },
                    series: [{
                        name: category,
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: renderData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }]
                };
        }
    };

    const convert = (list: Array<Record<string, any>>) => {
        const items: Array<Array<string>> = [];

        list.forEach(item => {
            items.push([item.simpleName, item.value, item.percentage]);
        });

        convertToXLSX(items);
    }

    return (
        <div className="container column" style={{ width: '100%' }}>
            <div className="container row-to-column row-to-column-start gap-sm pad" style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                <div className="row" style={{ width: '100%', maxWidth: chartType === 'pie' ? '1000px' : '1000px', overflowX: 'auto' }}>
                    {/*renderData.length > 0 ? <ReactEcharts option={getOption()} style={{
                        height: '50vh',
                        width: chartType === 'pie' ? '100%' : `${renderData.length * 100}px`,
                        minWidth: '100%',
                    }} /> : <></> */}
                </div>
                <div style={{ width: '100%', maxWidth: '400px' }} className="container column column-start max-400 overflow-hidden-height">
                    <table className="tableRef" style={{ width: '100%', maxWidth: '400px' }}>
                        <thead>
                            <tr>
                                <th className="row-space-between">Total</th>
                                <th>{renderData.reduce((prev, item) => prev + item.value, 0)}</th>
                                <th><FaFileExcel onClick={() => convert(renderData)} style={{ cursor: 'pointer', fontSize: '24px', color: 'rgb(0,150,100)' }} /></th>
                                {showTotal && <th>R$ {formatReal(renderData.reduce((prev, item) => prev + item.total, 0))}</th>}
                            </tr>
                            <tr>
                                <th className="row-space-between"></th>
                                <th>QTD</th>
                                <th>%</th>
                                {showTotal && <th>R$</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {renderData.map(item => (
                                <tr key={`render-data-${group}-${item.name}`}>
                                    <td>{item.simpleName}</td>
                                    <td>{item.value}</td>
                                    <td>{item.percentage}%</td>
                                    {showTotal && <td>R$ {formatReal(item.total)}</td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Chart;