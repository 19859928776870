import React from 'react';


import {

  Capa,



} from './styles';

import ProjectList from '../ProjectList';
import EventList from '../DashboardPages/features/EventsList';



const Home: React.FC = () => {


  return (
    <>


      <ProjectList filterList={['acls', 'acl-']} title="Cursos AHA no HCPA - ACLS" />




    </>
  );
};
export default Home;
