import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { FaCalendarCheck, FaReceipt } from 'react-icons/fa';

interface ICreateModule {
  lineData: Record<string, any>;
}

export default function UpdateModule({

  lineData,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();



  const moduleUpdate = (line: Record<string, any>): JSX.Element => (
    line.payment_status_id === 'paid' && line.client_id_response?._id ? <a title='Comprovante de inscrição' href={`/app/${line.project_id}/confirmacao/${line.ticket_hash}`} target="_BLANK"><button className="lineIcon" type="button"  >
      <FaReceipt />
    </button> </a> : <></>
  );

  return moduleUpdate(lineData);
}
