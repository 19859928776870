import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField, FormHandles } from '@unform/core';
import { Container } from './styles';
import { Error } from '../styles';
import { FaPlus } from 'react-icons/fa';
import { uuid } from 'uuidv4';

interface IListInput {
  label: string;
  name: string;
  value ?: string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list?: Array<IListInput>;
  placeholder: string;
}

const ArrayInputIncluder: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => {},
  list,
  ...rest
}): JSX.Element => {
  const { fieldName, defaultValue, error, registerField } = useField(name);


  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);


  const [value, setNewValue] = useState(defaultValue || ['']);

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      getValue: (ref: any) => {
        return JSON.parse(ref.value);
      },
      setValue: (ref: any,valueN : string) => {
        setNewValue( valueN ? JSON.parse(valueN) : ['']);
      }, 
    });
  }, [fieldName, registerField]);

  const addInput = () => {
    const newHash = `q${new Date().getMilliseconds()}`;
    setNewValue([...value,'']);
  }



  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button type="button" onClick={addInput}>
        <FaPlus/> Adicionar
      </button>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        name={name}
        value={JSON.stringify(value)}
      />

     
      {value && value.map((v,index) => {

        return (
          <Container
            key={v.name}
            {...rest}
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocus}
          >
      
            <input
              placeholder={v.label}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              defaultValue={ v}
              onChange={e => {
                const newValue = [...value];
             

                  newValue[index] = e.target.value;
           
                
               
                  setNewValue([...newValue ]);     }
              }
              name={v.name}
            />
            {error && (
              <Error title={error}>
                <FiAlertCircle />
              </Error>
            )}
          </Container>
        );
      })}

    </div>
  );
};

export default ArrayInputIncluder;
