import React from 'react';

import { Container } from './styles';
import modal from './Modal';
import { IModalMessage } from '../../hooks/Modal';

interface IModalContainerProps {
  messages: IModalMessage[];
  className: string;
  closeButton: boolean;
}

const ModalContainer: React.FC<IModalContainerProps> = ({ messages, className, closeButton = true }) => {
  return (<div style={{ width: '0px', height: '0px', zIndex: 10000000000, padding: '0px', margin: '0px', border: '0px', position: 'relative' }}>
    <Container>
      {messages.map(item =>
        modal({ key: item.id, title: item.title, content: item.content, className, closeButton }),
      )}
    </Container></div>
  );
};

export default ModalContainer;
