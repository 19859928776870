import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';

import { useField, FormHandles } from '@unform/core';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Container } from './styles';
import { Error } from '../styles';
import { uuid } from 'uuidv4';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  defaultV?: string;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  formRef,
  defaultV,
  setCallback = () => { },
  ...rest
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const [keyItem, setKeyItem] = useState(uuid());

  const selectRef = useRef<ClassicEditor>(null);

  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {

    if (defaultValue) {
      setCurrentValue(defaultValue);
      setKeyItem(uuid());
    }

  }, [defaultValue])


  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,

      getValue: (ref: any) => {

        return currentValue;
      },
      setValue: (ref: any, value) => {
        ref.props.data = value;
      },
    });
  }, [fieldName, registerField, currentValue]);

  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  useEffect(() => {
    registerField({ name: fieldName, ref: inputRef.current, path: 'value' });
  }, [fieldName, registerField]);

  return (
    <Container
      {...rest}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocus}
    >
      <span>{rest.placeholder}</span>
      {Icon && <Icon />}
      <CKEditor
        key={keyItem}
        ref={selectRef}
        editor={ClassicEditor}
        data={currentValue}
        onInit={editor => { }}
        onChange={(event, editor) => {
          const data = editor.getData();
          setCurrentValue(data);
        }}
        onBlur={(event, editor) => {
          console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          console.log('Focus.', editor);
        }}
      />

      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};

export default Input;
