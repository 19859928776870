import React, { useCallback, useRef, useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';
import { Link, useParams } from 'react-router-dom';
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';
import api from '../../services/api';
import { date, hour, dateLanguage, simpleDate, day, month, fullDate, dateSimpleMonth, checkIsAfterDate } from '../../utils/date';
import { Container } from './styles';
import { addHours } from 'date-fns';
import { useToast } from '../../hooks/Toast';
import { Form } from '@unform/web';

import { string } from 'yup';

import { useLanguage } from '../../hooks/Language';
import { FaArrowDown, FaArrowUp, FaLock, FaSearch } from 'react-icons/fa';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import Favorite from '../../components/ClassRoomContainer/modules/Favorite';
import { useAuth } from '../../hooks/Auth';
import { useConfiguration } from '../../hooks/Configuration';
import { urlWebsite } from '../../config';

interface IOption {
  value: string;
  label: string;
}

interface IProgram {
  start_at: Date;
  next_date: Date;
  formatted_date?: string;
  start_hour: Date;
  formatted_start_hour?: string;
  end_date: Date;
  formatted_end_date?: string;
  title: string;
  category: string;
  room_reference_id_response: Record<string, any>;
  description: string;
  day_reference?: string;
  status?: string;
}

interface IProgramFilter {
  formatted_date: string;
  program: Array<IProgram>;
  simpleDate?: string;
  day_reference?: string;
}

interface IProps {
  filterList?: Array<string>;
  title?: string;
}

const ProjectList: React.FC<IProps> = ({ filterList, title }) => {
  const [programs, setPrograms] = useState<Array<Record<string, any>>>([]);
  const [referenceProgram, setReferenceProgram] = useState<Array<Record<string, any>>>([]);
  const [currentDay, setCurrentDay] = useState(0);
  const { projectHash, eventHash } = useConfiguration();
  const [search, setSearch] = useState('');
  const [currentProgram, setCurrentProgram] = useState('');
  const [roomList, setRoomList] = useState<Array<IOption>>([]);
  const { addToast } = useToast();
  const { user, handleApiErrors } = useAuth();
  const { translate } = useLanguage();

  const { configuration } = useConfiguration();

  const [filter, setFilter] = useState({
    date: '',
    category: '',
    search: ''
  });

  const addFilter = (column, value) => {

    const newFilter = { ...filter };
    newFilter[column] = value;

    setFilter(newFilter);

  }

  const newSearch = (data) => {

    const newFilter = { ...filter };
    newFilter.search = search;

    setFilter(newFilter);

  }

  useEffect(() => {

    let programsContent: Array<Record<string, any>> = [...programs];







    if (filter.search !== "") {

      programsContent = programsContent.filter(item => {
        return item.title.toString().toLowerCase().trim().indexOf(filter.search.toLowerCase().trim()) >= 0

      });
    }

    setReferenceProgram(programsContent);

  }, [programs, filter])

  const load = async () => {


    const response = await api.get(`/get-events`);

    let events = response.data.rows?.filter(elem => {
      const eventNotEnd = !elem?.event_end ? false : elem?.event_end && !checkIsAfterDate(elem?.event_end) ? true : false;
      return !eventNotEnd;
    })

    events = events.filter(item => item?.status === 'Sim')


    events.sort((a, b) => {

      console.log(a?.start_at, a?.title, a.start_at > b.start_at ? 1 : a.start_at < b.start_at ? -1 : 0);
      console.log(b?.start_at, b?.title, a.start_at > b.start_at ? 1 : a.start_at < b.start_at ? -1 : 0)

      return a.start_at > b.start_at ? 1 : a.start_at < b.start_at ? -1 : 0
    })

    if (filterList && filterList?.length > 0) {

      const newEvents: Record<string, any> = [];

      filterList.map((key) => {

        const items = events.filter(i => i?.url.indexOf(key) >= 0);

        if (items?.length > 0) {
          items?.map(item => {
            newEvents.push(item);
          })
        }



      })

      events = newEvents;
    }


    setPrograms(events);
    setReferenceProgram(events);
  }

  useEffect(() => {

    load();
  }, []);

  const getType = (type) => {

    const valid = {
      free: <button className='free'>Gratuito</button>,

    }

    return valid[type] ? valid[type] : '';

  }

  const getEventType = (type) => {

    const valid = {
      Online: <button className='online'>Online</button>,
      "Híbrido": <button className='hibrid'>Híbrido</button>,
      "Presencial": <button className='local'>Presencial</button>,
    }

    return valid[type] ? valid[type] : '';

  }

  const line = program => {

    return <Link className='projectDiv' to={`/app/${program.url}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: 'rgba(0,0,0,0.8)', borderRadius: '15px', justifyContent: 'flex-start', width: '100%', maxWidth: '300px', minWidth: '300px', margin: '10px' }}>
      <div style={{ background: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '15px 15px 0px 0px', width: '100%', padding: '10px' }}><div style={{ width: '100%', maxWidth: '250px', paddingBottom: '56.25%', background: `url("${program?.image ? `${urlWebsite}/${program?.image}` : `/apoio/agenda.png`}") no-repeat center`, backgroundSize: 'contain' }}>
      </div></div>
      <div className='projectContent' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
        <div className='projectTypeButtons'>
          {program?.type && getType(program.type)}
          {program?.event_type && getEventType(program.event_type)}
        </div>
        <strong className='projectTitle' >
          {' '}

          {program.title}
          {' '}


        </strong>
        <p className='projectDate'  > {program.date_text}</p>
        <div className='projectTypeButtons'>
          <button className='projectSubscribe'>Saiba mais</button>
        </div>
      </div>

    </Link>


  };
  const { length } = programs;
  return (
    <Container id="programacao">
      <div className='searchHeader'>

        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'flex-start' }}><h1 style={{ color: '#fff', textAlign: 'left' }}>Portal de inscrições</h1>
          {title ? <h2 style={{ color: '#fff', textAlign: 'left' }}>{title}</h2> : <></>}
        </div>


        {configuration?.theme_data?.program_header === 'no' ? <></> : <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'center', justifyContent: 'center', width: '100%' }}>



          <Form
            style={{ display: 'flex', alignItems: 'stretch', background: 'none', margin: '5px' }}
            onSubmit={newSearch}
          >


            <input style={{ height: '43px', padding: '10px', borderRadius: '10px', border: '1px solid #ddd' }}
              name="pesquisa"
              type="text"
              placeholder={`${translate('Escreva para pesquisar')}...`}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />

            <button style={{ width: '43px', height: '43px', background: '#0c9490', color: '#fff', borderRadius: '10px', border: '0px', margin: '0px 5px' }} type="submit"><FaSearch size={20} /></button>
          </Form>


        </div>}

      </div>


      {referenceProgram.length > 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', flexWrap: 'wrap' }}>



        {referenceProgram?.map(items => items.status === 'Sim' ? line(items) : <></>)}
      </div> : <></>
      }
    </Container>
  );
};
export default ProjectList;
