import userEvent from '@testing-library/user-event';
import React, { useState, createContext, useContext, useEffect } from 'react';
import StateManager from 'react-select';

import { apiUrl, apiSocket } from '../config';
import { useAuth } from './Auth';

interface IMenu {
  title: string;
  target: string;
  targetID: string;
  fluid: number;
  external:  number;
  status:  number;
}

interface IPagesContextData {
  menu: Array<Record<string, any>>;
  pages: Array<Record<string, any>>;
  prepareMenu : Function;
  readPages: Function;
  setMenu(menu: Array<IMenu>): void;
}



const PagesContext = createContext<IPagesContextData>({} as IPagesContextData);

const PagesProvider: React.FC = ({ children }) => {
  const {user} = useAuth();
  const [pages, setPages] = useState([
    { tag: 'home', status: 2 },
    { tag: 'patrocinadores', status: 1 },
    { tag: 'programacao', status: 1 },
    { tag: 'sobre', status: 1 },
    { tag: 'palestrantes', status: 1 },
    { tag: 'login', status: 2 },
    { tag: 'inscricao', status: 2 },
  ]);
  

  const prepareMenu = ({ projectHash, eventHash, active_pages }) => {

    const menuContent : Array<IMenu> = [];


    if(active_pages.length >= 0){
      active_pages.map( page => {

     

        if(page.menu === 'yes' && page.active === 'yes'){

        let target = eventHash ? `/app/${projectHash}/event/${eventHash}/${page.url}` : `/app/${projectHash}/${page.url}`;
     
        let targetID = '';
        let fluid = 1;
        let external = 1;
        let status = 2;

        if(page.signed === 'yes'){
          if(!user){
            status = 1;
          }
        }

        if(page.type === 'external'){
      
          target = page.url;
          external = 2;
        }

        if(page.type === 'page'){
          fluid = 2;
          targetID = page.url;
        }

          const item = {
            title: page.title,
            target: target,
            targetID: targetID,
            fluid: fluid,
            external: external,
            status: status,
          }

          menuContent.push(item);



        }

      })
    }

    if(user?.profile === 1 || user?.profile === 4){
      const gerenciador = {
        title: 'Gerenciador',
        target: '/manager',
        targetID: '',
        fluid: 1,
        external: 1,
        status: 2,
      }

      menuContent.push(gerenciador);
    }

    if(user && projectHash){
      const gerenciador = {
        title: 'Palestras',
        target: `/app/${projectHash}/dashboard`,
        targetID: '',
        fluid: 1,
        external: 1,
        status: 2,
      }

      menuContent.push(gerenciador);
    }


    setMenu( menuContent);


  }

  const [menu, setMenu] = useState<Array<IMenu>>([]);

  

  const readPages = () => {
    const pagesResponse = pages.reduce((prev, elem) => {
      prev[elem.tag] = elem;

      return prev;
    }, {});

    return pagesResponse;
  };

  return (
    <PagesContext.Provider value={{ pages, menu, setMenu, readPages, prepareMenu }}>
      {children}
    </PagesContext.Provider>
  );
};

function usePages(): IPagesContextData {
  const context = useContext(PagesContext);

  if (!context) {
    throw new Error('usePages must be used within a PagesProvider');
  }

  return context;
}

export { usePages, PagesProvider };
