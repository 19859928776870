import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Container, CertificateLine, Error } from './styles';
import Input from '../../components/Forms/Input';
import { useToast } from '../../hooks/Toast';
import { Button } from '../../components/Button/styles';
import api from '../../services/api';
import Loader from '../../components/Loader';
import { authTitle, hash, urlWebsite } from '../../config';
import CheckboxInput from '../../components/Forms/CheckboxInput';
import * as Yup from 'yup';
import { useLanguage } from '../../hooks/Language';
import { useAuth } from '../../hooks/Auth';
import getValidationErrors from '../../utils/getValidationErrors';

import Research from './FormContainer';
import Exam from './FormContainer2';
import { MdError } from 'react-icons/md';
import { useModal } from '../../hooks/Modal';
import { Link, useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/Configuration';
import { handleCertificateDownload } from '../DashboardPages/pages/CertificatesUsers/modules/ExportPDF';
import QRCode from 'qrcode.react';
import { date } from '../../utils/date';
import { FaXing } from 'react-icons/fa';
import { FiXOctagon } from 'react-icons/fi';
interface ICertificates {
  certificate_id_response: { title: string };
  hash: string;
}

interface IOptions {
  id: string;
  value: string;
  label: string;


}




interface IAnswers {
  id: string;
  value: string;
  label: string;
}

interface IQuestion {
  title: string;
  options: Array<{ question: string, value: string }>;
  correct: string;
}

interface IQuestions {
  title: string;
  questions: Array<IQuestion>
}

interface IResponse {
  number: number;
  answers: Array<string>;
}

interface ICertificateUser {
  id: string;
  answers: Array<Record<string, any>>;
  hash: string;
  certificate_id_response: {
    proof: IQuestions
  },
  user_id: number;
}





const Certificados: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const { translate, language } = useLanguage();
  const { user } = useAuth();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [getNumber, setGetNumber] = useState(0);
  const [emailInfo, setEmailInfo] = useState('');
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const { addModal } = useModal();
  const { hash } = useParams<Record<string, string>>();
  const { configuration } = useConfiguration();
  const [certificate, setCertificate] = useState<Record<string, any>>();


  const loadCertificate = async (hashID) => {
    setLoading(true);
    try {
      const response = await api.get(`/get-certificate/${hashID}`);

      setCertificate(response.data);
      setSuccess(true);
      setLoading(false);

    }
    catch (err) {
      setError(true)
      setLoading(false);
    }

  }

  useEffect(() => {

    loadCertificate(hash);


  }, [hash])


  if (error) {

    return <Container style={{ padding: '30px 10px', background: '#fff', borderRadius: '15px', display: 'flex', alignItems: 'center', gap: '25px', justifyContent: 'center', flexDirection: 'column' }}>
      <FiXOctagon style={{ fontSize: '84px', color: 'red' }} />
      <h2 style={{ color: 'red' }}>Certificado não identificado</h2>

      <Link to="/"><button className="defaultButtonReverse">Voltar para o site</button></Link>
    </Container>

  }

  if (success) {
    return <Container style={{ padding: '30px 10px', background: '#fff', borderRadius: '15px', display: 'flex', alignItems: 'center', gap: '25px', justifyContent: 'center', flexDirection: 'column' }}>
      <h2 style={{ marginBottom: '20px', color: 'rgb(0, 14, 64)' }}>{translate("Certificado identificado")}</h2>

      <table className='tableRef'>
        <tr><td>Emitido por:</td><td>{authTitle}</td></tr>
        <tr><td>Participante:</td><td>{certificate?.name}</td></tr>
        <tr><td>Documento do participante:</td><td>{certificate?.document_number}</td></tr>
        <tr><td>Data da emissão:</td><td>{date(certificate?.createdAt)}</td></tr>
        <tr><td>Certificado:</td><td>{certificate?.certificate_id_response?.title}</td></tr>
      </table>
      <div>


        <button type="button" className="defaultButtonReverse" onClick={() => handleCertificateDownload({ certificate })} >{translate("Baixar")}</button>
        <aside style={{ display: 'none' }}>
          <QRCode id={`canva-${certificate?.hash}`}
            size={100}
            value={`https://eventos.fundmed.org.br/validar-certificado/${certificate?.hash}`} renderAs="canva" />
        </aside>


        ))

      </div>


    </Container>

  }

  if (loading) {
    return <Container> <h2>Validando seu certificado</h2></Container>
  }

  return <></>;
};
export default Certificados;
