import React, { useState } from 'react';
import { useLanguage } from '../../../../../../../hooks/Language';
import Input from '../../../../../../DashboardPages/core/components/Forms/Input';
import MaskedInput from '../../../../../../DashboardPages/core/components/Forms/MaskedInput';
import SelectSimple from '../../../../../../../components/Forms/SelectSimple';
import calculateFinalPrice from '../../../Functions/calculateFinalPrice';
import generateInstallments from '../../../Functions/generateInstallments';


const GeneralForm: React.FC = () => {

    const { translate } = useLanguage();

    return <>
        <SelectSimple index={10} style={{ zIndex: 100000 }}
            options={[
                { value: 'cpf', label: translate('Pessoa Física') },
                {
                    value: 'cnpj',
                    label: translate('Entidade / Pessoa Jurídica'),
                },
            ]}
            name="document_type"

            label={translate('Emitir como')}
        />

    </>;
};
export default GeneralForm;
