import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Container, Content, Background, AnimatedDiv } from './styles';


import FormContainer from './FormContainer';
import api from '../../../../services/api';
import { FaTicketAlt } from 'react-icons/fa';
import { useModal } from '../../../../hooks/Modal';
import { uuid } from 'uuidv4';
import { useConfiguration } from '../../../../hooks/Configuration';
 
import { Link } from 'react-router-dom';


const MyTickets: React.FC = () => {
  const {addModal} = useModal();
  const [reload,setReload] = useState('');
  const [tickets,setTickets] = useState<Array<Record<string,any>>>([]);
  const {projectHash, configuration} = useConfiguration();
  const load = async () => {

 

    const response = await api.get(`/my-tickets/${projectHash}`);

    if(response?.data?.rows?.length > 0){
       setTickets(response.data.rows);
    }
  
  }
  
  useEffect(() => {
  
    if(projectHash){

    load();
    }
  
  },[projectHash])

  useEffect(() => {
  
    if(reload !== ''){
    load();
    }
  
  },[reload])


  const subscribePartner = (id) => {

    addModal({
      title:' ',
      content: <FormContainer setCallBack={() => { setReload(uuid()) }} hash={id}/>,
      theme: 'whiteModal',
      key: 'partner'
    })

  }


  const status = (payment_status) => {

    if(payment_status === 'waiting'){
      return <p style={{fontSize:'12px', background:'#ddd', color:'#333', maxWidth:'170px', padding:'1px 5px', marginBottom: '15px', borderRadius:'3px'}}>
        Aguardando pagamento</p>
    }
    if(payment_status === 'paid'){
      return <p style={{fontSize:'12px', background:'rgb(0,250,0)', color:'#333',  maxWidth:'200px', padding:'1px 5px', marginBottom: '15px', borderRadius:'3px'}}>
        Pago</p>
    }
    if(payment_status === 'cancelled'){
      return <p style={{fontSize:'12px', background:'rgb(250,0,0)', color:'#fff',  maxWidth:'200px', padding:'1px 5px', marginBottom: '15px', borderRadius:'3px'}}>Cancelado</p>
    }
 

  }

  return (
    <Container>
 
    <h2>Minhas inscrições</h2>

      {tickets.map( ticketInfo => {

        return <aside className='ticket'>
          <FaTicketAlt size={50}/>
          <aside>
         <strong> {ticketInfo?.product_id_response.title}</strong>
         {status(ticketInfo.payment_status_id)}
          <p className='user'><strong>Participante:<br/></strong> {ticketInfo?.payer_id && !ticketInfo?.partner?.name ? 'Não informado' : ticketInfo?.client_id_response?.name}</p>

<div style={{width:'100%', flexDirection:'column', display:'flex', justifyContent:'center', alignItems:'flex-start'}}>
          {ticketInfo?.payer_id && !ticketInfo?.partner?.name && <button type='button' onClick={() => subscribePartner(ticketInfo.sale_hash)}>Cadastrar acompanhante</button>}
         
         {ticketInfo?.fields_text && <><p style={{fontSize:'12px', color: '#333'}}>Referência: {` `}{ticketInfo?.fields_text}</p></>}
         
          {ticketInfo?.boleto_url && ticketInfo?.payment_status_id === 'waiting' && <><br/><a href={ticketInfo?.boleto_url} target='_blank'><button type='button'>Visualizar Boleto</button></a></>}
         
         { ticketInfo?.payment_status_id === 'paid' ?  <Link to={ `/app/${projectHash}/recibo/${ticketInfo.sale_hash}`}><button>Emitir Recibo</button></Link> : <></>}
          
          </div>
        </aside>
        </aside>

      })}
       
       
 
    </Container>
  );
};

export default MyTickets;
