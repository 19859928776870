import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';
import { Container } from './styles';
import theme from '../../../styles/ThemeComponents';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  style?: Record<string, any>;
  name: string;
  unique?: boolean;
  preText?: JSX.Element;
  posText?: JSX.Element;
  background_line?: string;
  setCallBack?: Function;
  options: {
    id: string;
    value: string;
    label: string;
    sub_title?: string;
  }[];
}
const CheckboxInput: React.FC<Props> = ({
  name,
  options,
  preText = '',
  posText = '',
  unique = false,
  style,
  background_line = '',
  setCallBack = (value) => { },
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.filter(ref => ref.checked).map(ref => ref.value);
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach(ref => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);
  return (
    <Container style={style} {...rest}>
      {preText && <label style={{ border: '0', textAlign: 'justify', color: theme.color }}>{preText}</label>}
      {options.map((option, index) => (
        <label style={{ cursor: 'pointer', color: theme.color, width: '100%', background: background_line ? background_line : 'none', padding: "0px 10px" }} htmlFor={`${name}_${option.id}`} key={`${name}_${option.id}`}>
          <input style={{ color: theme.color, border: '1px solid#333' }}
            onClick={(e) => {
              if (unique) {
                inputRefs.current.forEach(ref => {
                  ref.checked = false;
                });

                setCallBack(e.currentTarget.value);

                inputRefs.current[index].checked = true;
              }
            }}
            defaultChecked={defaultValue.find((dv: string) => dv === option.id)}
            ref={ref => {
              inputRefs.current[index] = ref as HTMLInputElement;
            }}
            value={option.value}
            type="checkbox"
            id={`${name}_${option.id}`}
            {...rest}
          />
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginLeft: '10px' }}>
            <strong style={{ color: theme.color, fontSize: '14px' }}>{option.label}</strong>
            {option.sub_title && <p style={{ color: theme.color, fontSize: '12px' }}>{option.sub_title}</p>}
          </div>
        </label>
      ))}
      {posText && <label style={{ border: '0', textAlign: 'justify', color: theme.color }}>{posText}</label>}
    </Container>
  );
};
export default CheckboxInput;
