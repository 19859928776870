import usePaymentRequest from './paymentRequest';

export default function usePaymentWithPix() {

  const { paymentRequest } = usePaymentRequest()

  const payWithPix = async ({ paymentContext }) => {

    await paymentRequest({ paymentContext });

    return false;

  }

  return { payWithPix };

}