import styled, { keyframes } from 'styled-components';

interface IBackground {
  background?: string;
}

const fadeIn = keyframes`
from {
  opacity: 0 ;
  transform: translateX(-30px) ;
}
to{
  opacity: 1 ;
  transform: translateX(0px) ;
}
`

export const Container = styled.div<IBackground>`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: rgba(255,255,255,0.97);
  color: #fff;
  padding: 10px 10px;
  width: 100%;
  padding-top: 30px;
  gap: 25px;
  margin: auto;

  h2{
    font-size: 20px !important;
    color: #333;
  }

  .selectorDiv { 
    display: flex ;
    gap: 5px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
 

  }

  .button{
    padding: 10px;
    background: #eee;
    color: #333;
    font-size: 12px;
    border: 0px;
    border-radius: 5px;
  }

  .buttonColored{
    background: rgb(0,100,150);
    color: #fff;
  }

  .dashboardMenu{
     margin: 10px; 
     display:flex; 
     align-items: center; 
     justify-content:flex-start;
     margin: 0px 10px;
     cursor: pointer;
     svg,p{
       color: #4e5768;
       font-size: 10px;
       }
     &:hover{
       svg,p{
       color: #333;
       }
     }

     svg {
       min-width: 15px;
       font-size: 16px;
       margin-right: 5px;
 
     }

     >p{
      font-size: 10px;
     }

     @media (max-width: 750px) {
      justify-content:center;
      width: 100%;
      svg{
        font-size: 24px;
        min-width: 24px;
        margin-right: 0px;

      }
    }
  }

  .selectDashboardMenu{

    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff !important;
    &:hover{
        color: #fff;
      }
    p{
      color: #fff  !important;

      &:hover{
        color: #fff;
      }
    }

  }
  
  .dashboardMenuActive {

      border-bottom: 2px solid #ddd;
      padding-bottom: 5px;
       svg,p{
       color: #333;
       font-weight: bold;
       }

       @media (max-width: 750px) {
      svg{
        font-size: 24px;
        min-width: 24px;
        margin-right: 0px;

      }
    }
   

  }



  .headerBoard{
      select {
        padding: 10px;
        border-radius: 5px;
        background: #fff;
        margin-right: 15px;
        width: 100%;
    max-width: 330px;
        
      }

      button{
        padding: 10px;
        border-radius: 5px;
        background: #fff;
      }
    }



  .p800 {
    z-index: 1;
    width: 100%;
    max-width: 1200px;
    margin: 30px 0;

    @media (max-width: 1200px) {
      width: 100%;
      min-width: 100%;
    }

    .user-header, .user-header-submenu { 
      display: flex;
      justify: flex-start;
      align-items: center;
      width: 100%;
      >h2{
        font-size: 18px;
      color: #4e5768 !important;
      min-width: 220px;
      }
      >div{
        background: #ddd;
        width: 100%;
        height: 1px;
      }

      animation: ${fadeIn} 0.7s forwards;
    }

    .user-header-submenu{
      margin-top: 30px;
      
      >h2{
        display: flex ;
        align-items: center;
        justify-content: flex-start;
        min-width: 250px;
        font-size: 16px;
        color: #4e5768 !important;

        >svg{
          margin-right: 10px;
        }
      }
    }

    .user-block{
      display: flex;
      justify: flex-start;
      align-items: center;
      width: 100%;
      background: #fff;
      border-radius: 5px;
     
      margin-top: 15px;

      animation: ${fadeIn} 1s forwards;

      @media (max-width: 750px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

      >.img-block{
        padding: 30px ;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
     

        width: 180px;


        @media (max-width: 750px) {
          border-right: 0px solid  #eee;
    }

        >img {
          width: 100px;
          border-radius: 50%;
        }

        >h2{
          font-size: 12px;
          color: #4e5768;
          width: 100%;
          text-align: center;
        }

        >p{
          margin-top: 5px;
          font-size: 10px;
          color: #4e5768;
          width: 100%;
          text-align: center;
        }

      }
      >.content-block{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;

      

        >div{
          padding: 15px;
          display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        
        >p{
          color: #aaa;
          font-size: 10px;
          margin-bottom: 8px;
        }

        >div{
          display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        width: 100%;

        @media (max-width: 750px) {
          flex-direction: column;

        }

        >p{
          color: #aaa;
          font-weight: bold;
          font-size: 10px;
          width: 200px;
          display: flex;
          align-items: center;
          padding-right: 15px;
          justify-content: space-between;

          @media (max-width: 750px) {
            margin: 2px 0px;
            width: 250px;
            padding-right: 0px;
    align-items: center;
    justify-content: space-between;
          }


          >strong{
            margin-left: 5px;
            background: #ddd;
            color:#4e5768;
            padding: 5px;
            min-width: 60px;
            text-align: center;
            min-height: 23px;
            border-radius: 5px;
          }

        }
        }


   
        }

        >div+div{
          border-top: 1px solid  #eee;
        }

        >table {
          width: 100%;
    
          
         border-collapse: collapse;

          th{
            font-size: 12px;
            background:  #4e5768;
            color: #fff;
            border: 1px solid #ddd;
            padding: 5px 10px;
            font-weight: 500;
          }

          td{
            font-size: 10px;
            color: #4e5768;
            border: 1px solid #ddd;
            padding: 5px 10px;
            font-weight: 500;
          }
        }

      }
    }

    > h2 {
      font-size: 24px;
      color: #4e5768 !important;
    }

    > p {
      text-align: justify;
    }
  }

  >h2{
    font-size: 42px;
  }

  @media (max-width: 750px) {
    padding: 30px 20px;
  }

  .sponsors {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin: 30px 0;
    flex-wrap: wrap;

    > div {
      width: 300px;
      max-width: 300px;
      margin: 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      > h2 {
        font-size: 16px;

        color: rgb(50, 50, 50);
      }
      > img {
        width: 100%;
        margin: 20px;
      }

      > img.logoGerenciamento {
        margin-top: 50px;
        width: 70%;
      }
    }

    @media (max-width: 750px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  > .blocoLogo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;

    .logoEvento {
      width: 100%;
      max-width: 500px;
      height: auto;
      transform: translateX(-5%);
    }

    .logoEventoMini {
      width: 100px;
      margin-top: 50px;
    }
  }

  .bloco {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;

    > a {
      margin: 10px;
    }

    @media (max-width: 750px) {
      flex-direction: column;
    }

    > a {
      > button {
        width: 200px;
        padding: 20px;
        text-align: center;
        background: rgb(0, 102, 178);
        color: #fff;
        transition: background 0.5s;
        border: 0;
        border-radius: 8px;

        &:hover {
          background: rgb(0, 102, 178);
        }
      }
    }
  }

  .separator {
    width: 100%;
    max-width: 200px;
    height: 2px;
    background: rgb(0, 102, 178);
    margin: 20px 0;
  }

  .borderedBottom {
    border: 2px solid #fff;
    padding: 10px;
    color: #fff;
    background: none;
    opacity: 0.8;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }
  }

  .logoEvento {
    width: 400px;
    max-width: 80%;
  }

  .palestrantes {
    max-width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 750px) {
      margin: 20px 0;
    }

    > .palestranteDiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > .palestrante {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        height: 100px;
        margin: 10px;
        border: 2px solid rgb(0, 102, 178);
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
        }
      }

      > h2 {
        font-size: 16px;
        font-weight: bold;
        color: rgb(0, 102, 178);
      }

      > p {
        font-size: 14px;

        color: rgb(0, 102, 178);
      }
    }
  }

  .paragraph {
    max-width: 500px;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 102, 178);
    }
  }
`;

export const ContainerWhite = styled(Container)`
  min-height: 90vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #555;
  padding: 100px 10%;
  width: 100%;
  max-width: 100%;
  margin: auto;

  @media (max-width: 1200px) {
    padding: 30px 20px;
    flex-direction: column;
  }

  strong {
    color: rgb(0, 100, 150);
    display: block;
  }

   .p50 {
    z-index: 1;
    width: 50%;
    min-width: 50%;
    margin: 30px 0;

    @media (max-width: 1200px) {
      width: 100%;
      min-width: 100%;
    }

    > h2 {
      font-size: 24px;
      color: #4e5768 !important;
    }

    > p {
      text-align: justify;
    }
  }

  .p600 {
    max-width: 600px;
    min-width: auto;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 102, 178);
    }
  }
`;
