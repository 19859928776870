import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaCheckCircle, FaExclamationCircle, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';



import { Container, ContainerWhite } from './styles';
import { useParams } from 'react-router-dom';
import { useModal } from '../../../../hooks/Modal';

import { useLoading } from '../../../../hooks/LoadingHook';
import Chart from '../../../../components/ChartModule';
import TicketList from '../TicketList';
import SaleList from '../SaleList';
import SubscriptionProfile from '../SubscriptionProfile';
import TicketListProfile from '../TicketListProfile';
import SaleListProduct from '../SaleListProduct';





const SaleReportCoordenador: React.FC<Record<string, any>> = ({ project }) => {

  const { user } = useAuth();
  const { addLoading, removeLoading } = useLoading();
  const { addModal } = useModal();
  const [products, setProducts] = useState<Record<string, any>[]>([]);
  const [sales, setSales] = useState<Record<string, any>[]>([]);
  const [contador, setContador] = useState(5)


  const [page, setPage] = useState('ticket');



  return <Container>
    <h2 style={{ padding: '30px 10px' }}>{project?.title}</h2>
    <div className='selectorDiv'>

      <button className={page === 'ticket' ? 'button buttonColored' : 'button'} onClick={() => setPage('ticket')}>Inscrições</button>
      <button className={page === 'sale-product-data' ? 'button buttonColored' : 'button'} onClick={() => setPage('sale-product-data')}>Dados dos inscritos</button>

      <button className={page === 'ticket-profile' ? 'button buttonColored' : 'button'} onClick={() => setPage('ticket-profile')}>Perfil das Inscrições</button>

      <button className={page === 'sale' ? 'button buttonColored' : 'button'} onClick={() => setPage('sale')}>Pagamentos</button>
      <button className={page === 'profile' ? 'button buttonColored' : 'button'} onClick={() => setPage('profile')}>Perfil do inscrito</button>
    </div>


    {page === 'ticket' ? <TicketList project={project} /> : <></>}
    {page === 'sale-product-data' ? <SaleListProduct project={project} /> : <></>}
    {page === 'ticket-profile' ? <TicketListProfile project={project} /> : <></>}
    {page === 'sale' ? <SaleList project={project} /> : <></>}
    {page === 'profile' ? <SubscriptionProfile project={project} /> : <></>}
  </Container>






}
export default SaleReportCoordenador;
