import styled from 'styled-components';

export const Container = styled.div`
margin: 10px auto;
 width: 100%;
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 justify-content: flex-start;
 gap: 10px;
`;

export const ProductItemStyle = styled.div`
 display: flex;
 align-items: center;
 justify-content: flex-start;
 border-radius: 5px;
 padding: 10px 15px;
 border: 1px solid #ddd;

 transition: 0.5s all;
 width: 100%;
 cursor: pointer;

 .addProductAction{
     width: 60px;
     min-width: 60px;

     div{
         border:1px solid #ddd;
         border-radius: 5px;
         width: 30px;
     min-width: 30px;
     display: flex;
 align-items: center;
 justify-content: center;
 padding: 5px;
 transition: 0.5s all;
     }

     svg{
        transition: 0.5s all;
     }

     .activeProduct{
         background: rgb(0,150,50);
         svg{ 
             color: #fff;
         }
     }

     .activeProduct.changeble{
          .showIcon { display: flex; }
          .notShowIcon { display: none; }

          &:hover{
            background: #333;
         
            .showIcon { display: none;  }
          .notShowIcon { display: flex; }
          }
     }

     .inactiveProduct{
         
        &:hover{
            background: rgb(0,150,50);
         svg{ 
             color: #fff;
         }
        }
      
     }
 }

 .addProductInfo{
     width: 100%;
     display: flex;
 align-items: flex-start;
 justify-content: center ;
 flex-direction: column;
     p{
         font-size: 14px;
         border-radius: 0px;
         border: 0px;
         width: 100%;
         text-align: left;
     }
      
 }

 .addProductCount{
     width:30px;
     min-width:30px;

     div{
         border:1px solid #ddd;
         border-radius: 5px;
         width: 30px;
     min-width: 30px;
     display: flex;
 align-items: center;
 justify-content: center;
 padding: 5px;
     }
     
 }

      
 }

 




    

`;
