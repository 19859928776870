import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaCheckCircle, FaExclamationCircle, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';



import { Container, ContainerWhite } from './styles';
import { useParams } from 'react-router-dom';
import { useModal } from '../../../../hooks/Modal';

import { useLoading } from '../../../../hooks/LoadingHook';
import Chart from '../../../../components/ChartModule';





const SubscriptionProfile: React.FC<Record<string, any>> = ({ project }) => {
  const { user } = useAuth();
  const { addLoading, removeLoading } = useLoading();
  const { addModal } = useModal();
  const [products, setProducts] = useState<Record<string, any>[]>([]);
  const [sales, setSales] = useState<Record<string, any>[]>([]);
  const [contador, setContador] = useState(5)

  const [filter, setFilter] = useState('');
  const [group, setGroup] = useState('state');

  const salesFiltered = filter ? sales?.filter(sale => sale?.payment_status_id === filter) : sales;

  const load = async () => {

    addLoading({ title: 'Carregando', key: 'loading' });


    const salesList = await api.get('/students-by-project-sale', {
      params: {
        limitItems: 10000,
        where: { project_id: project?.url },


      }
    });

    const getPaymentStatus = {
      waiting: 'Aguardando pagamento',
      paid: 'Pago',
      cancelled: 'Cancelado',
      expired: 'Expirado',
    };

    const getPaymentMethod = {
      boleto: 'Boleto',
      cortesy: 'Cortesia',
      courtesy: 'Cortesia',
      credit_card: 'Cartão de crédito',
      pix: 'PIX'
    }

    const saleContent: Array<Record<string, any>> = [];
    salesList.data.rows.map(item => {


      saleContent.push(item);

    })

    setSales(saleContent)

    removeLoading('loading')
  }

  useEffect(() => {
    if (project?.url) {
      load();
    }

  }, [project])



  return <Container>




    <h2>Perfil de inscritos</h2>

    <div className='selectorDiv'>
      <button className={group === 'state' ? 'button buttonColored' : 'button'} onClick={() => setGroup('state')}>Estado</button>
      <button className={group === 'country' ? 'button buttonColored' : 'button'} onClick={() => setGroup('country')}>País</button>
      <button className={group === 'know_about' ? 'button buttonColored' : 'button'} onClick={() => setGroup('know_about')}>Como soube do evento</button>
    </div>
    {salesFiltered?.length > 0 ? <Chart data={salesFiltered} group={group} /> : <p style={{ color: '#333', padding: '15px 25px', background: '#ddd', fontSize: '14px', textAlign: 'center' }}>Nenhum dado encontrado</p>}



  </Container>






}
export default SubscriptionProfile;
