import React from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import uuid from '../../UniqueHash';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../FormComponent';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import { AxiosInstance } from 'axios';
import { FaPlus } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useLoading } from '../../../../../../hooks/LoadingHook';

interface ICreateModule {
  apiRef?: AxiosInstance,
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  buttonContent?: JSX.Element;
  addCallback?: Function;
}

interface IParams {
  projectHash: string;
  eventHash: string;
}

export default function CreateModule({
  apiRef = api,
  title,
  formSchema,
  validationSchema,
  endpoint,
  buttonContent = <span>Novo</span>,
  addCallback = () => { },
}: ICreateModule): JSX.Element {
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { projectHash, eventHash } = useConfiguration();
  const { addLoading, removeLoading } = useLoading();
  const key = uuid();

  const setCallback = async (
    data: Record<string, any>,
    formRef: React.RefObject<FormHandles>,
  ): Promise<void> => {



    addLoading({ title: 'Carregando', key: 'loadingCreate' });
    try {
      const newData = sanitizeDataObject(data, formSchema);

      const schema = createSchemaByForm(validationSchema);
      await schema.validate(newData, { abortEarly: false });

      if (projectHash) {
        newData.project_id = projectHash;
      }
      if (eventHash) {
        newData.event_id = eventHash;
      }

      const response = await apiRef.post(endpoint, newData);

      if (response.status !== 200) {
        removeLoading('loadingCreate');
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }

      removeModal(key);

      addToast({ type: 'success', title: 'Salvo com sucesso' });
      await reloadSearchAll();
      await addCallback();
      removeLoading('loadingCreate');
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        removeLoading('loadingCreate');
        return;
      }

      removeLoading('loadingCreate');


      return addToast({ type: 'error', title: err.response.data.error || err?.response?.data?.message || 'Erro ao salvar' });
    }
  };

  const handleCreate = (): void => {
    const content = (
      <FormComponent schema={formSchema} setCallback={setCallback} />
    );
    if (content) {
      const keyModal = addModal({ title, content, key, theme: "whiteModal" });
    }
  };

  const moduleCreate = (): JSX.Element => (
    <button className="searchButton" type="button" onClick={handleCreate}>
      <FaPlus />
    </button>
  );

  return moduleCreate();
}
