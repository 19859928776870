export default function calculateDiscount(coupon, cart) {



    const currentValue = cart.reduce((prev, item) => {


        prev = prev + (parseInt(item?.price, 10) * item?.quantity);
        return prev;

    }, 0);


    const discountedValue = cart.reduce((prev, item) => {




        const couponItem = coupon?.products?.find(product => product?.product === item?._id?.toString());

        const nextValue = couponItem ? (parseInt(couponItem.price, 10) * item?.quantity) : (parseInt(item?.price, 10) * item?.quantity);

        prev = prev + nextValue;
        return prev;

    }, 0);



    return { currentValue, discountedValue, discount: (currentValue - discountedValue) };



}