import React from 'react';

import uuid from '../../core/components/UniqueHash';
import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';

import { useConfiguration } from '../../../../hooks/Configuration';
import uploadSubscribeCertificate from './modules/UploadSubscribeCertificate';
import { FaFileExcel } from 'react-icons/fa';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

const Dashboard: React.FC = () => {
  const endpoint = '/certificates';
  const title = 'Certificados';
  const { projectHash, eventHash } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    title: { ref: 'title', column: 'title', label: 'Título' },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    title: {
      model: 'input',
      type: 'text',
      name: 'title',
      label: 'Título',
    },
    /*  minimum_limit: {
       model: 'selectSimple',
       name: 'minimum_limit',
       label: 'Mínimo de Salas visitadas',
       
       options: [
         { label: '1', value: 1},
         { label: '2', value: 2},
         { label: '3', value: 3},
         { label: '4', value: 4},
         { label: '5', value: 5},
         { label: '6', value: 6},
         { label: '7', value: 7},
         { label: '8', value: 8},
         { label: '9', value: 9},
         { label: '10', value: 10},
         { label: '11', value: 11},
         { label: '12', value: 12},
         { label: '13', value: 13},
         { label: '14', value: 14},
         { label: '15', value: 15},
         { label: '16', value: 16},
         { label: '17', value: 17},
         { label: '18', value: 18},
         { label: '19', value: 19},
         { label: '20', value: 20},
         { label: '21', value: 21},
         { label: '22', value: 22},
         { label: '23', value: 23},
         { label: '24', value: 24},
         { label: '25', value: 25},
         { label: '26', value: 26},
         { label: '27', value: 27},
         { label: '28', value: 28},
         { label: '29', value: 29},
         { label: '30', value: 30},
 
 
       ]
     
     },
 */

    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Ativo?',

      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    content: {
      model: 'jsonInputList',
      type: 'text',
      name: 'content',
      label: 'Campos',
      show: 'text',
      list: [
        { label: 'Texto', name: 'text', type: 'richText' },
        { label: 'Cor', name: 'color', type: 'inputColor', default: '#000' },
        {
          label: 'Tamanho', name: 'width', type: 'select', default: '100%', options: [

            { label: '1%', text: '1%', value: '1%' },
            { label: '2%', text: '2%', value: '2%' },
            { label: '3%', text: '3%', value: '3%' },
            { label: '4%', text: '4%', value: '4%' },
            { label: '5%', text: '5%', value: '5%' },
            { label: '6%', text: '6%', value: '6%' },
            { label: '7%', text: '7%', value: '7%' },
            { label: '8%', text: '8%', value: '8%' },
            { label: '9%', text: '9%', value: '9%' },
            { label: '10%', text: '10%', value: '10%' },
            { label: '11%', text: '11%', value: '11%' },
            { label: '12%', text: '12%', value: '12%' },
            { label: '13%', text: '13%', value: '13%' },
            { label: '14%', text: '14%', value: '14%' },
            { label: '15%', text: '15%', value: '15%' },
            { label: '16%', text: '16%', value: '16%' },
            { label: '17%', text: '17%', value: '17%' },
            { label: '18%', text: '18%', value: '18%' },
            { label: '19%', text: '19%', value: '19%' },
            { label: '20%', text: '20%', value: '20%' },
            { label: '21%', text: '21%', value: '21%' },
            { label: '22%', text: '22%', value: '22%' },
            { label: '23%', text: '23%', value: '23%' },
            { label: '24%', text: '24%', value: '24%' },
            { label: '25%', text: '25%', value: '25%' },
            { label: '26%', text: '26%', value: '26%' },
            { label: '27%', text: '27%', value: '27%' },
            { label: '28%', text: '28%', value: '28%' },
            { label: '29%', text: '29%', value: '29%' },
            { label: '30%', text: '30%', value: '30%' },
            { label: '31%', text: '31%', value: '31%' },
            { label: '32%', text: '32%', value: '32%' },
            { label: '33%', text: '33%', value: '33%' },
            { label: '34%', text: '34%', value: '34%' },
            { label: '35%', text: '35%', value: '35%' },
            { label: '36%', text: '36%', value: '36%' },
            { label: '37%', text: '37%', value: '37%' },
            { label: '38%', text: '38%', value: '38%' },
            { label: '39%', text: '39%', value: '39%' },
            { label: '40%', text: '40%', value: '40%' },
            { label: '41%', text: '41%', value: '41%' },
            { label: '42%', text: '42%', value: '42%' },
            { label: '43%', text: '43%', value: '43%' },
            { label: '44%', text: '44%', value: '44%' },
            { label: '45%', text: '45%', value: '45%' },
            { label: '46%', text: '46%', value: '46%' },
            { label: '47%', text: '47%', value: '47%' },
            { label: '48%', text: '48%', value: '48%' },
            { label: '49%', text: '49%', value: '49%' },
            { label: '50%', text: '50%', value: '50%' },
            { label: '51%', text: '51%', value: '51%' },
            { label: '52%', text: '52%', value: '52%' },
            { label: '53%', text: '53%', value: '53%' },
            { label: '54%', text: '54%', value: '54%' },
            { label: '55%', text: '55%', value: '55%' },
            { label: '56%', text: '56%', value: '56%' },
            { label: '57%', text: '57%', value: '57%' },
            { label: '58%', text: '58%', value: '58%' },
            { label: '59%', text: '59%', value: '59%' },
            { label: '60%', text: '60%', value: '60%' },
            { label: '61%', text: '61%', value: '61%' },
            { label: '62%', text: '62%', value: '62%' },
            { label: '63%', text: '63%', value: '63%' },
            { label: '64%', text: '64%', value: '64%' },
            { label: '65%', text: '65%', value: '65%' },
            { label: '66%', text: '66%', value: '66%' },
            { label: '67%', text: '67%', value: '67%' },
            { label: '68%', text: '68%', value: '68%' },
            { label: '69%', text: '69%', value: '69%' },
            { label: '70%', text: '70%', value: '70%' },
            { label: '71%', text: '71%', value: '71%' },
            { label: '72%', text: '72%', value: '72%' },
            { label: '73%', text: '73%', value: '73%' },
            { label: '74%', text: '74%', value: '74%' },
            { label: '75%', text: '75%', value: '75%' },
            { label: '76%', text: '76%', value: '76%' },
            { label: '77%', text: '77%', value: '77%' },
            { label: '78%', text: '78%', value: '78%' },
            { label: '79%', text: '79%', value: '79%' },
            { label: '80%', text: '80%', value: '80%' },
            { label: '81%', text: '81%', value: '81%' },
            { label: '82%', text: '82%', value: '82%' },
            { label: '83%', text: '83%', value: '83%' },
            { label: '84%', text: '84%', value: '84%' },
            { label: '85%', text: '85%', value: '85%' },
            { label: '86%', text: '86%', value: '86%' },
            { label: '87%', text: '87%', value: '87%' },
            { label: '88%', text: '88%', value: '88%' },
            { label: '89%', text: '89%', value: '89%' },
            { label: '90%', text: '90%', value: '90%' },
            { label: '91%', text: '91%', value: '91%' },
            { label: '92%', text: '92%', value: '92%' },
            { label: '93%', text: '93%', value: '93%' },
            { label: '94%', text: '94%', value: '94%' },
            { label: '95%', text: '95%', value: '95%' },
            { label: '96%', text: '96%', value: '96%' },
            { label: '97%', text: '97%', value: '97%' },
            { label: '98%', text: '98%', value: '98%' },
            { label: '99%', text: '99%', value: '99%' },
            { label: '100%', text: '100%', value: '100%' },
          ]
        },

        {
          label: 'Tamanho da fonte', name: 'size', type: 'select', default: '24px', options: [


            { label: '1px', text: '1px', value: '1px' },
            { label: '2px', text: '2px', value: '2px' },
            { label: '3px', text: '3px', value: '3px' },
            { label: '4px', text: '4px', value: '4px' },
            { label: '5px', text: '5px', value: '5px' },
            { label: '6px', text: '6px', value: '6px' },
            { label: '7px', text: '7px', value: '7px' },
            { label: '8px', text: '8px', value: '8px' },
            { label: '9px', text: '9px', value: '9px' },
            { label: '10px', text: '10px', value: '10px' },
            { label: '11px', text: '11px', value: '11px' },
            { label: '12px', text: '12px', value: '12px' },
            { label: '13px', text: '13px', value: '13px' },
            { label: '14px', text: '14px', value: '14px' },
            { label: '15px', text: '15px', value: '15px' },
            { label: '16px', text: '16px', value: '16px' },
            { label: '17px', text: '17px', value: '17px' },
            { label: '18px', text: '18px', value: '18px' },
            { label: '19px', text: '19px', value: '19px' },
            { label: '20px', text: '20px', value: '20px' },
            { label: '21px', text: '21px', value: '21px' },
            { label: '22px', text: '22px', value: '22px' },
            { label: '23px', text: '23px', value: '23px' },
            { label: '24px', text: '24px', value: '24px' },
            { label: '25px', text: '25px', value: '25px' },
            { label: '26px', text: '26px', value: '26px' },
            { label: '27px', text: '27px', value: '27px' },
            { label: '28px', text: '28px', value: '28px' },
            { label: '29px', text: '29px', value: '29px' },
            { label: '30px', text: '30px', value: '30px' },
            { label: '31px', text: '31px', value: '31px' },
            { label: '32px', text: '32px', value: '32px' },
            { label: '33px', text: '33px', value: '33px' },
            { label: '34px', text: '34px', value: '34px' },
            { label: '35px', text: '35px', value: '35px' },
            { label: '36px', text: '36px', value: '36px' },
            { label: '37px', text: '37px', value: '37px' },
            { label: '38px', text: '38px', value: '38px' },
            { label: '39px', text: '39px', value: '39px' },
            { label: '40px', text: '40px', value: '40px' },
            { label: '41px', text: '41px', value: '41px' },
            { label: '42px', text: '42px', value: '42px' },
            { label: '43px', text: '43px', value: '43px' },
            { label: '44px', text: '44px', value: '44px' },
            { label: '45px', text: '45px', value: '45px' },
            { label: '46px', text: '46px', value: '46px' },
            { label: '47px', text: '47px', value: '47px' },
            { label: '48px', text: '48px', value: '48px' },
            { label: '49px', text: '49px', value: '49px' },
            { label: '50px', text: '50px', value: '50px' },
            { label: '51px', text: '51px', value: '51px' },
            { label: '52px', text: '52px', value: '52px' },
            { label: '53px', text: '53px', value: '53px' },
            { label: '54px', text: '54px', value: '54px' },
            { label: '55px', text: '55px', value: '55px' },
            { label: '56px', text: '56px', value: '56px' },
            { label: '57px', text: '57px', value: '57px' },
            { label: '58px', text: '58px', value: '58px' },
            { label: '59px', text: '59px', value: '59px' },
            { label: '60px', text: '60px', value: '60px' },



          ]
        },

        {
          label: 'Margem do Topo', name: 'top', type: 'select', default: '25%', options: [
            { label: '0%', text: '0%', value: '0%' },
            { label: '1%', text: '1%', value: '1%' },
            { label: '2%', text: '2%', value: '2%' },
            { label: '3%', text: '3%', value: '3%' },
            { label: '4%', text: '4%', value: '4%' },
            { label: '5%', text: '5%', value: '5%' },
            { label: '6%', text: '6%', value: '6%' },
            { label: '7%', text: '7%', value: '7%' },
            { label: '8%', text: '8%', value: '8%' },
            { label: '9%', text: '9%', value: '9%' },
            { label: '10%', text: '10%', value: '10%' },
            { label: '11%', text: '11%', value: '11%' },
            { label: '12%', text: '12%', value: '12%' },
            { label: '13%', text: '13%', value: '13%' },
            { label: '14%', text: '14%', value: '14%' },
            { label: '15%', text: '15%', value: '15%' },
            { label: '16%', text: '16%', value: '16%' },
            { label: '17%', text: '17%', value: '17%' },
            { label: '18%', text: '18%', value: '18%' },
            { label: '19%', text: '19%', value: '19%' },
            { label: '20%', text: '20%', value: '20%' },
            { label: '21%', text: '21%', value: '21%' },
            { label: '22%', text: '22%', value: '22%' },
            { label: '23%', text: '23%', value: '23%' },
            { label: '24%', text: '24%', value: '24%' },
            { label: '25%', text: '25%', value: '25%' },
            { label: '26%', text: '26%', value: '26%' },
            { label: '27%', text: '27%', value: '27%' },
            { label: '28%', text: '28%', value: '28%' },
            { label: '29%', text: '29%', value: '29%' },
            { label: '30%', text: '30%', value: '30%' },
            { label: '31%', text: '31%', value: '31%' },
            { label: '32%', text: '32%', value: '32%' },
            { label: '33%', text: '33%', value: '33%' },
            { label: '34%', text: '34%', value: '34%' },
            { label: '35%', text: '35%', value: '35%' },
            { label: '36%', text: '36%', value: '36%' },
            { label: '37%', text: '37%', value: '37%' },
            { label: '38%', text: '38%', value: '38%' },
            { label: '39%', text: '39%', value: '39%' },
            { label: '40%', text: '40%', value: '40%' },
            { label: '41%', text: '41%', value: '41%' },
            { label: '42%', text: '42%', value: '42%' },
            { label: '43%', text: '43%', value: '43%' },
            { label: '44%', text: '44%', value: '44%' },
            { label: '45%', text: '45%', value: '45%' },
            { label: '46%', text: '46%', value: '46%' },
            { label: '47%', text: '47%', value: '47%' },
            { label: '48%', text: '48%', value: '48%' },
            { label: '49%', text: '49%', value: '49%' },
            { label: '50%', text: '50%', value: '50%' },
            { label: '51%', text: '51%', value: '51%' },
            { label: '52%', text: '52%', value: '52%' },
            { label: '53%', text: '53%', value: '53%' },
            { label: '54%', text: '54%', value: '54%' },
            { label: '55%', text: '55%', value: '55%' },
            { label: '56%', text: '56%', value: '56%' },
            { label: '57%', text: '57%', value: '57%' },
            { label: '58%', text: '58%', value: '58%' },
            { label: '59%', text: '59%', value: '59%' },
            { label: '60%', text: '60%', value: '60%' },
            { label: '61%', text: '61%', value: '61%' },
            { label: '62%', text: '62%', value: '62%' },
            { label: '63%', text: '63%', value: '63%' },
            { label: '64%', text: '64%', value: '64%' },
            { label: '65%', text: '65%', value: '65%' },
            { label: '66%', text: '66%', value: '66%' },
            { label: '67%', text: '67%', value: '67%' },
            { label: '68%', text: '68%', value: '68%' },
            { label: '69%', text: '69%', value: '69%' },
            { label: '70%', text: '70%', value: '70%' },
            { label: '71%', text: '71%', value: '71%' },
            { label: '72%', text: '72%', value: '72%' },
            { label: '73%', text: '73%', value: '73%' },
            { label: '74%', text: '74%', value: '74%' },
            { label: '75%', text: '75%', value: '75%' },
            { label: '76%', text: '76%', value: '76%' },
            { label: '77%', text: '77%', value: '77%' },
            { label: '78%', text: '78%', value: '78%' },
            { label: '79%', text: '79%', value: '79%' },
            { label: '80%', text: '80%', value: '80%' },
            { label: '81%', text: '81%', value: '81%' },
            { label: '82%', text: '82%', value: '82%' },
            { label: '83%', text: '83%', value: '83%' },
            { label: '84%', text: '84%', value: '84%' },
            { label: '85%', text: '85%', value: '85%' },
            { label: '86%', text: '86%', value: '86%' },
            { label: '87%', text: '87%', value: '87%' },
            { label: '88%', text: '88%', value: '88%' },
            { label: '89%', text: '89%', value: '89%' },
            { label: '90%', text: '90%', value: '90%' },
            { label: '91%', text: '91%', value: '91%' },
            { label: '92%', text: '92%', value: '92%' },
            { label: '93%', text: '93%', value: '93%' },
            { label: '94%', text: '94%', value: '94%' },
            { label: '95%', text: '95%', value: '95%' },
            { label: '96%', text: '96%', value: '96%' },
            { label: '97%', text: '97%', value: '97%' },
            { label: '98%', text: '98%', value: '98%' },
            { label: '99%', text: '99%', value: '99%' },
            { label: '100%', text: '100%', value: '100%' },
          ]
        },
        {
          label: 'Margem da Esquerda', name: 'left', type: 'select', default: '0%', options: [
            { label: '0%', text: '0%', value: '0%' },
            { label: '1%', text: '1%', value: '1%' },
            { label: '2%', text: '2%', value: '2%' },
            { label: '3%', text: '3%', value: '3%' },
            { label: '4%', text: '4%', value: '4%' },
            { label: '5%', text: '5%', value: '5%' },
            { label: '6%', text: '6%', value: '6%' },
            { label: '7%', text: '7%', value: '7%' },
            { label: '8%', text: '8%', value: '8%' },
            { label: '9%', text: '9%', value: '9%' },
            { label: '10%', text: '10%', value: '10%' },
            { label: '11%', text: '11%', value: '11%' },
            { label: '12%', text: '12%', value: '12%' },
            { label: '13%', text: '13%', value: '13%' },
            { label: '14%', text: '14%', value: '14%' },
            { label: '15%', text: '15%', value: '15%' },
            { label: '16%', text: '16%', value: '16%' },
            { label: '17%', text: '17%', value: '17%' },
            { label: '18%', text: '18%', value: '18%' },
            { label: '19%', text: '19%', value: '19%' },
            { label: '20%', text: '20%', value: '20%' },
            { label: '21%', text: '21%', value: '21%' },
            { label: '22%', text: '22%', value: '22%' },
            { label: '23%', text: '23%', value: '23%' },
            { label: '24%', text: '24%', value: '24%' },
            { label: '25%', text: '25%', value: '25%' },
            { label: '26%', text: '26%', value: '26%' },
            { label: '27%', text: '27%', value: '27%' },
            { label: '28%', text: '28%', value: '28%' },
            { label: '29%', text: '29%', value: '29%' },
            { label: '30%', text: '30%', value: '30%' },
            { label: '31%', text: '31%', value: '31%' },
            { label: '32%', text: '32%', value: '32%' },
            { label: '33%', text: '33%', value: '33%' },
            { label: '34%', text: '34%', value: '34%' },
            { label: '35%', text: '35%', value: '35%' },
            { label: '36%', text: '36%', value: '36%' },
            { label: '37%', text: '37%', value: '37%' },
            { label: '38%', text: '38%', value: '38%' },
            { label: '39%', text: '39%', value: '39%' },
            { label: '40%', text: '40%', value: '40%' },
            { label: '41%', text: '41%', value: '41%' },
            { label: '42%', text: '42%', value: '42%' },
            { label: '43%', text: '43%', value: '43%' },
            { label: '44%', text: '44%', value: '44%' },
            { label: '45%', text: '45%', value: '45%' },
            { label: '46%', text: '46%', value: '46%' },
            { label: '47%', text: '47%', value: '47%' },
            { label: '48%', text: '48%', value: '48%' },
            { label: '49%', text: '49%', value: '49%' },
            { label: '50%', text: '50%', value: '50%' },
            { label: '51%', text: '51%', value: '51%' },
            { label: '52%', text: '52%', value: '52%' },
            { label: '53%', text: '53%', value: '53%' },
            { label: '54%', text: '54%', value: '54%' },
            { label: '55%', text: '55%', value: '55%' },
            { label: '56%', text: '56%', value: '56%' },
            { label: '57%', text: '57%', value: '57%' },
            { label: '58%', text: '58%', value: '58%' },
            { label: '59%', text: '59%', value: '59%' },
            { label: '60%', text: '60%', value: '60%' },
            { label: '61%', text: '61%', value: '61%' },
            { label: '62%', text: '62%', value: '62%' },
            { label: '63%', text: '63%', value: '63%' },
            { label: '64%', text: '64%', value: '64%' },
            { label: '65%', text: '65%', value: '65%' },
            { label: '66%', text: '66%', value: '66%' },
            { label: '67%', text: '67%', value: '67%' },
            { label: '68%', text: '68%', value: '68%' },
            { label: '69%', text: '69%', value: '69%' },
            { label: '70%', text: '70%', value: '70%' },
            { label: '71%', text: '71%', value: '71%' },
            { label: '72%', text: '72%', value: '72%' },
            { label: '73%', text: '73%', value: '73%' },
            { label: '74%', text: '74%', value: '74%' },
            { label: '75%', text: '75%', value: '75%' },
            { label: '76%', text: '76%', value: '76%' },
            { label: '77%', text: '77%', value: '77%' },
            { label: '78%', text: '78%', value: '78%' },
            { label: '79%', text: '79%', value: '79%' },
            { label: '80%', text: '80%', value: '80%' },
            { label: '81%', text: '81%', value: '81%' },
            { label: '82%', text: '82%', value: '82%' },
            { label: '83%', text: '83%', value: '83%' },
            { label: '84%', text: '84%', value: '84%' },
            { label: '85%', text: '85%', value: '85%' },
            { label: '86%', text: '86%', value: '86%' },
            { label: '87%', text: '87%', value: '87%' },
            { label: '88%', text: '88%', value: '88%' },
            { label: '89%', text: '89%', value: '89%' },
            { label: '90%', text: '90%', value: '90%' },
            { label: '91%', text: '91%', value: '91%' },
            { label: '92%', text: '92%', value: '92%' },
            { label: '93%', text: '93%', value: '93%' },
            { label: '94%', text: '94%', value: '94%' },
            { label: '95%', text: '95%', value: '95%' },
            { label: '96%', text: '96%', value: '96%' },
            { label: '97%', text: '97%', value: '97%' },
            { label: '98%', text: '98%', value: '98%' },
            { label: '99%', text: '99%', value: '99%' },
            { label: '100%', text: '100%', value: '100%' },
          ]
        },
        {
          label: 'Alinhamento', name: 'align', type: 'select', default: 'center', options: [
            { label: 'Centralizado', text: 'Centralizado', value: 'center' },
            { label: 'Esquerda', text: 'Esquerda', value: 'left' },
            { label: 'Direita', text: 'Direita', value: 'right' },

          ]
        },
        {
          label: 'Negrito', name: 'bold', type: 'select', default: 'bold', options: [
            { label: 'Sim', text: 'Sim', value: 'bold' },
            { label: 'Não', text: 'Não', value: '400' },

          ]
        },
        {
          label: 'Caixa alta', name: 'element', type: 'select', default: 'h2', options: [
            { label: 'Sim', text: 'Sim', value: 'h2' },
            { label: 'Não', text: 'Não', value: 'p' },

          ]
        },

      ],
      defaultValue: [{
        "text": "<p>Certificamos que</p>",
        "color": "#000000",
        "width": "100%",
        "size": "20px",
        "top": "37%",
        "left": "0%",
        "align": "center",
        "bold": "400",
        "element": "p"
      }, {
        "text": "<p>{NOME}</p>",
        "color": "#004766",
        "width": "100%",
        "size": "24px",
        "top": "44%",
        "left": "0%",
        "align": "center",
        "bold": "bold",
        "element": "h2"
      }, {
        "text": "<p>participou do evento {EVENTO} na modalidade participante.</p><p>&nbsp;</p><p>Porto Alegre, XX de XXXXXXXX de 2023</p>",
        "color": "#000000",
        "width": "80%",
        "size": "18px",
        "top": "52%",
        "left": "10%",
        "align": "center",
        "bold": "400",
        "element": "p"
      }],
    },
    height: {
      model: 'input',
      type: 'text',
      name: 'height',
      label: 'Altura do certificado',
    },

    exam_id: {
      alias: 'exam_id',
      model: 'selectApi',
      name: 'exam_id',
      label: 'Prova',

      endpoint,
    },

    satisfaction_survey_id: {
      alias: 'satisfaction_survey_id',
      model: 'selectApi',
      name: 'satisfaction_survey_id',
      label: 'Pesquisa de Satisfação',

      endpoint,
    },


  };






  const formSchemaUpdate = {
    ...formSchema,
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Imagem',
    },


  };


  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (): JSX.Element => (
        <a href="/apoio/modelo-certificados.xlsx" title="Modelo de Upload" download> <button className="searchButton" style={{ background: 'orange' }} type="button"  >
          <FaFileExcel color='#fff' />
        </button>  </a>)
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        uploadSubscribeCertificate({
          lineData,

        }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
