import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import * as Yup from 'yup';
import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle } from '../../../../../../config';
import { FaMoneyBill, FaMoneyBillAlt, FaPlusCircle } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';
import { string } from 'yup';
import { useSearch } from '../../../../../../hooks/Search';
import { useModal } from '../../../../../../hooks/Modal';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { uuid } from 'uuidv4';
import FormComponent, { sanitizeDataObject } from '../../../../core/components/FormComponent';
import { createSchemaByForm } from '../../../../../../utils/SchemaCreator';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../../../../../utils/getValidationErrors';

interface ICreateModule {
  lineData?: Record<string, any>;
}

export const CreateNewProjectModule: React.FC<ICreateModule> = () => {


  const { addToast } = useToast();
  const { reloadSearchAll } = useSearch();
  const { addModal, removeModal } = useModal();

  const { projectHash, eventHash } = useConfiguration()
  const key = uuid();
  const formSchema = {
    reference_number: { model: 'input', type: 'text', name: 'reference_number', label: 'Código SAGI / SIAF' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Nome do Projeto' },
    url: { model: 'input', type: 'text', name: 'url', pattern: 'url', label: 'URL do Projeto' },
    start_date: { model: 'datePicker', type: 'text', name: 'start_date', label: 'Data de Referência' },
    date_text: { model: 'input', type: 'text', name: 'date_text', label: 'Data em Texto (Ex: 02 a 4 de abril de 2024)' },
    event_type: {
      defaultValue: 'Presencial',
      model: 'selectSimple', type: 'text', name: 'event_type', label: 'Tipo de evento', options: [
        { label: 'Online', value: 'Online' },
        { label: 'Híbrido', value: 'Híbrido' },
        { label: 'Presencial', value: 'Presencial' },
      ]
    },

    cnpj: { model: 'maskedInput', mask: "99.999.999/9999-99", type: 'text', name: 'cnpj', label: 'CNPJ', defaultValue: '94.391.901/0001-03' },
    bank: {
      model: 'selectSimple', type: 'text', name: 'bank', label: 'Banco', options: [
        { label: 'Banco do Brasil', text: 'Banco do Brasil', value: '001' },
        { label: 'Sicredi', text: 'Sicredi', value: '748' },
      ]
    },



    bank_agency: { model: 'input', type: 'text', name: 'bank_agency', pattern: 'agency', label: 'Agência' },
    bank_agency_dv: { model: 'input', type: 'text', name: 'bank_agency_dv', pattern: 'dv', label: 'Ag. DV' },
    bank_account: { model: 'input', type: 'text', name: 'bank_account', pattern: 'number', label: 'Conta corrente' },
    bank_account_dv: { model: 'input', type: 'text', name: 'bank_account_dv', pattern: 'dv', label: 'Conta Corrent DV' },


    allow_boleto: {
      model: 'selectSimple',
      name: 'allow_boleto',
      label: 'Liberar Boleto',
      defaultValue: 'yes',
      type: 'text',


      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },

    event_end: { model: 'datePickerHour', type: 'text', name: 'event_end', label: 'Data de remoção da capa' },
    sale_end: { model: 'datePickerHour', type: 'text', name: 'sale_end', label: 'Encerramento das vendas' },
    boleto_end: { model: 'datePickerHour', type: 'text', name: 'boleto_end', label: 'Fim da venda em boleto' },
    boleto_end_days: {
      model: 'selectSimple', type: 'text', name: 'boleto_end_days', label: 'Bloqueio de boletos em dias úteis',
      options: [
        { label: 'Não bloquear', value: '' },

        { label: '01', value: '1' },
        { label: '02', value: '2' },
        { label: '03', value: '3' },
        { label: '04', value: '4' },
        { label: '05', value: '5' },
        { label: '06', value: '6' },
        { label: '07', value: '7' },
        { label: '08', value: '8' },
        { label: '09', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
      ]
    },

    allow_ticket_transfer: {
      model: 'selectSimple', type: 'text', name: 'allow_ticket_transfer', label: 'Permitir transferência de ingressos',
      options: [
        { label: 'Sim', value: 'Sim' },
        { label: 'Não', value: 'Não' },
      ]
    },
    allow_credit_card: {
      model: 'selectSimple',
      name: 'allow_credit_card',
      label: 'Liberar Cartão de Credito',
      defaultValue: 'yes',
      type: 'text',

      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    allow_pix: {
      model: 'selectSimple',
      name: 'allow_pix',
      label: 'Liberar Pix',
      defaultValue: 'yes',

      type: 'text',
      options: [


        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },


      ]

    },
    boleto: {
      model: 'selectSimple',
      name: 'boleto',
      label: 'Vencimento do boleto (dias)',
      defaultValue: '7',

      type: 'text',
      options: [

        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
      ]

    },
    open_status: {
      model: 'selectSimple',
      name: 'open_status',
      label: 'Status do projeto?',
      defaultValue: 'open',
      type: 'text',
      options: [
        { label: 'Aguardando abertura', value: 'waiting' },
        { label: 'Aberto', value: 'open' },
        { label: 'Encerrado', value: 'closed' },
      ]
    },

    sales_limit_type: {
      model: 'selectSimple',
      name: 'sales_limit_type',
      label: 'Limitar Vendas?',
      defaultValue: 'no',
      type: 'text',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },

      ]
    },
    sales_limit: { model: 'input', type: 'text', pattern: 'number', name: 'sales_limit', label: 'Número máximo de vendas', default: '100000' },

  }

  const formValidation: Record<string, any> = {
    image: {
      name: 'title',
      type: 'string',
      label: 'Capa do evento',

    },
    title: {
      name: 'title',
      type: 'string',
      label: 'Nome do Evento',
      rules: [{ type: 'required' }],
    },
    url: {
      name: 'url',
      type: 'string',
      label: 'URL do Evento',
      rules: [{ type: 'required' }],
    },
    cnpj: {
      name: 'cnpj',
      type: 'string',
      label: 'CNPJ',
      rules: [{ type: 'required' }],
    },

    bank: {
      name: 'bank',
      type: 'string',
      label: 'Banco',
      rules: [{ type: 'required' }],
    },
    bank_agency: {
      name: 'bank_agency',
      type: 'string',
      label: 'Agência',
      rules: [{ type: 'required' }],
    },
    bank_agency_dv: {
      name: 'bank_agency_dv',
      type: 'string',
      label: 'Agência DV',
      rules: [{ type: 'required' }],
    },
    bank_account: {
      name: 'bank_account',
      type: 'string',
      label: 'C/C',
      rules: [{ type: 'required' }],
    },
    bank_account_dv: {
      name: 'bank_account_dv',
      type: 'string',
      label: 'C/C DV',
      rules: [{ type: 'required' }],
    },

    open_status: {
      name: 'open_status',
      type: 'string',
      label: 'Status do projeto?',
      rules: [{ type: 'required' }],
    },
  };

  const setCallback = async (
    data: Record<string, any>,
    formRef: React.RefObject<FormHandles>,
  ): Promise<void> => {
    try {
      const newData = sanitizeDataObject(data, formSchema);

      const schema = createSchemaByForm(formValidation);
      await schema.validate(newData, { abortEarly: false });

      if (projectHash) {
        newData.project_id = projectHash;
      }
      if (eventHash) {
        newData.event_id = eventHash;
      }

      newData.payment_hash = 're_clx4o8ucu4i59019tm6m48shr';
      newData.new_finance = 'yes';

      const response = await api.post('new-project-default', newData);

      if (response.status !== 200) {
        return addToast({ type: 'error', title: 'Erro ao salvar' });
      }

      removeModal(key);

      addToast({ type: 'success', title: 'Salvo com sucesso' });
      await reloadSearchAll();

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
        return;
      }

      return addToast({ type: 'error', title: err.response.data.error });
    }
  };




  const handleCreate = (): void => {
    const content = (
      <FormComponent schema={formSchema} setCallback={setCallback} />
    );
    if (content) {
      const keyModal = addModal({ title: 'Novo Projeto', content, key, theme: "whiteModal" });
    }
  };




  const moduleUpdate = (): JSX.Element => (
    <><button title="Criar Projeto" className="searchButton" type="button" onClick={() => handleCreate()}>
      <FaPlusCircle />
    </button>
    </>
  );

  return moduleUpdate();
}
