import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import { FaList, FaTable, FaTabletAlt } from 'react-icons/fa';
import downloadit from '../../../../../../utils/download';

interface ICreateModule {
  projectHash: string;
}

export default function UpdateModule({

  projectHash,

}: ICreateModule): JSX.Element {
  const { handleApiErrors } = useAuth();
  const { addToast } = useToast();


  const handleCount = async (): Promise<void> => {
    addToast({ title: 'Gerando relatório', type: 'info' })
    await downloadit({ url: `/report-cortesies-count/${projectHash}` });

  };

  const handleList = async (): Promise<void> => {
    addToast({ title: 'Gerando relatório', type: 'info' })
    await downloadit({ url: `/report-cortesies/${projectHash}` });

  };

  const moduleUpdate = (): JSX.Element => (
    <>  <button className="searchButton" type="button" onClick={handleCount}>
      <FaList />
    </button><button className="searchButton" type="button" onClick={handleList}>
        <FaTable />
      </button></>
  );

  return moduleUpdate();
}
