import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaIdBadge, FaReceipt, FaTicketAlt, FaTrophy } from 'react-icons/fa';

import { IconType } from 'react-icons/lib';
import { useParams } from 'react-router-dom';
import SelectSimple from '../../../../components/Forms/SelectSimple';
import { useAuth } from '../../../../hooks/Auth';
import api from '../../../../services/api';
import SaleReportCoordenador from '../../../AreaDoCoordenador/Pages/Home';
import InOut from '../../../DashboardPages/core/components/Forms/InOut';
import SalesCoordenadores from '../../../DashboardPages/pages/SalesCoordenadores';

import { Container, ContainerWhite } from './styles';

interface IOptions {
  text?: string;
  label?: string;
  value: string | number;
}


const AreaCoordenador: React.FC = () => {

  const { user } = useAuth();

  const [userInfo, setUserInfo] = useState<Record<string, any>>({});

  const [projectsList, setProjectsList] = useState<Array<Record<string, any>>>([]);
  const [currentValue, setCurrentValue] = useState();

  const projectOptions = projectsList?.reduce((prev: IOptions[], item) => {

    prev.push({ label: item?.title, value: item?.url, text: item?.title })
    return prev;
  }, [] as IOptions[])

  const project = projectsList?.find(i => i?.url === currentValue) || {}

  useEffect(() => {
    setCurrentValue(projectsList?.[0]?.url)
  }, [projectsList])

  const load = async () => {

    const projects = await api.get('/coordenator-projects');

    setProjectsList(projects?.data?.rows);

  }

  useEffect(() => {

    if (!user) {
      window.location.href = `/app/login-coordenador`;
      return;
    }

    if (user && user.profile !== 30) {
      window.location.href = `/app/login-coordenador`;
      return;
    }

    load();



  }, [user])

  return (
    <>
      <Container >
        {currentValue ? <>
          <Form onSubmit={() => { }} initialData={{ project: currentValue }}>
            <SelectSimple style={{ minWidth: '300px' }} setCallback={(value) => { setCurrentValue(value) }} options={projectOptions} name="project" label='Projeto' />
          </Form>
          <SaleReportCoordenador project={project} /></>
          : <><h2 style={{ width: '100%', textAlign: 'center', color: '#333' }}>Validando acesso...</h2></>}
      </Container>
    </>
  );
};
export default AreaCoordenador;
