import React, { useEffect, useState } from 'react';
import { FaCheck, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FiLock } from 'react-icons/fi';
import Input from '../components/Forms/Input';
import MaskedInput from '../components/Forms/MaskedInput';

import SelectSimple from '../components/Forms/SelectSimple';
import { useLanguage } from "../hooks/Language"
import { countries } from "../pages/Products/modules/Login/countries";
import api from '../services/api';
import states from './SignUp/FormContainer/states';

interface IProps {
    changeCountry: Function;
    countryISO: string;
    checkUserValue?: Function;
}

export const checkDocumentNumber = async (document_number) => {

    const doc = document_number?.toString().replace(/[^0-9]/g, '');
    const valid = await api.get('check-user-document_number', { params: { document_number: doc } });

    return valid?.data?.valid || 'no';

}


const DefaultSignUpFormV4: React.FC<IProps> = ({ checkUserValue = (value) => { }, changeCountry }) => {

    const [countryISO, setCountryISO] = useState('BR')
    const [checkUser, setCheckUser] = useState('no')
    const [currentDocumentNumber, setCurrentDocumentNumber] = useState('');
    const { translate } = useLanguage();




    return <>
        <SelectSimple index={1} mandatory={true} setCallback={(value) => setCountryISO(value)} options={countries} name="country" label={translate('Pais')} />


        {countryISO === 'BR' ? <MaskedInput mandatory={true}
            mask="999.999.999-99"
            name="document_number"
            setCallback={(value) => setCurrentDocumentNumber(value)}
            placeholder={translate('CPF')}
        /> : <Input setCallback={(value) => setCurrentDocumentNumber(value)} mandatory={true} name="document_number"
            placeholder={translate('Nº do documento')} />}

        {!checkUser ? <button type='button' onClick={async () => {
            const check = await checkDocumentNumber(currentDocumentNumber);


            setCheckUser(check);
            checkUserValue(check);
        }} className='greenButton2 buttonColored'>Validar</button> : <></>}
        {checkUser === 'no' ? <>
            {countryISO === 'BR' ? <SelectSimple index={2} options={states} name="state" label="Estado" /> :

                <Input name="state" placeholder={'Província / Região'} />}
            <Input name="city" placeholder={'Município'} />
            <Input mandatory={true} name="name" placeholder={translate('Nome completo')} />

            {countryISO === 'BR' ? <MaskedInput
                mandatory={true}
                mask="(99) 99999.9999"
                name="phone"
                placeholder={translate('Telefone / Whatsapp')}
            /> : <Input name="phone" mandatory={true}
                placeholder={translate('Telefone')} />}







            <MaskedInput mandatory={true}
                mask="99/99/9999"
                name="extra_1"
                placeholder={translate('Data de nascimento')}
            />

            <p style={{ fontSize: '14px', textAlign: 'center', fontWeight: 'bold', marginTop: '30px', marginBottom: '15px', color: '#333' }}> {translate('Crie seu acesso')}</p>

            <Input mandatory={true} name="email" placeholder={translate('E-mail')} />


            <Input mandatory={true}
                icon={FiLock}
                name="password"
                type="password"
                placeholder={translate('Cadastre sua senha de acesso')}
            />
        </> : <></>}

        {checkUser === 'yes' ? <div className='row'><FaCheck style={{ color: 'green', fontSize: '18px' }} /> <p>Usuário identificado</p></div> : <></>}


    </>
}

export default DefaultSignUpFormV4;