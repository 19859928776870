import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLanguage } from '../../hooks/Language';
import { useConfiguration } from '../../hooks/Configuration';
 
import DefaultTheme from './modules/Themes/Default';
import DasaTheme from './modules/Themes/SidebarTheme';
import SinglePageTheme from './modules/Themes/SinglePageTheme';
import SidebarEventTheme from './modules/Themes/SidebarThemeEvents';

 

const HomeApplication: React.FC = () => {
 
 
  const {translate} = useLanguage();

  const {configuration, projectHash, eventHash} = useConfiguration();

  const [theme,setTheme] = useState('');
  
 

  useEffect(() => {

    let themeOption = configuration?.theme_data?.["page-theme"] || 'single_page';

    if(themeOption === 'single_page' && eventHash){
      themeOption = 'sidebar_event_theme';
    }

 

    setTheme( themeOption || 'default')

  },[ configuration])

  const themes = {
      default : <DasaTheme/>,
      dasa : <DasaTheme/>,
      single_page : <SinglePageTheme/>,
      sidebar_event_theme : <SidebarEventTheme/>

  }

  

  return ( themes?.[theme] ? themes?.[theme] : <></>  );
};
export default HomeApplication;
