import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField, FormHandles } from '@unform/core';
import { Container } from './styles';
import { Error } from '../styles';
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  pattern?: string;
}

const InputColor: React.FC<InputProps> = ({
  name,
  icon: Icon,
  formRef,
  pattern,
  setCallback = () => { },
  ...rest
}) => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(!!defaultValue);
  const [value, setValue] = useState(defaultValue || '');
  const handleInputBlur = useCallback(() => {
    setIsFocus(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName, ref: inputRef.current, path: undefined,
      getValue: () => {
        return value;
      },
      setValue: (ref: any, valueItem) => {
        setValue(valueItem);
      },

    });
  }, [fieldName, registerField, value]);

  return (
    <Container
      {...rest}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocus}
    >
      <span>{rest.placeholder}</span>
      {Icon && <Icon />}

      <div style={{ width: '20px', height: '20px', background: value || '#fff', border: '1px solid #333', borderRadius: '5px', marginRight: '10px' }} />
      <p>{value}</p>
      <input
        style={{ position: 'absolute', top: '0px', left: '0px', right: '0px', bottom: '0px', width: '100%', opacity: "0.01%" }}
        {...rest}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        value={value}
        onChange={e => {
          if (pattern) {
            let string = e.target.value || '';



            if (pattern === 'number') {
              string = string.toLowerCase().replace(/[^0-9]/g, '');
            }
            else if (pattern === 'url') {
              string = string.toLowerCase().replace(/[^a-z0-9-]/g, '');
            }
            else if (pattern === 'text') {
              string = string.toLowerCase().replace(/[^a-z-]/g, '');
            }
            else if (pattern === 'agency') {
              string = string.toLowerCase().replace(/[^0-9]/g, '').slice(0, 4);
            }
            else if (pattern === 'dv') {
              string = string.toLowerCase().replace(/[^0-9xX]/g, '').slice(0, 1);
            }

            setValue(string);
          }
          else {
            setValue(e.target.value);
          }

          setCallback(e.target.value, formRef);
        }}
        name={name}
        ref={inputRef}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle />
        </Error>
      )}
    </Container>
  );
};

export default InputColor;
