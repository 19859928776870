import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaCheck, FaGift, FaMinus, FaPlus, FaSpinner, FaTrash } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import { useLocation, useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../../../hooks/Configuration';
import { useLanguage } from '../../../../../../hooks/Language';
import { useModal } from '../../../../../../hooks/Modal';
import { useToast } from '../../../../../../hooks/Toast';
import api from '../../../../../../services/api';
import format from '../../../../../../utils/format';
import Input from '../../../../../DashboardPages/core/components/Forms/Input';
import { Container, ProductItemStyle } from './styles';



interface IProductRender {
    cart: Array<Record<string, any>>;
    product: Record<string, any>;
    setCallback: Function;
    unique: boolean;
    notChange: boolean;
    minimum?: number;
    coupon?: Record<string, any>;
    groupControl?: Record<string, any>[];

}

interface IAddProductComponent {
    cart: Array<Record<string, any>>;
    setCallback: Function;
    unique: boolean;
    showAdditional?: boolean;
    coupon?: Record<string, any>;
}

function useProducts({ project_id }) {

    const { productId } = useParams<Record<string, any>>();

    const loadProducts = async () => {

        if (productId) {
            const products = await api.get(`/product/${productId}/${project_id}`, { params: { limitItems: 10000 } });

            return products?.data ? [products?.data] : [];

        }
        else {
            const products = await api.get(`/products-list/${project_id}`, { params: { limitItems: 10000 } });
            products?.data?.rows?.sort((a, b) => a?.price < b?.price ? -1 : a?.price > b?.price ? 1 : 0)
            return products?.data?.rows || [];
        }

    }

    return { loadProducts };

}

const ProductItem: React.FC<IProductRender> = ({ minimum = 0, notChange, unique, product, setCallback, cart, coupon, groupControl }) => {
    const { product_hash, groupId } = useParams<Record<string, any>>();
    const productReference = cart?.find(item => item?._id?.toString() === product?._id?.toString());
    const { addModal } = useModal();
    const couponItem = coupon?.products?.find(i => i?.product?.toString() === product?._id?.toString());
    const { addToast } = useToast();

    const group = product?.group ? groupControl?.find(i => i?.group === product?.group) : false;


    const available = group ? group?.total_quantities > (group?.used + (group?.quantity || 0)) : (productReference?.quantity || 0) < product?.available_quantities;

    return product?.title ? <div
        style={{
            borderRadius: '5px',
            padding: '10px 15px',
            border: '1px solid #ddd',
            width: '100%'
        }}
    > <ProductItemStyle style={{ opacity: !available ? 0.4 : 1 }} >

            <div className='addProductInfo' ><p style={{ fontWeight: 'bold', fontSize: '12px', width: '100%' }}>{product?.title} </p>
                {product?.description ? <p style={{ fontSize: '12px', width: '100%' }}>{product?.description} </p> : <></>}
                {product_hash !== 'courtesy' && product?.before_price ? <p style={{ position: 'relative', fontWeight: 'bold' }}> {product_hash !== 'courtesy' && product?.before_price && product?.available_quantities >= 1 ? <><div style={{ height: '2px', opacity: 0.5, background: '#777', width: '70px', position: 'absolute', top: '50%', right: '0px' }} /> De:</> : <></>}  R$ {format(product?.before_price)}</p> : <></>}
                {product_hash !== 'courtesy' ? <p style={{ position: 'relative' }}> {product_hash !== 'courtesy' && couponItem?.product && product?.available_quantities >= 1 ? <div style={{ height: '2px', opacity: 0.5, background: '#777', width: '70px', position: 'absolute', top: '50%', right: '0px' }} /> : <>Valor un.:</>}  R$ {format(product?.price)}</p> : <></>}
                {product_hash !== 'courtesy' && couponItem?.product && product?.available_quantities >= 1 ? <p style={{ textAlign: 'right', fontWeight: 'bold', fontSize: '14px', color: 'green' }}>{`R$ `} {format(couponItem?.price)}</p> : <></>}

            </div>



            <div>
                <div className='addProductAction'> {!available && productReference?.quantity < 1 ? <p style={{ color: 'rgb(150,0,0)' }}>Esgotado</p> : <><div><p>{productReference?.quantity || '0'}</p></div>


                    {unique ? <div className={notChange ? 'activeProduct' : productReference?.quantity > 0 ? 'activeProduct changeble' : 'inactiveProduct changeble'}>

                        {notChange ? <FaCheck /> :

                            (!productReference || productReference?.quantity < 1) ? <FaCheck /> : <> <FaCheck className='showIcon' /> <FiX className='notShowIcon' /> </>}
                    </div> : <>
                        <div onClick={() => available ? setCallback(product, 'add') : false}> <FaPlus style={{ opacity: !productReference || productReference?.quantity >= product?.available_quantities ? 0.5 : 1 }} /> </div>
                        <div onClick={() => !productReference || productReference?.quantity <= minimum ? false : setCallback(product, 'remove')}>  <FaMinus style={{ opacity: !productReference || productReference?.quantity <= minimum ? 0.5 : 1 }} /> </div>

                    </>}</>}
                </div>
                {product_hash !== 'courtesy' && couponItem?.product && product?.available_quantities >= 1 ? <p style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '5px', fontSize: '9px', color: 'green', border: '1px solid green', borderRadius: '5px', padding: '5px' }}>Desconto aplicado</p> : <></>}
            </div>


        </ProductItemStyle >
        {product?.benefits_title && product?.benefits_description ? <p onClick={() => product?.benefits_title && product?.benefits_description ? addModal({ title: 'Benefícios', theme: 'whiteModalMedium', content: <p dangerouslySetInnerHTML={{ __html: product?.benefits_description }} /> }) : false} className='scale' style={{ width: '100%', opacity: product?.available_quantities < 1 ? 0.4 : 1, color: '#777', fontSize: '10px', padding: '10px 10px', cursor: 'pointer', paddingBottom: '5px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} ><FaGift style={{ width: '15px', minWidth: '15px' }} /> <span>{product?.benefits_title}</span></p> : <></>}

    </div> : <></>




}

const AddProductsComponent: React.FC<IAddProductComponent> = ({ cart, setCallback, unique = true, showAdditional = true, coupon }) => {
    const { projectHash } = useConfiguration();
    const { product_hash, productId, groupId } = useParams<Record<string, any>>()
    const [products, setProducts] = useState<Array<Record<string, any>>>([])
    const { configuration } = useConfiguration();
    const { loadProducts } = useProducts({ project_id: projectHash });



    const { search } = useLocation();
    const params = new URLSearchParams(search);




    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (configuration?.url) {
            loadProducts().then(productsArray => {
                setProducts([...productsArray]);
                setLoading(false);

                const productAdd = params.get("produto");

                if (productAdd) {
                    const productInfo = productsArray?.find(i => i?.hash_link === productAdd);

                    if (productInfo) {
                        setCallback(productInfo, 'add')
                    }
                }



            });
        }

    }, [configuration?.url])





    const { translate } = useLanguage();

    let listProducts = products?.filter((product) => (productId === product?.hash_link || product?.status_id === 'Sim'));
    if (groupId) {
        listProducts = listProducts.filter(i => i?.group_show === groupId)
    }

    if (configuration?.current_event_id_response?.product_order === 'asc') {
        listProducts = listProducts.sort((a, b) => a?.price > b?.price ? -1 : a?.price < b?.price ? 1 : 0)
    }
    else if (configuration?.current_event_id_response?.product_order === 'desc') {
        listProducts = listProducts.sort((a, b) => a?.price > b?.price ? 1 : a?.price < b?.price ? -1 : 0)
    }
    else if (configuration?.current_event_id_response?.product_order === 'position') {
        listProducts = listProducts.sort((a, b) => a?.position > b?.position ? 1 : a?.position < b?.position ? -1 : 0)
    }


    const groupControl: Record<string, any>[] = products?.reduce((prev: Record<string, any>[], item) => {

        if (item?.group) {
            const index = prev?.findIndex(i => i?.group === item?.group);

            if (index >= 0) {
                const used = item?.sold_quantities + item?.waiting_quantities;
                prev[index].used += used;
            }
            else {
                const used = item?.sold_quantities + item?.waiting_quantities;
                prev.push({ group: item?.group, used: used, total_quantities: item?.total_quantities, quantity: 0 })
            }


        }

        return prev;
    }, [] as Record<string, any>[])


    /* group control + cart */

    cart.map(i => {

        const product = products?.find(p => p?._id === i?._id);

        if (product?.group) {

            const findGroupIndex = groupControl?.findIndex(a => a?.group === product?.group);

            groupControl[findGroupIndex].quantity += i?.quantity;

        }


    })


    return <Container  >


        {listProducts?.length > 0 ? <h2 style={{ margin: ' 0px', fontSize: '14px', color: '#333', borderBottom: '3px solid #0a4f4b', padding: '5px 0px', width: '100%' }}>{translate('1. Selecione seus ingressos')}</h2> : <></>}

        {loading ? <p style={{ margin: '5px 0px', color: '#333', textAlign: 'right', width: '100%' }}><FaSpinner className='spin' size={20} style={{ minWidth: '20px', width: '20px', color: '#333', marginRight: '10px' }} />  {translate(`Carregando`)}</p> : <></>}

        {listProducts?.map((product) => <ProductItem coupon={coupon} notChange={false} unique={unique} cart={cart} key={product?._id?.toString()} product={product} setCallback={setCallback} groupControl={groupControl} />)}

    </Container >

}

export default AddProductsComponent;
