import { Form } from '@unform/web';
import React, { useEffect, useState } from 'react';
import { FaBell, FaCertificate, FaCheckCircle, FaExclamationCircle, FaFile, FaIdBadge, FaImages, FaQuestionCircle, FaReceipt, FaRegIdBadge, FaSubscript, FaTicketAlt, FaTrophy, FaUser } from 'react-icons/fa';
import { FiMessageCircle } from 'react-icons/fi';

import { IconType } from 'react-icons/lib';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';
import api from '../../../../services/api';



import { Container, ContainerWhite } from './styles';
import { useParams } from 'react-router-dom';
import { useModal } from '../../../../hooks/Modal';

import { useLoading } from '../../../../hooks/LoadingHook';
import Chart from '../../../../components/ChartModule';
import { formatReal } from '../../../../utils/format';





const SaleList: React.FC<Record<string, any>> = ({ project }) => {

  const { user } = useAuth();
  const { addLoading, removeLoading } = useLoading();
  const { addModal } = useModal();
  const [products, setProducts] = useState<Record<string, any>[]>([]);
  const [sales, setSales] = useState<Record<string, any>[]>([]);
  const [contador, setContador] = useState(5)

  const [filter, setFilter] = useState('');


  const salesFilteredItems = filter ? sales?.filter(sale => sale?.payment_status_id === filter) : sales;
  const salesFiltered = salesFilteredItems?.sort((a, b) => a?.client_name < b?.client_name ? -1 : a?.client_name > b?.client_name ? 1 : 0)
  const load = async () => {

    addLoading({ title: 'Carregando', key: 'loading' });


    const salesList = await api.get('/list-sale-by-project', {
      params: {
        limitItems: 10000,
        where: { project_id: project?.url, payment_status_id: { "$ne": "cancelled" } },
        export_data: {
          _id: { ref: '_id', label: 'ID', search: true },
          client_id: { ref: 'client_id', label: 'Titular', search: true },
          payer_id: { ref: 'payer_id', label: 'Comprador', search: true },
          product_id: { ref: 'product_id', label: 'producte', search: true },
          payment_status_id: { ref: 'payment_status_id', label: 'Status de pagamento', search: true },
        }

      }
    });

    const getPaymentStatus = {
      waiting: 'Aguardando pagamento',
      paid: 'Pago',
      cancelled: 'Cancelado',
      expired: 'Expirado',
    };

    const getPaymentMethod = {
      boleto: 'Boleto',
      cortesy: 'Cortesia',
      courtesy: 'Cortesia',
      credit_card: 'Cartão de crédito',
      pix: 'PIX'
    }

    const saleContent: Array<Record<string, any>> = [];
    salesList.data.rows.map(item => {

      item.payment_method_id_title = getPaymentMethod?.[item?.payment_method_id];
      item.payment_status_id_title = getPaymentStatus?.[item?.payment_status_id];
      item.product_title = item?.product_id_response?.title;
      item.client_name = item.client_id_response?.name?.toUpperCase()?.trim();
      saleContent.push(item);

    })

    setSales(saleContent)
    removeLoading('loading')

  }

  useEffect(() => {
    if (project?.url) {
      load();
    }

  }, [project])



  return <Container>
    <div className='selectorDiv'>
      <button className={filter === '' ? 'button buttonColored' : 'button'} onClick={() => setFilter('')}>Todos</button>
      <button className={filter === 'paid' ? 'button buttonColored' : 'button'} onClick={() => setFilter('paid')}>Pagos</button>
      <button className={filter === 'waiting' ? 'button buttonColored' : 'button'} onClick={() => setFilter('waiting')}>Aguardando</button>
    </div>


    <h2>Listagem</h2>
    <p style={{ color: '#333' }}>{salesFiltered.length} vendas</p>

    <table className='tableRef'>
      <tbody>
        <tr><th>Cliente</th><th>Forma de pagamento</th><th>Status de PG</th><th>Preço</th><th>TJuros do cartão</th><th>Total</th></tr>
        {salesFiltered.map(sale => {
          return <tr> <td>{sale?.client_name}</td><td>{sale?.payment_method_id_title}</td><td>{sale?.payment_status_id_title}</td>
            <td>R$ {formatReal(sale?.price - sale?.fees)}</td>
            <td>R$ {formatReal(sale?.fees)}</td>

            <td>R$ {formatReal(sale?.total)}</td></tr>

        })}

      </tbody>
    </table>

  </Container>






}
export default SaleList;
