import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';
import contactModule from './Modules/ContactModule';
import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../hooks/Configuration';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}


const Dashboard: React.FC = () => {
  const endpoint = '/students';
  const title = 'Cadastrados';
  const { projectHash, eventHash } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto' },
    event_id: { ref: 'event_id', column: 'event_id', label: 'Evento' },
    phone: { ref: 'phone', column: 'phone', label: 'Telefone', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'Email' },
    document_number: {
      ref: 'document_number',
      column: 'document_number',
      label: 'CPF',
    },
    state: {
      ref: 'state',
      column: 'state',
      label: 'Estado',
    },
    country: {
      ref: 'country',
      column: 'country',
      label: 'País',
    },


    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },

    email: { model: 'input', type: 'text', name: 'email', label: 'Email' },
    document_number: { model: 'input', type: 'text', name: 'document_number', label: 'CPF' },
    phone: { model: 'input', type: 'text', name: 'phone', label: 'Telefone' },
    zipcode: { model: 'input', type: 'text', name: 'zipcode', label: 'CEP' },
    company: {
      model: 'input',
      type: 'text',
      name: 'company',
      label: 'Empresa',
    },

    password_hash: {
      model: 'input',
      type: 'password',
      name: 'password_hash',
      label: 'Senha',
    },


    projects: {
      model: 'JsonInput',
      where: {},
      bodyRender: (data: Record<string, any>) => (
        <nav style={{ fontSize: '10px' }}>
          <strong style={{ fontSize: '10px' }}>
            (

            {data.title}
          </strong>
          <br />
          {data.url}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong style={{ fontSize: '10px' }}>
            (

            {data.title}
            <br />
            {data.url}
          </strong>

        </>
      ),
      storageType: 'id',
      endpoint: `/projects`,
      name: 'projects',
      label: 'Projetos autorizados',
    },

    profile: {
      model: 'selectSimple',
      name: 'profile',
      label: 'Nível',
      defaultValue: '2',
      options: [
        { label: 'Administrador', value: 1 },
        { label: 'Participante', value: 2 },
        { label: 'Coordenador de projeto', value: 30 },
        { label: 'Mediador / Palestrante', value: 3 },
        { label: 'Organização', value: 4 },
        { label: 'Expositor', value: 5 },
        { label: 'Moderador de Chat', value: 6 },
      ],
    },
  };

  const formValidation: Record<string, ValidationSchema> = {

  };


  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        contactModule({
          lineData

        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
