import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiPhone } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaAddressCard,
  FaArrowLeft,
} from 'react-icons/fa';

import { MdError, MdDateRange } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey } from '../../../config';
import getCep from '../../../services/cep';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import { useLanguage } from '../../../hooks/Language';
import { countries } from '../../../pages/Products/modules/Login/countries';
import { useToast } from '../../../hooks/Toast';
import { AnimatedDiv } from '../../DashboardPages/core/components/Profile/styles';
import Axios from 'axios';
import { useAuth } from '../../../hooks/Auth';
import states from './states';
import { useConfiguration } from '../../../hooks/Configuration';

interface IRecoverFormData {
  name: string;
  email: string;
  repeat_email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
 country: string;
 extra_2 : string;
 extra_3 : string;
 hash : string;
 instituicao ?: any;
}

interface IOption{
  value: string;
  label: string;
}

interface ICaptcha {
  getValue(): string;
}

interface IParam {
  code: string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const {addToast} = useToast();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
  const [countryISO, setCountryISO] = useState('BR');
  const { translate} = useLanguage();
  const [cities,setCities] = useState<Array<IOption>>([]);
  const [stateValue,setStateValue] = useState('');

  const {code} = useParams<IParam>();
  const {projectHash} = useConfiguration();
const {signIn} = useAuth();
  const callCityApi = async ( value) => {

    const response = await Axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`);

    if(response){

      const values : Array<IOption> = [];

      response.data.map( city => {
        values.push( { value: city.nome, label : city.nome});
      })

      setCities(values);
    }


  }
  

  useEffect(() => {
    callCityApi(stateValue);
  },[stateValue])

  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    data.birth_date = data.birth_date
      ? data.birth_date.toString().replace(/[^0-9/]/g, '')
      : '';

    

    try {
      formRef.current?.setErrors({});

      const schema =    Yup.object().shape( {
       
        hash: Yup.string().required(`${translate('Código de cortesia')} ${translate('obrigatório')}`),
          email: Yup.string()
            .required(`${translate('Email')} ${translate('obrigatório')}`)
            .email(`${translate('Email')} ${translate('obrigatório')}`),
           
         
   
      } );

   

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const response = await api.post(`/signup-cortesy-add-code`, {
        ...data,
       
        captcha: captchaValue,
        
      });


     if(response.data.confirmationCode){


        
      window.scrollTo(0,0);
    

      setSuccess(true);
      setLoading(false);
    }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        console.log(err);
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      addToast({title : 'Erro', type:'error', description: err?.response?.data?.message || err?.response?.data?.error ||  'Erro ao validar',})
      setErrorMessage([
    
        err?.response?.data?.message || err?.response?.data?.error || 'Erro ao gerar cadastro',
    ]);
    
    }
    setLoading(false);
  };

  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    const state = cepResponse.uf
      ? states.find(st => {
          return st.uf === cepResponse.uf;
        })
      : false;

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
    
        formRef?.current?.setFieldValue('state', cepResponse.uf);
      
    }
  };

if(success){
 return   <ResponseMessage
  active
  type={'success'}
  title={'Cadastro ativado com sucesso'}
  description={<aside style={{background:'#fff', display:'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center', margin: '30px', borderRadius: '20px'}}>
  <Link style={{ display:'flex', flexDirection:'row', alignItems: 'center', justifyContent:'center' }} to={`/app/${projectHash}/login`}> <button className='defaultButton'> ACESSAR </button></Link>
  </aside>}
/>
 }

  return (
    <>
      {loading === true && (
        <Loader message="Preparando ..." position="fixed" />
      )}



<aside style={{background:'#fff', display:'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center', borderRadius: '20px'}}>
         
      
  
<aside style={{background:'#fff', display:'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center', margin: '30px', borderRadius: '20px'}}>
<Link style={{ display:'flex', flexDirection:'row', alignItems: 'center', justifyContent:'center' }} to={`/app/${projectHash}/usar-codigo/${code ? code : ''}`}><FaArrowLeft color="#333" size={20}/><strong> VOLTAR </strong></Link>
</aside>
        
                  <Form className="form" ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR', hash: code}}>
                    <h1 className="defaultMarked" style={{color: '#333'}}><div/>{translate('Ativar cadastro')}</h1>
                    <Input name="hash" placeholder={translate('Código do ingresso')} />
                   
                    <Input name="email" placeholder={translate('E-mail')} />
                    
              <aside style={{display:'flex', justifyContent:'center', width: '100%'}}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />
</aside>

<aside style={{display:'flex', justifyContent:'center', width: '100%'}}>
  {errorMessage && <Error>{errorMessage.map( text => <div>{text}</div> )}</Error>}
</aside>
                    <Button type="submit">
                      {translate('Validar inscrição')}

                    </Button>
                   
                  </Form>
           
          </aside>
    </>
  );
};

export default FormContainer;
