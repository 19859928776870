import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import * as Yup from 'yup';

import { Container, Content, Background, AnimatedDiv } from './styles';

import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';

import FormContainer from './FormContainer';
import { useLanguage } from '../../hooks/Language';
import { useConfiguration } from '../../hooks/Configuration';


interface SignUpFormData {
  name: string;
  email: string;
  password: string;
}


const SignUp: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const [withPassword, setWithPassword] = useState(false);
  const { projectHash, eventHash } = useConfiguration();
  const formRef = useRef<FormHandles>(null);
  const { translate } = useLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Container>

      <Content>
        <AnimatedDiv>
          <Link to={eventHash ? `/app/${projectHash}/event/${eventHash}/login/` : `/app/${projectHash}/login/`} style={{ color: 'rgb(0,15,64)' }}>
            <FiArrowLeft />
            {translate('Voltar para login')}
          </Link>

          <h2>{translate('Inscrição')}</h2>
          <FormContainer />
        </AnimatedDiv>
      </Content>
    </Container>
  );
};

export default SignUp;
