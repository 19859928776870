import React, { useEffect, useState } from 'react';
import { Capa, Photo } from './styles';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import SliderComponent from '../SliderComponent';
import api from '../../../../services/api';
import { urlWebsite } from '../../../../config';
import { FaArrowCircleRight, FaArrowRight, FaBrain, FaChevronRight, FaDownload } from 'react-icons/fa';
import { useAuth } from '../../../../hooks/Auth';
import downloadit from '../../../../utils/download';
import sendTrack from '../../../../utils/sendTracking';
import { useConfiguration } from '../../../../hooks/Configuration';
 
const FileGallery : React.FC = () => {
 const {projectHash, eventHash} = useConfiguration();
  const {user} = useAuth();
  const [filesList,setFilesList] = useState<Array<Record<string,any>>>([]);
  const [slides, setSlides] = useState<Array<JSX.Element>>([] as Array<JSX.Element> );


  const load = async () => {
    const fileData = await api.get(`file-gallery-list/${projectHash}`);
    if(fileData){

     

      setFilesList(fileData.data.rows);
    }
  }

  useEffect(() => {
    load();
  },[])

  const downloadItem =async ({category, title, url ,user_name = '', user_id = '' }) => {
sendTrack({category, title, url ,user_name, user_id, project_id: projectHash, event_id: eventHash})

  
  }


  useEffect(( ) => {
    const l : Array<JSX.Element> = [];
     filesList?.length > 0 && filesList.map( (event,index)  => {
 
       
      l.push(
      
      
       <div className='downloadItem' onClick={() => { downloadItem({ category : 'material-de-apoio', title: event.title,url : `${urlWebsite}/${event.url}`, user_name : user?.name || '', user_id : user?.id ||''})}} > <a href={`${urlWebsite}/${event.url}`}  target="_BLANK"  className='event-container'>
        {event.image ? <img src={`${urlWebsite}/${event.image}`} /> : <></>}
        <div className='eventTime'>
          <FaDownload color={"#333"} size={20}/>
        </div>

      
      <div className='event-content'>
       <strong className='event-title' style={{marginBottom: '10px'}}>{event.title}</strong>
      <p className='event-project' style={{minHeight: '0px'}} dangerouslySetInnerHTML={{__html: event.description}}/>
      </div>
      
      
      </a></div>)
     }
    )

    setSlides(l);
  },[filesList])



    return     <>  { slides?.[0] &&  
    <Capa id="arquivos-apoio">


 { projectHash === 'simposio-dasa-tmo' ?<>
<h1 style={{color: 'rgb(0, 14, 64)', textAlign:'center', margin: '20px auto'}}>PODCASTS</h1>
<aside style={{width:'100%', maxWidth: '358px', margin: '15px auto'}}>
<iframe style={{borderRadius:'12px'}} src="https://open.spotify.com/embed/episode/3NwU0yMNqZZiBQ5wCI0PSO?utm_source=generator" width="100%" height="232" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
</aside>
</> : <></>}
   <h1 style={{color: 'rgb(0, 14, 64)', textAlign:'center', margin: '20px auto'}}>ARQUIVOS DE APOIO</h1>
    
    <div className='event-list-container'>
{slides.length > 0 ? slides : <h2>Nenhum arquivo disponível.</h2>}
    </div>
   
  </Capa>} </>
}

export default FileGallery;