import React, { useEffect, useState, useRef } from 'react';

import { useField, FormHandles } from '@unform/core';
import { FiXCircle } from 'react-icons/fi';
import { List, FieldSelect, Container } from './styles';

import api from '../../../../../../services/api';
import { string } from 'yup';
import { useParams } from 'react-router-dom';
import { useConfiguration } from '../../../../../../hooks/Configuration';

interface ISelectApi {
  endpoint: string;
  name: string;
  alias: string;
  label: string;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  addEmpty?: boolean;
}

interface IOption {
  text: string;
  value: string;
}

const SelectApi: React.FC<ISelectApi> = ({
  endpoint,
  name,
  alias,
  label,
  formRef,
  addEmpty = false,
  setCallback = (string: string, form: React.RefObject<FormHandles>) => { },
}) => {

  const { projectHash, eventHash } = useConfiguration();
  const [data, setData] = useState<Array<IOption>>([]);
  const [value, setValue] = useState('');
  const [active, setActive] = useState(false);
  const [currentOption, setCurrentOption] = useState<IOption>({} as IOption);

  const selectRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [currentValue, setCurrentValue] = useState(defaultValue);
  const { configuration } = useConfiguration();
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,

      getValue: (ref: any) => {
        return currentValue;
      },
    });
  }, [fieldName, registerField, currentValue]);

  function onChangeSelected(optionValue: IOption): void {
    setCallback(optionValue.value, formRef);
    setValue('');
    setCurrentOption(optionValue);
    setCurrentValue(optionValue.value);
    setActive(false);
  }

  useEffect(() => {
    const load = async (): Promise<void> => {



      if (defaultValue && defaultValue !== '') {
        const response = await api.get(`${endpoint}/feedSelect/${alias}`, {
          params: { _id: defaultValue, where: { project_id: configuration?.url } },
        });


        if (response?.data[0]) {
          onChangeSelected(response.data[0]);
        }
      }
    };

    load();
  }, []);

  const loadNew = async (string): Promise<void> => {
    const options: Record<string, any> = {
      search: string,
      where: { project_id: configuration?.url }
    }
    /* 
        if (projectHash) {
          options.where = {
            project_id: projectHash
          }
        }
    */

    if (string && string !== '') {
      const response = await api.get(`${endpoint}/feedSelect/${alias}`, {
        params: { ...options },
      });


      setData(response.data);
    }
  };





  async function loadOptions(text: string): Promise<void> {



    const options: Record<string, any> = {
      search: text, where: { project_id: configuration?.url }
    }
    /*
        if(projectHash){
          options.where = {
            project_id : projectHash
          }
        }
    */
    const response = await api.get(`${endpoint}/feedSelect/${alias}`, {
      params: { ...options },
    });

    const responseData = response && response.data ? response.data : [];

    const dataSet = addEmpty
      ? [{ text: 'Todos', value: null }, ...responseData]
      : responseData;

    setData(dataSet);
  }


  function activeSelection(): void {
    setActive(true);
    loadOptions(value);
  }

  function handleInative(): void {
    onChangeSelected({ text: '', value: '' });
    setActive(false);
  }

  return (
    <Container isErrored={!!error} >
      <span>{label}</span>
      <FieldSelect active={active}>
        <div>
          <div id={`${name}Select`}>
            <input
              autoComplete="off"
              placeholder="Pesquisar..."
              id={`${name}Input`}
              value={value}
              onChange={e => {

                setValue(e.target.value);
                loadNew(e.target.value || '');
              }}
              onClick={activeSelection}
              type="text"
            />
            <button type="button" onClick={handleInative}>
              <FiXCircle />
            </button>
            <div className="selectDiv">
              <div className="selectHolder" />
              <select ref={selectRef} name={name}>
                <option value={currentOption.value}>
                  {currentOption.text || 'Selecionar'}
                </option>
              </select>
            </div>
          </div>
          <List className="List" active={active}>
            <ul>
              {data && data && data.length > 0 ? (
                data.map(option => (
                  <li value={option.value}>
                    <button
                      onClick={() => onChangeSelected(option)}
                      type="button"
                    >
                      {option.text}
                    </button>
                  </li>
                ))
              ) : (
                <li value=""> Nenhum resultado encontrado </li>
              )}
            </ul>
          </List>
        </div>
      </FieldSelect>
    </Container>
  );
};

export default SelectApi;
