import { useLoading } from "../../../../../../hooks/LoadingHook";
import { useModal } from "../../../../../../hooks/Modal";
import { useLanguage } from "../../../../../../hooks/Language";
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useParams } from 'react-router-dom';

export default function usePaymentRequest() {

    const { projectHash, eventHash } = useParams<Record<string, any>>()
    const { translate, language } = useLanguage();
    const { removeLoading, addLoading } = useLoading();
    const { addModal } = useModal();
    const { addToast } = useToast();

    const paymentRequest = async ({ paymentContext }) => {

        addLoading({ title: 'Preparando a sua compra', key: 'loading' })
        const { content, coupon, cart, paymentMethod } = paymentContext;

        await api
            .post(`/payment`, {
                ...paymentContext
            })
            .then(response => {
                const { data } = response;

                if (data && data.status === 'success') {
                    return window.location.href = eventHash ? `/app/${projectHash}/event/${eventHash}/payment/success/${data.hash}` : `/app/${projectHash}/payment/success/${data.hash}`;
                } else {
                    addModal({ title: 'Erro', content: data.message, key: '1' });
                }
                removeLoading('loading')
            })
            .catch(err => {
                addToast({
                    title: translate('Erro'),
                    description: translate(err?.response?.data?.message || err?.response?.data?.error || translate('Erro ao efetuar pagamento')),
                    type: 'error',
                });
                removeLoading('loading')
                return false;
            });


    }

    return { paymentRequest };


}