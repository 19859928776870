import React from 'react';

import uuid from '../../core/components/UniqueHash';
import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules';
import ExportSurveyList from './modules/ExportSurveyList';
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import QRCodeModule from './modules/QrCode';
import ExportSurveyListReport from './modules/ExportSurveyListReport';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}



const SatisfactionSurvey: React.FC = () => {
  const endpoint = '/satisfaction-survey';
  const title = 'Pesquisa de Satisfação';
  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID' },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto', show: false },
    event_id: { ref: 'event_id', column: 'event_id', label: 'Evento', show: false },
    image: { ref: 'image', column: 'image', label: 'Capa' },
    title: { ref: 'title', column: 'title', label: 'Título' },

    description: { ref: 'description', column: 'description', label: 'Descritivo' },

    thanks_title: { ref: 'thanks_title', column: 'thanks_title', label: 'Título de Sucesso' },
    thanks_description: { ref: 'thanks_description', column: 'thanks_description', label: 'Descritivo de sucesso' },

    questions: { ref: 'questions', column: 'questions', label: 'Perguntas', show: false },


    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    title: {
      model: 'input',
      type: 'text',
      name: 'title',
      label: 'Título',

    },
    title_en: {
      model: 'input',
      type: 'text',
      name: 'title_en',
      label: '(ING) Título',
    },
    title_es: {
      model: 'input',
      type: 'text',
      name: 'title_es',
      label: '(ESP) Título',
    },
    description: {
      model: 'richText',
      type: 'text',
      name: 'description',
      label: 'Descritivo',
      defaultValue: 'Sua opinião nos ajuda a realizar eventos cada vez melhores!'
    },
    description_en: {
      model: 'richText',
      type: 'text',
      name: 'description_en',
      label: '(ING) Descritivo',
    },
    description_es: {
      model: 'richText',
      type: 'text',
      name: 'description_es',
      label: '(ESP) Descritivo',
    },
    thanks_title: {
      model: 'input',
      type: 'text',
      name: 'thanks_title',
      label: 'Título de Sucesso',
    },
    thanks_title_en: {
      model: 'input',
      type: 'text',
      name: 'thanks_title_en',
      label: '(ING) Título de Sucesso',
    },
    thanks_title_es: {
      model: 'input',
      type: 'text',
      name: 'thanks_title_es',
      label: '(ESP) Título de Sucesso',
    },

    thanks_description: {
      model: 'richText',
      type: 'text',
      name: 'thanks_description',
      label: 'Descritivo de Sucesso',
    },
    thanks_description_en: {
      model: 'richText',
      type: 'text',
      name: 'thanks_description_en',
      label: '(ING) Descritivo de Sucesso',
    },
    thanks_description_es: {
      model: 'richText',
      type: 'text',
      name: 'thanks_description_es',
      label: '(ESP) Descritivo de Sucesso',
    },

    questions: {
      model: 'jsonInputList',
      type: 'text',
      name: 'questions',
      label: 'Questões',
      show: 'title',
      list: [
        { label: 'Título', name: 'title', type: 'richText' },
        { label: '(ING) Título', name: 'title_en', type: 'richText' },
        { label: '(ESP) Título', name: 'title_es', type: 'richText' },
        { label: 'Descritivo', name: 'description', type: 'richText' },
        { label: '(ING) Descritivo', name: 'description_en', type: 'richText' },
        { label: '(ESP) Descritivo', name: 'description_es', type: 'richText' },

        {
          label: 'Tipo', name: 'type', type: 'select', options: [
            { label: 'Checkbox Único', text: 'Checkbox Único', value: 'checkboxSingle' },
            { label: 'Checkbox Múltiplo', text: 'Checkbox Múltiplo', value: 'checkbox' },
            { label: 'Emoji', text: 'Emoji', value: 'emoji' },
            { label: 'Estrelas', text: 'Estrelas', value: 'star' },
            { label: '1 a 10', text: '1 a 10', value: 'range' },
            { label: 'Texto', text: 'Texto', value: 'input' },
            { label: 'Área de Texto', text: 'Área de Texto', value: 'textarea' },
            { label: 'Seleção', text: 'Seleção', value: 'select' },
          ]
        },
        { label: 'Opções de seleção', name: 'options', type: 'arrayIncluder' },
        {
          label: 'Obrigatório?', name: 'mandatory', type: 'select', options: [
            { label: 'Sim', text: 'Sim', value: 'yes' },
            { label: 'Não', text: 'Não', value: 'no' },
          ]
        },

      ],
      defaultValue: [{
        "title": "<p>Como você ficou sabendo do evento?</p><p>*Marque todas as opções onde você viu nossa divulgação.</p>",
        "type": "checkbox",
        "options": ["E-mail marketing", "Indicação de amigos/colegas", "Redes sociais", "Site do HCPA", "Site da FundMed", "WhatsApp", "Outro"],
        "mandatory": "yes"
      }, {
        "title": "<p>Como você avalia o local do evento?</p>",
        "description": "<p>*Leve em consideração as instalações, facilidade de&nbsp; acesso, audiovisual, etc.</p>",
        "type": "emoji",
        "options": [],
        "mandatory": "yes"
      }, {
        "title": "<p>O que você achou do processo de inscrição no evento?</p>",
        "type": "emoji",
        "options": [],
        "mandatory": "yes"
      }, {
        "title": "<p>Dê uma nota para o atendimento da equipe do evento</p>",
        "type": "range",
        "options": [],
        "mandatory": "yes"
      }, {
        "title": "<p>Como você se sentiu em relação aos item abaixo?</p>",
        "description": "<p>Palestrantes</p>",
        "type": "emoji",
        "options": [],
        "mandatory": "yes"
      }, {
        "title": "<p>Como você se sentiu em relação aos item abaixo?</p>",
        "title_en": "<p>Temas apresentados</p>",
        "type": "emoji",
        "options": [],
        "mandatory": "yes"
      }, {
        "title": "<p>Como você se sentiu em relação aos item abaixo?</p>",
        "description": "<p>Tempo de duração</p>",
        "type": "emoji",
        "options": [],
        "mandatory": "yes"
      }, {
        "title": "<p>Como você se sentiu em relação aos item abaixo?</p>",
        "description": "<p>Pontualidade</p>",
        "type": "emoji",
        "options": [],
        "mandatory": "yes"
      }, {
        "title": "<p>No geral, como você avalia o evento?</p>",
        "type": "emoji",
        "options": [],
        "mandatory": "yes"
      }, {
        "title": "<p>Você participaria da próxima edição?</p>",
        "type": "checkboxSingle",
        "options": ["Sim", "Não", "Talvez"],
        "mandatory": "yes"
      }, {
        "title": "<p>Gostaria de deixar uma sugestão ou comentário? Fique à vontade.</p>",
        "type": "textarea",
        "options": [],
        "mandatory": "no"
      }]
    },


  };






  const formSchemaUpdate = {
    ...formSchema,
    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Imagem',
    },
  };


  const formValidation: Record<string, ValidationSchema> = {

  };



  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        <ExportSurveyListReport lineData={lineData} />,
      (lineData: Record<string, any>) =>
        <ExportSurveyList lineData={lineData} />,
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        QRCodeModule({
          lineData,
          title,

        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default SatisfactionSurvey;
