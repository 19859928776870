import React from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';




const PoliticaCancelamento: React.FC = () => {



  return (
    <>
      <ContainerWhite id="cancelamento">
        <div className="p50 p600 ">
          <h2>POLÍTICA DE CANCELAMENTO E REEMBOLSO</h2>
          <div className="separator" />
          <p>

            Considerando que as inscrições são realizadas de maneira on-line, o participante poderá exercer, no prazo de até 7 (sete) dias, a contar da data de sua inscrição, seu direito de arrependimento, previsto no artigo 49 da Lei no 8.098/1990 (Código de Defesa do consumidor).
            <br />  Para tanto, a solicitação deve ser formalizada através do e-mail eventos@fundmed.org.br, constando no assunto – SOLICITAÇÃO DE REEMBOLSO e TÍTULO DO EVENTO ou CURSO. Após a solicitação formal, o reembolso será processado e efetuado em até 30 (trinta) dias.
            <br /> Atenta-se que não serão aceitas solicitações de reembolso feitas com menos de 48h (quarenta e oito horas) antes da realização do evento.
            Para quaisquer dúvidas adicionais, entre em contato pelo e-mail eventos@fundmed.org.br.  </p>
        </div>
      </ContainerWhite>
    </>
  );
};
export default PoliticaCancelamento;
