import React from 'react';
import Input from "../../../../components/Forms/Input";
import SelectSimple from '../../../../components/Forms/SelectSimple';
import ArrayInputIncluderJoin from '../../../DashboardPages/core/components/Forms/ArrayInputIncluderJoin';
import TextareaInput from '../../../DashboardPages/core/components/Forms/TextareaInput';
import UploadCurriculumContent from '../../../DashboardPages/core/components/Forms/UploadCurriculumContent';

interface IFields {
    field: string;
    limit: number;
    label: string;
    title: string;
    description: string;
    type: string;
    options?: Array<string>;
    mask?: string;
}

interface IOptions {
    text?: string;
    label?: string;
    value: string;
}

interface IProps {
    fields: Array<IFields>;
    setCallBack?: Function;


}

const CompleteSaleFields: React.FC<IProps> = ({ fields, setCallBack = () => { } }) => {



    const readOptions = (options: Array<string> = []) => {

        const newOptions: Array<IOptions> = [];

        options.map(opt => {
            newOptions.push({ label: opt, value: opt, text: opt });
        })

        return newOptions;
    }


    const readFields = (field: IFields) => {



        const valid = {
            input: <Input name={field.field} placeholder={field.title} />,
            textarea: <><TextareaInput name={field.field} placeholder={field.title} />{field.label && <p>{field.label}</p>}</>,
            arrayInputIncluder: <><p>{field.title}</p><p>{field.description}</p><ArrayInputIncluderJoin mask={field?.mask || ''} label={field.label} limit={field.limit} name={field.field} placeholder={field.title} /></>,
            image: <><p>{field.title}</p><p>{field.description}</p><UploadCurriculumContent label={field.label} name={field.field} /></>,
            select: <><SelectSimple options={readOptions(field?.options)} name={field.field} label={field.title} />{field.label && <p>{field.label}</p>} </>
        };

        return valid[field?.type] ? valid[field?.type] : <></>;




    }

    return <div className='fieldsList'>
        {
            fields.map(f => {
                return readFields(f);
            })
        }
    </div>;

}


export default CompleteSaleFields;